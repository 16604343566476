
import React, { useState, useRef, useEffect } from "react";
import {
  TextField,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  OutlinedInput,
  Autocomplete,
  Button,
  DialogActions,
  Stack,
  FormHelperText,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  Box,
  Chip,
} from "@mui/material";
import { useSnackBar } from "../../contexts/SnackBarContext/SnackBarContext";
import { useStudioScrapeStatus } from "../../contexts/StudioScrapeStatus/StudioScrapeStatusContext";
import { useSettingsContext } from "../../contexts/SettingsContext";
import { makeFirstLetterLowercase } from "./utils"; // Adjust path as necessary
import "./staffDialog.scss";
import { getUrlVariableValue } from "../../utility-functions/utility-functions";
import { useAuth } from "../../contexts/AuthContext";
import { updateStaffMasterSettings } from "../../services/staff.services";
import { useStaffContext } from "../../contexts/StaffContext/StaffContext";
import { LoadingButton } from "@mui/lab";
import NumericTextField from "../CustomTextFields/NumericTextField";
import EarningsDialog from "../../pages/StaffSettings/EarningsDialog";
import { AddRounded, EditRounded } from "@mui/icons-material";

const InputComponent = React.memo(({ label, type, value, onChange }) => {
  return type === 'number' ? (
    <NumericTextField label={label} value={value} onChange={onChange} />
  ) : (
    <TextField
      label={label}
      type={type}
      fullWidth
      margin="normal"
      value={value || ""}
      onChange={onChange}
    />
  );
});

const GeneratedInputComponents = React.memo(
  ({ bucketName, bucketSetup, editableStaff, handleChange }) => {
    const ratePropertyName = makeFirstLetterLowercase(bucketName) + "Rate";

    return (
      <>
        {bucketSetup.rate.includeZero === "Paid A Different Amount" && (
          <InputComponent
            key={`${bucketName}-zero-attendee`}
            label="Zero Attendee"
            type="number"
            value={editableStaff[ratePropertyName]?.zero || ""}
            onChange={(e) =>
              handleChange(`${ratePropertyName}.zero`, e.target.value)
            }
          />
        )}

        {bucketSetup.rate.structure === "Flat Rate" && (
          <InputComponent
            key={`${bucketName}-flat-rate`}
            label={`${bucketSetup.rate.structure} (1-12 Attendees)`}
            type="number"
            value={editableStaff[ratePropertyName]?.flat || ""}
            onChange={(e) =>
              handleChange(`${ratePropertyName}.flat`, e.target.value)
            }
          />
        )}

        {bucketSetup.rate.structure === "Base Rate + Per Head Bonus" && (
          <>
            <InputComponent
              key={`${bucketName}-base-rate`}
              label="Base Rate"
              type="number"
              value={editableStaff[ratePropertyName]?.base || ""}
              onChange={(e) =>
                handleChange(`${ratePropertyName}.base`, e.target.value)
              }
            />
            <InputComponent
              key={`${bucketName}-per-head-rate`}
              label="Per Head Rate"
              type="number"
              value={editableStaff[ratePropertyName]?.perHead || ""}
              onChange={(e) =>
                handleChange(`${ratePropertyName}.perHead`, e.target.value)
              }
            />
            <InputComponent
              key={`${bucketName}-starting-after`}
              label="Starting After"
              type="number"
              value={editableStaff[ratePropertyName]?.afterAttendee || ""}
              onChange={(e) =>
                handleChange(
                  `${ratePropertyName}.afterAttendee`,
                  e.target.value
                )
              }
            />
          </>
        )}

        {bucketSetup.rate.structure === "Custom Amount" &&
          Array.from(
            { length: parseInt(bucketSetup.capacity.split("~")[1], 10) },
            (_, i) => (
              <InputComponent
                key={`${bucketName}-custom-amount-${i}`}
                label={`Custom Amount for ${i + 1} Attendees`}
                type="number"
                value={
                  editableStaff[ratePropertyName]?.customAmounts?.[i] || ""
                }
                onChange={(e) =>
                  handleChange(
                    `${ratePropertyName}.customAmounts[${i}]`,
                    e.target.value
                  )
                }
              />
            )
          )}
      </>
    );
  }
);

const DynamicRateInput = React.memo(
  ({ bucketName, bucketSetup, editableStaff, handleChange }) => {
    return (
      <>
        <Typography variant="h5">
          {bucketName} Rates
        </Typography>
        <GeneratedInputComponents
          bucketName={bucketName}
          bucketSetup={bucketSetup}
          editableStaff={editableStaff}
          handleChange={handleChange}
        />
      </>
    );
  }
);

const DynamicRates = ({ settings, editableStaff, handleChange }) => {
  const classBuckets =
    settings[getUrlVariableValue("settingsId")].classSettings.classBuckets;

  return (
    <>
      {Object.entries(classBuckets).map(([bucketName, bucketSetup]) => (
        <DynamicRateInput
          key={bucketName}
          bucketName={bucketName}
          bucketSetup={bucketSetup}
          editableStaff={editableStaff}
          handleChange={handleChange}
        />
      ))}
    </>
  );
};

// Helper function to sanitize the staff name for Excel.
const sanitizeForExcel = (str) => {
  if (typeof str !== 'string') return str;

  // Include a broad set of characters that could cause issues in Excel cell references.
  // This includes characters that can start formulas (=), denote text ('),
  // indicate array formulas or special formatting (%), or that Excel doesn't
  // allow in names and references (most special characters).
  const invalidChars = [
    '=', "'", '%', '`', '+', '-', '@', '[', ']', '(', ')', '{', '}', '<', '>', '?',
    '#', '$', '^', '&', '*', '"', '~', '|', '/', '\\', ':', ';', '!', ','
  ];

  let sanitized = str;
  invalidChars.forEach(char => {
    sanitized = sanitized.replace(new RegExp(`\\${char}`, 'g'), '');
  });

  return sanitized;
};


const AddStaffComponent = ({ staff, onSave, onClose }) => {
  const { getUID } = useAuth();
  const { settings, dispatch, reportCompilerDispatch } = useSettingsContext();
  const {
    editableStaff,
    updateEditableStaff,
    resetEditableStaff,
    staffContextDispatch,
    loading,
  } = useStaffContext();

  const showSnackBar = useSnackBar();
  const { currentlySelectedStudios } = useStudioScrapeStatus();

  const fieldRefs = useRef({});
  const [errors, setErrors] = useState({});

  const [customDialogOpen, setCustomDialogOpen] = useState(false);
  const [currentCustomField, setCurrentCustomField] = useState(null);
  const [tempEarnings, setTempEarnings] = useState([]);

  const [alternateNamesInitialized, setAlternateNamesInitialized] = useState(false);
  const [initialAlternateName, setInitialAlternateName] = useState("");

  const handleOpenCustomDialog = (field) => {
    setCurrentCustomField(field);
    setTempEarnings(editableStaff[field] || []);
    setCustomDialogOpen(true);
  };

  const handleCloseCustomDialog = (confirmed) => {
    setCustomDialogOpen(false);
    if (confirmed && currentCustomField) {
      updateEditableStaff({
        ...editableStaff,
        [currentCustomField]: tempEarnings,
      });
    }
  };

  const handleChange = (path, value) => {
    if (path.includes(".")) {
      const keys = path.split(".");
      let updatedStaff = { ...editableStaff };

      let current = updatedStaff;
      keys.forEach((key, index) => {
        if (index === keys.length - 1) {
          current[key] = value;
        } else {
          current[key] = current[key] || {};
          current = current[key];
        }
      });

      updateEditableStaff(updatedStaff);
    } else {
      updateEditableStaff({ ...editableStaff, [path]: value });
    }
  };

  const handleSaveStaff = async (uid, staff) => {
    const newStaffObjects = {};
    newStaffObjects[staff.id] = staff;
    const updateSuccessful = await updateStaffMasterSettings(uid, newStaffObjects);
    if (updateSuccessful) {
      showSnackBar(`Successfully added ${staff.name}`, "success");
      reportCompilerDispatch({
        type: "BUILD_REPORT_COMPILER_STATE_STAFF",
      });
    } else {
      showSnackBar(`Failed to add ${staff.name}`, "error");
    }
  };

  const validateRequiredFields = () => {
    const newErrors = {};
    const staffFields = settings[getUrlVariableValue("settingsId")].generalSettings.staffFields;
    staffFields
      .filter((field) => field.order !== -1) // Only consider displayed fields
      .forEach((field) => {
        if (field.required) {
          const val = editableStaff[field.field];
          const isEmptyValue =
            val === undefined ||
            val === null ||
            (typeof val === "string" && val.trim() === "") ||
            (Array.isArray(val) && val.length === 0);

          if (isEmptyValue) {
            newErrors[field.field] = `${field.name} is required`;
          }
        }
      });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const isValid = validateRequiredFields();

    if (!isValid) {
      return;
    }

    staffContextDispatch({ type: "SET_LOADING", payload: true });

    dispatch({
      type: "ADD_STAFF_SETTINGS_STAFF_MEMBER",
      newStaffId: editableStaff.id,
      newStaff: editableStaff,
    });

    await handleSaveStaff(getUID(), editableStaff);

    staffContextDispatch({ type: "ADD_STAFF", payload: editableStaff });
    resetEditableStaff();
    staffContextDispatch({ type: "SET_LOADING", payload: false });

    onSave(editableStaff);
  };

  useEffect(() => {
    // If there are errors, scroll to the first error field
    if (Object.keys(errors).length > 0) {
      const staffFields = settings[getUrlVariableValue("settingsId")].generalSettings.staffFields;
      const firstErrorField = staffFields.find(
        (field) => field.order !== -1 && errors[field.field]
      );

      if (firstErrorField && fieldRefs.current[firstErrorField.field]) {
        fieldRefs.current[firstErrorField.field].scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
        fieldRefs.current[firstErrorField.field].focus();
      }
    }
  }, [errors, settings]);

  useEffect(() => {
    if (staff && staff.name && editableStaff && !alternateNamesInitialized) {
      const sanitizedName = sanitizeForExcel(staff.name);
      const newAlternateNames = [...(editableStaff.alternateNames || []), staff.name];

      updateEditableStaff({
        ...editableStaff,
        name: sanitizedName,
        alternateNames: newAlternateNames,
      });

      setInitialAlternateName(staff.name);
      setAlternateNamesInitialized(true);
    }
  }, [staff, editableStaff, updateEditableStaff, alternateNamesInitialized]);


  const staffFields = settings[getUrlVariableValue("settingsId")].generalSettings.staffFields;
  const sortedFields = staffFields
    .filter((field) => field.order !== -1)
    .sort((a, b) => a.order - b.order);

  const getOptionsForField = (field) => {
    if (field.options && Array.isArray(field.options) && field.options.length > 0) {
      return field.options;
    }

    // Default options based on field name
    switch (field.field) {
      case "type":
        return settings[getUrlVariableValue("settingsId")].generalSettings?.staffTypes?.map(
          (staff) => staff.name
        ) || [];
      case "locations":
        return currentlySelectedStudios || [];
      case "homeLocation":
        return currentlySelectedStudios || [];
      case "homeRegion":
        return settings[getUrlVariableValue("settingsId")].generalSettings?.regions || [];
      case "tier":
        return settings[getUrlVariableValue("settingsId")].generalSettings?.payTiers || [];
      default:
        return [];
    }
  };

  return (
    <Stack spacing={3} sx={{ margin: "16px 0" }}>
      {sortedFields.map((field) => {
        const fieldValue = editableStaff[field.field];
        const isError = !!errors[field.field];
        const helperText = isError ? errors[field.field] : field.description;

        switch (field.type) {
          case "string":
          case "text":
            return (
              <TextField
                key={field.field}
                label={field.name}
                value={fieldValue || ""}
                onChange={(e) => handleChange(field.field, e.target.value)}
                fullWidth
                margin="dense"
                error={isError}
                helperText={helperText}
                inputRef={(el) => (fieldRefs.current[field.field] = el)}
                disabled={field.field === "name"}
              />
            );

          case "singleSelect":
            const selectOptions = getOptionsForField(field);
            return (
              <FormControl
                key={field.field}
                fullWidth
                error={isError}
                margin="dense"
              >
                <InputLabel>{field.name}</InputLabel>
                <Select
                  label={field.name}
                  value={fieldValue || ""}
                  onChange={(e) => handleChange(field.field, e.target.value)}
                  input={<OutlinedInput label={field.name} />}
                  inputRef={(el) => (fieldRefs.current[field.field] = el)}
                >
                  {selectOptions.length > 0 ? (
                    selectOptions.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem disabled>No options available</MenuItem>
                  )}
                </Select>
                {isError && (
                  <FormHelperText color="error">
                    {errors[field.field]}
                  </FormHelperText>
                )}
                {!isError && field.description && (
                  <FormHelperText>{field.description}</FormHelperText>
                )}
              </FormControl>
            );

          case "list":
            const autocompleteOptions = getOptionsForField(field);
            return (
              <Autocomplete
                key={field.field}
                multiple
                freeSolo
                options={autocompleteOptions}
                value={fieldValue || []}
                onChange={(event, newValue) => {
                  handleChange(field.field, newValue);
                }}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => {
                    const tagProps = getTagProps({ index });
                    // If this is the initial alternate name, remove the onDelete handler
                    if (option === initialAlternateName) {
                      delete tagProps.onDelete;
                    }
                    return <Chip {...tagProps} label={option} />;
                  })
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label={field.name}
                    placeholder={field.description}
                    error={isError}
                    helperText={helperText}
                    inputRef={(el) => (fieldRefs.current[field.field] = el)}
                  />
                )}
              />
            );

          default:
            return (
              <TextField
                key={field.field}
                label={field.name}
                value={fieldValue || ""}
                onChange={(e) => handleChange(field.field, e.target.value)}
                fullWidth
                margin="dense"
                error={isError}
                helperText={helperText}
                inputRef={(el) => (fieldRefs.current[field.field] = el)}
              />
            );
        }
      })}


      {Object.entries(settings[getUrlVariableValue("settingsId")]?.timeSettings?.timeBuckets || {}).map(([bucketName, bucket]) => {
        if (bucket.type === "Earning") {
          const field = `${bucketName.toLowerCase()}Earning`;
          const fieldValue = editableStaff[field] || [];

          return (
            <div key={bucketName}>
              {fieldValue && fieldValue.length > 0 ? (
                <Stack spacing={2} sx={{ marginTop: "16px" }}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      padding: '8px 0',
                      borderBottom: '1px solid',
                      borderColor: 'divider',
                    }}
                  >
                    <Typography variant="h5" marginBottom={0}>
                      {bucketName} Earnings
                    </Typography>
                    <Button
                      variant="outlined"
                      startIcon={<EditRounded />}
                      onClick={() => {
                        setCurrentCustomField(field);
                        setTempEarnings(fieldValue);
                        setCustomDialogOpen(true);
                      }}
                    >
                      Edit
                    </Button>
                  </Box>
                  <Table sx={{ marginTop: 0 }} size="medium">
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ paddingLeft: 0, paddingRight: 0 }}>Location</TableCell>
                        <TableCell align="right" sx={{ paddingLeft: 0, paddingRight: 0 }}>Amount</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {fieldValue.map((item, idx) => (
                        <TableRow key={idx}>
                          <TableCell sx={{ paddingLeft: 0, paddingRight: 0 }}>{item.location}</TableCell>
                          <TableCell align="right" sx={{ paddingLeft: 0, paddingRight: 0 }}>
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "USD",
                            }).format(item.amount)}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Stack>
              ) : (
                <>
                  <Typography variant="h5" marginBottom={0}>
                    {bucketName} Earnings
                  </Typography>
                  <Button
                    variant="outlined"
                    startIcon={<AddRounded />}
                    onClick={() => {
                      setCurrentCustomField(field);
                      setTempEarnings([]);
                      setCustomDialogOpen(true);
                    }}
                    sx={{ marginTop: "24px" }}
                  >
                    Add Earnings
                  </Button>
                </>
              )}
            </div>
          );
        }
        return null;
      })}

      {/* EarningsDialog for adding/editing earnings */}
      <EarningsDialog
        isOpen={customDialogOpen}
        onClose={(confirmed) => {
          setCustomDialogOpen(false);
          if (confirmed && currentCustomField) {
            updateEditableStaff({
              ...editableStaff,
              [currentCustomField]: tempEarnings,
            });
          }
        }}
        locations={currentlySelectedStudios}
        earnings={tempEarnings}
        setEarnings={setTempEarnings}
      />



      <Typography variant="h5" gutterBottom>
        Hourly Rates
      </Typography>
      <NumericTextField
        label="Primary Hourly Rate"
        value={editableStaff.hourly || ""}
        onChange={(e) => handleChange("hourly", e.target.value)}
      />

      <NumericTextField
        label="Secondary Hourly Rate"
        value={editableStaff.secondaryHourly || ""}
        onChange={(e) => handleChange("secondaryHourly", e.target.value)}
      />

      <DynamicRates
        settings={settings}
        editableStaff={editableStaff}
        handleChange={handleChange}
      />

      <Typography variant="h5" gutterBottom>
        Commission Rates
      </Typography>
      <TextField
        label="Retail Commission Rate"
        type="text"
        fullWidth
        margin="dense"
        value={editableStaff.commissionRate?.retail || ""}
        onChange={(e) => handleChange("commissionRate.retail", e.target.value)}
      />
      <TextField
        label="Agreement Commission Rate"
        type="text"
        fullWidth
        margin="dense"
        value={editableStaff.commissionRate?.agreement || ""}
        onChange={(e) =>
          handleChange("commissionRate.agreement", e.target.value)
        }
      />

      <EarningsDialog
        isOpen={customDialogOpen}
        onClose={handleCloseCustomDialog}
        locations={currentlySelectedStudios}
        earnings={tempEarnings}
        setEarnings={setTempEarnings}
      />

      <DialogActions className="add-staff-component__actions">
        <Button onClick={() => onClose(staff)}>Cancel</Button>
        <LoadingButton
          loading={loading}
          variant="contained"
          onClick={handleSubmit}
        >
          Confirm
        </LoadingButton>
      </DialogActions>
    </Stack>
  );
};

export default AddStaffComponent;
