import React, { useState, useEffect } from "react";
// import XLSX from "xlsx";
import XLSX from "@sheet/core";
import { API } from "aws-amplify";
import { db } from "../../firebase";
import {
  collection,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { getUserSettings } from "../../services/studio.services";
import { useSettingsContext } from "../../contexts/SettingsContext";
import {
  buildReportCompilerState,
  handleDownload,
} from "../../contexts/ReportCompiler/utility-functions.js";
import {
  addOrUpdateUrlVariable,
} from "../../utility-functions/utility-functions.js";
import { useStudioScrapeStatus } from "../../contexts/StudioScrapeStatus/StudioScrapeStatusContext";
import {
  findStudiosWithoutAccount,
  getWorkbookFromS3,
} from "../../contexts/StudioScrapeStatus/utility-functions.js";
import {
  fetchPreSignedUrls,
  processS3Urls,
  getStudiosAndWorkbooksFromSessionData,
  createPresignedUrl,
  fetchLatestSessionPathWithSnapshot,
  getReportCompilerStateFromLatestSessionSnapshot,
} from "../../utility-functions/AWS/S3-Helper-Functions.js";
import { getSettingsIdsForUid } from "../../services/studio.services";
import { ComparisonView } from "./ComparisonView.js";
import { uploadTestOutput } from "../../utility-functions/utility-functions.js";
import "./_outputTest.scss";
export async function getAllUsers() {
  const myAPI = "paywellAPIResource";
  const path = "/listAllUsers";
  let users;
  await API.post(myAPI, path)
    .then((res) => {
      users = res;
console.log(users)
    })
    .catch((error) => {
      console.error(error);
      users = error;


      // showSnackBar("Failed fetching all users.", "error");
    });
  return users;
}
async function getClubReadyLogins(uid) {
  const q = query(
    collection(
      db,
      process.env.REACT_APP_CLUB_PILATES_ACCOUNTS_FIREBASE_COLLECTION
    ),
    where("uid", "==", uid)
  );
  const querySnapshot = await getDocs(q);
  if (querySnapshot.empty) {
    return false;
  }
  let logins = [];

  querySnapshot.forEach((doc) => {
    logins.push(doc.data());
  });

  return { logins: logins, status: "done" };
}

async function compareExcelFiles(s3FilePath1, s3FilePath2, includeExactMatches) {
  const myAPI = "paywellAPIResource";
  const path = "/compareExcelFiles";
  const event = {
    body: {
      s3FilePath1,
      s3FilePath2,
      includeExactMatches
    },
  };
  var result;
  await API.post(myAPI, path, event)
    .then((res) => {


      result = res;
    })
    .catch((error) => {
      console.error(error);
      // throw new Error(error);

    });
  return result;
}
function OutputTest() {
  const [comparisonData, setComparisonData] = useState(null);

  var { settings, dispatch, reportCompilerDispatch, reportCompilerState } =
    useSettingsContext();
  const {
    scrapeSelectedStudios,
    currentlySelectedStudiosDispatch,
    currentlySelectedStudios,
  } = useStudioScrapeStatus();

  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);

  const [logs, setLogs] = useState([]);
  // Function to update logs
  const updateLogs = (message) => {
    setLogs((prevLogs) => [...prevLogs, message]);
  };

  const clearLogs = () => {
    setLogs([]);
  };

  useEffect(() => {
    // Fetch user data when the component mounts
    async function fetchUsers() {
      try {
        // Use the provided getAllUsers function to fetch users
        const users = await getAllUsers();

        const activeUsers = users.users.filter((user) => !user.disabled);

        activeUsers.sort((a, b) => a.email.localeCompare(b.email));

        console.log(activeUsers);

        setUsers(activeUsers);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    }

    fetchUsers();
  }, []);

  const handleUserSelect = (event) => {
    const selectedOptions = Array.from(
      event.target.selectedOptions,
      (option) => {
        const userEmail = option.value;
        // Find the user object based on the email
        return users.find((user) => user.email === userEmail);
      }
    );

    setSelectedUsers(selectedOptions);
  };


  /**
   sessionSnapshotData.workbooks will be an object of all reports (KEY: S3 path, VALUE: excel/json)
   {
     "Club Pilates Surprise/Active Members 13173 10-08-2023 - 10-09-2023.xls": (EXCEL WORKBOOK)
     "Club Pilates Surprise/ClubReady Time Punch Detail 10-08-2023 - 10-09-2023": (JSON)
     "Club Pilates Ahwatukee/Booking Events Log 11913 - 10-7-2023 - 10-9-2023.xls": (EXCEL WORKBOOK)
   }
 
   we will need to iterate through each key to determine the reports type and studio index in order to run: 
 
 
   Example:  "Club Pilates Ahwatukee/Booking Events Log 11913 - 10-7-2023 - 10-9-2023.xls" ---> "bel1file"
 
   studioIndex = We can find the studioIndex based of the sessionSnapshotData.settings.generalSettings.studios.indexOf("Club Pilates Ahwatukee")
   reportType = We can find the file report type by passing in "Booking Events Log 11913 - 10-7-2023 - 10-9-2023.xls" to our converter function.
 
   fileId = reportType + studioIndex + "file"
 
   handleAddExcelData(report + (studioIndex + 1) + "file", workbook);
  */
  async function run() {
    clearLogs();
    setComparisonData(null);
    console.log("Testing the following users: ", selectedUsers);

    /**** FOR EACH USER */
    for (const user of selectedUsers) {
      updateLogs("------" + user.email + "------");
      if (user.disabled) {
        return;
      }

      const settingsIdArray = await getSettingsIdsForUid(user.uid)
        .then((settingsIds) => {
          console.log("Settings IDs:", settingsIds);

          return settingsIds;
        })
        .catch((error) => {
          console.error("Error:", error);
          updateLogs(`!ERROR!: ${error}`);

          return false;
        });

      /**** FOR EACH SETTINGS PRESET */
      for (const settingsId of settingsIdArray) {
        updateLogs("Email: " + user.email);
        updateLogs(
          `Number of Setting Presets to Test: ${settingsIdArray.length}`
        );
        updateLogs("Settings Preset #" + settingsId);

        const latestSessionPath = await fetchLatestSessionPathWithSnapshot(
          process.env.REACT_APP_PAYWELL_RUNS_BUCKET_NAME,
          user.uid,
          undefined,
          settingsId
        );

        if (latestSessionPath) {
          // Handle the latest session path
          updateLogs(
            `Session Path for Settings Preset #${settingsId}: ${latestSessionPath}`
          );
        } else {
          // Handle the case where the latest session path is not found
          updateLogs(
            `Session Path for Settings Preset #${settingsId}: Not Found`
          );
          updateLogs(``);
          continue;
        }

        updateLogs("Fetching presigned urls from S3...");

        const preSignedUrls = await fetchPreSignedUrls(
          process.env.REACT_APP_PAYWELL_RUNS_BUCKET_NAME,
          latestSessionPath
        );

        if (preSignedUrls && preSignedUrls.length > 0) {
          updateLogs("Processing S3 Urls...");
          const sessionSnapshotData = await processS3Urls(preSignedUrls);



          updateLogs("Extracting data from S3 Session Snapshot...");
          const { workbooks, studios } =
            getStudiosAndWorkbooksFromSessionData(sessionSnapshotData);

          console.log("sessionSnapshotData: ", sessionSnapshotData);

          console.log(
            "BEFORE BUILD_REPORT_COMPILER_STATE: ",
            reportCompilerState
          );

        
          updateLogs(
            "Building report compiler state using session snapshot settings..."
          );
          reportCompilerState = buildReportCompilerState(
            sessionSnapshotData.settings
          );

          // reportCompilerState = mealBreakFlag = true

          console.log(
            "AUTO TEST REPORT COMPILER STATE BUILT!: ",
            reportCompilerState
          );
          
          updateLogs("Compiling S3 session data using report compiler...");
          const latestSessionSnapshotData = await getReportCompilerStateFromLatestSessionSnapshot(
            latestSessionPath,
            studios,
            settingsId,
            reportCompilerState);
          
     
          reportCompilerState = latestSessionSnapshotData.updatedReportCompilerState;
          
       
          updateLogs("Downloading test output...");
          const liveOutputS3Path = latestSessionPath + "session_snapshot/output.xlsx";
          const liveOutputS3Url = await createPresignedUrl(
            process.env.REACT_APP_PAYWELL_RUNS_BUCKET_NAME,
            latestSessionPath + "session_snapshot/output.xlsx"
          );

          const liveOutputWorkbook = await getWorkbookFromS3(liveOutputS3Url);

          XLSX.writeFile(
            liveOutputWorkbook.workbook,
            liveOutputWorkbook.fileName
          );

          

          // Inject any new questions or changes we would like to test - scott
          // Example: reportCompilerState.inputFilesArrays.questions.mealBreakFlag = 1;

          
          reportCompilerState = handleDownload(
            {
              testMode: true,
              latestSessionPath,
            },
            reportCompilerState
          );

          var testOutputS3Path = await uploadTestOutput(
            latestSessionPath,
            reportCompilerState.output.workbook,
            reportCompilerState.output.name
          );
          reportCompilerState.output.testOutputS3Path = testOutputS3Path;
          
          updateLogs(`------ ${user.email} Test Complete ------`);

          updateLogs(`------ ${user.email} Outputs Comparison ------`);
          // Call lambda /compareExcelFiles(liveOutputS3Path, reportCompilerState.output.testOutputS3Path)

          updateLogs(`Comparing:`);
          updateLogs(`${liveOutputS3Path}`);
          updateLogs(`to`);
          updateLogs(`${reportCompilerState.output.testOutputS3Path}`);
         
          const comparisonData = await compareExcelFiles(liveOutputS3Path, reportCompilerState.output.testOutputS3Path, false);
          if (comparisonData) {
            setComparisonData(comparisonData); // Assuming comparisonResult is the data you want to display
          }

        } else {
          // Handle the case where pre-signed URLs are not found
          updateLogs("!ERROR!: Pre-signed URLs not found.");
        }
      }
      updateLogs(``);
      updateLogs(``);
      updateLogs(``);
    }
  }

  async function test() {
    console.log("Testing the following users: ", selectedUsers);
    const user = selectedUsers[0];
    setLogs([...logs, "Testing the following users: " + user.email]);

    if (user.disabled) {
      return;
    }

    console.log(`${user.email}\nUID: ${user.uid}`);
    setLogs([...logs, `| ${user.email} |: Getting user settings...`]);
    const res = await getUserSettings(user.uid);
    const userSettings = res.settings;

    setLogs([...logs, `| ${user.email} |: Getting Clubready Logins...`]);
    var scrapeAccounts = await getClubReadyLogins(user.uid);
    console.log("Clubready Accounts: ", scrapeAccounts);

    if (userSettings) {
      const settingPresets = Object.values(userSettings);
      console.log("Settings: ", settingPresets);

      for (const settingsPreset of settingPresets) {
        if (
          settingsPreset.generalSettings &&
          settingsPreset.generalSettings.studios
        ) {
          setLogs([
            ...logs,
            `| ${user.email} Preset #${settingsPreset.settingsId} |: Testing "${settingsPreset.settingsTitle}" preset with settings id of: ${settingsPreset.settingsId}`,
          ]);
          console.log(
            `Settings Preset: ${settingsPreset.settingsTitle} ${settingsPreset.settingsId}`
          );

          addOrUpdateUrlVariable("settingsId", settingsPreset.settingsId);

          setLogs([
            ...logs,
            `| ${user.email} Preset #${settingsPreset.settingsId} |: Building report compiler state...`,
          ]);

          let reportCompilerState = buildReportCompilerState(userSettings);
          console.log("Report Compiler State: ", reportCompilerState);

          let studiosWithoutAccount = findStudiosWithoutAccount(
            settingsPreset.generalSettings.studios,
            scrapeAccounts.logins
          );

          let studiosWithAccounts =
            settingsPreset.generalSettings.studios.filter(
              (studioName) => !studiosWithoutAccount.includes(studioName)
            );

          setLogs([
            ...logs,
            `| ${user.email} Preset #${settingsPreset.settingsId
            } |: Scraping ${studiosWithAccounts.join(", ")}`,
          ]);

          await new Promise((resolve) => setTimeout(resolve, 3000));

          const scrapeSelectedStudiosPromise = scrapeSelectedStudios(
            studiosWithAccounts,
            scrapeAccounts,
            reportCompilerState,
            false
          );
          await Promise.all([scrapeSelectedStudiosPromise]);

          /** 
            - implement retry logic for if studios have any failed reports
            - I also need to figure out how to manage the studio scrape status context when running test. 
            */

          console.log("Done scraping");
          // handleDownload(folderPath);
        }
      }
    }
  }

  return (
    <div className="output-container">
      <label htmlFor="userEmails">Select user(s):</label>
      <select
        id="userEmails"
        multiple
        onChange={handleUserSelect}
        value={selectedUsers.map((user) => user.email)}
      >
        {users.map((user) => (
          <option key={user.email} value={user.email}>
            {`${user.email} - ${user.uid}`}
          </option>
        ))}
      </select>
      <button onClick={run}>Start Test</button>

      <br />
      <br />
      <h6>Test Log</h6>
      <ul>
        {logs.map((log) => (
          <li>{log}</li>
        ))}
      </ul>

      <ComparisonView data={comparisonData} />
    </div>
  );
}

export default OutputTest;


