import React, { useEffect, useState } from 'react';
import { TextField, Switch, FormControlLabel, Box } from '@mui/material';

/**
 * A component that combines a toggle switch and text input for numerical values.
 * When disabled, the value is set to -1 and the text input is hidden.
 * When enabled, allows entering a custom numerical value.
 *
 * @param {Object} props
 * @param {number} props.value - The current numerical value
 * @param {function} props.onChange - Callback function to update the value
 * @param {string} [props.inputLabel] - Label for the text input field
 * @param {string} [props.id] - Optional ID to pass to the TextField
 * @param {Object} props.textFieldProps - Additional props to pass to TextField
 * @returns {JSX.Element}
 */
const NegativeOneToggleInput = ({ 
  value, 
  onChange,
  inputLabel = "Value",
  id,
  textFieldProps = {}
}) => {
  const [isEnabled, setIsEnabled] = useState(value > -1);

  useEffect(() => {
    setIsEnabled(value > -1);
  }, [value]);

  const handleToggleChange = (event) => {
    if (!event.target.checked) {
      onChange(-1);
    } else {
      onChange(1); // Default value when enabled
    }
  };

  const handleValueChange = (event) => {
    const newValue = Number(event.target.value);
    if (!isNaN(newValue)) {
      onChange(newValue);
    }
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
      <Switch 
        checked={isEnabled}
        onChange={handleToggleChange}
      />
      
      {isEnabled && (
        <TextField
          type="number"
          value={value}
          onChange={handleValueChange}
          label={inputLabel}
          size="small"
          id={id}
          {...textFieldProps}
        />
      )}
    </Box>
  );
};

export default NegativeOneToggleInput;
