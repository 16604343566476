import React, { useCallback, useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { Box, Typography, Button } from "@mui/material";
import FolderRoundedIcon from "@mui/icons-material/FolderRounded";
import FileUploadStatus from "../../components/FileUploadStatus";
import { createStaffEmailArrays } from "../../utility-functions/EmailCreation/CreateEmails";
import { useAuth } from "../../contexts/AuthContext";
import { useSettingsContext } from "../../contexts/SettingsContext";
import { buildReportCompilerState } from "../../contexts/ReportCompiler/utility-functions";
import {
  getUrlVariableValue,
  stringIsNotNumber,
} from "../../utility-functions/utility-functions";
import "./staffEmailer.scss"; // For any minor styling tweaks
import BasicTable from "../../components/Table";

/* ----------------- NEW HELPERS FOR ROW TYPING ------------------- */
/**************************************
 * HELPER: Check if numeric
 **************************************/
function isNumeric(val) {
  if (typeof val === "number") return !isNaN(val);
  if (typeof val === "string") {
    const trimmed = val.trim();
    return trimmed && !isNaN(parseFloat(trimmed)) && isFinite(trimmed);
  }
  return false;
}

/**************************************
 * HELPER: Convert to USD
 **************************************/
function toUsd(value) {
  const floatVal = parseFloat(value);
  return floatVal.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  });
}

/**************************************
 * Detect subheader row
 **************************************/
function isSubheaderRow(row) {
  // subheader => first cell is non-empty text, rest are empty
  if (typeof row[0] === "string" && row[0].trim() !== "") {
    return row.slice(1).every((cell) => cell === "");
  }
  return false;
}

/**************************************
 * Detect totals row
 **************************************/
function isTotalsRow(row, rowIndex, totalRows) {
  const firstCell = typeof row[0] === "string" ? row[0].toLowerCase() : "";
  if (firstCell.includes("totals") || firstCell.includes("subtotal")) return true;
  if (rowIndex === totalRows - 1) return true;
  return false;
}


/**************************************
 * 4) Convert 2D array -> typed rows
 *************************************/
/**************************************
 * 4) Convert 2D array -> typed rows
 *    with new "columnHeader" logic
 *************************************/
function identifyRowTypes(rows) {
  let previousRowType = null;

  return rows.map((row, rowIndex) => {
    // 1) Check if this row is a subheader
    if (isSubheaderRow(row)) {
      previousRowType = "subheader";
      return { type: "subheader", data: row };
    }

    // 2) If previous row was subheader => this row => "columnHeader"
    if (previousRowType === "subheader") {
      previousRowType = "columnHeader";
      return { type: "columnHeader", data: row };
    }

    // 3) Else check if it's a totals row
    if (isTotalsRow(row, rowIndex, rows.length)) {
      previousRowType = "totals";
      return { type: "totals", data: row };
    }

    // 4) Otherwise => normal row
    previousRowType = "normal";
    return { type: "normal", data: row };
  });
}


/**************************************
 * 5) Format row columns per new rules
 *************************************/
// function formatRowAccordingToRules(rowObj) {
//   const { type, data } = rowObj;

//   // RULE 1: Always format col 6 (index 5) if numeric
//   if (data.length > 5 && isNumeric(data[5])) {
//     data[5] = toUsd(data[5]);
//   }

//   // Only apply these if the row is "columnHeader"
//   if (type === "columnHeader") {
//     // RULE 2a: If row[0] includes "--" => col 5 (index 4) => $
//     if (typeof data[0] === "string" && data[0].includes("--")) {
//       if (data.length > 4 && isNumeric(data[4])) {
//         data[4] = toUsd(data[4]);
//       }
//     }

//     // RULE 2b: If data[2] is blank, and data[1], data[3] are non-empty => col 4 => $
//     if (data.length > 3) {
//       const col2 = data[2]; // index 2
//       const col1 = data[1]; // index 1
//       const col3 = data[3]; // index 3

//       // Check if col2 is blank, col1 & col3 are non-empty
//       const col2IsBlank = (col2 == null || col2.trim() === "");
//       const col1NotEmpty = (typeof col1 === "string" && col1.trim() !== "");
//       const col3NotEmpty = (typeof col3 === "string" && col3.trim() !== "");

//       if (col2IsBlank && col1NotEmpty && col3NotEmpty) {
//         // Format col3 if numeric
//         if (isNumeric(col3)) {
//           data[3] = toUsd(col3);
//         }
//       }
//     }
//   }

//   return rowObj;
// }

function formatRowAccordingToRules(rowObj) {
  const { type, data, applyColumnFormat } = rowObj;

  // 1) Always format col6 (index5)
  if (data.length > 5 && isNumeric(data[5])) {
    data[5] = toUsd(data[5]);
  }

  // 2) If applyColumnFormat is set => format that column
  if (applyColumnFormat != null && data.length > applyColumnFormat) {
    const colIndex = applyColumnFormat;
    if (isNumeric(data[colIndex])) {
      data[colIndex] = toUsd(data[colIndex]);
    }
  }

  return rowObj;
}



/**************************************
 * Identify row types + track "activeFormatColumn"
 **************************************/
function parseRowsWithColumnRules(rows) {
  let previousRowWasSubheader = false;
  let activeFormatColumn = null;
  const output = [];

  for (let i = 0; i < rows.length; i++) {
    const row = rows[i];
    let rowType = "normal";

    // (1) subheader detection
    if (isSubheaderRow(row)) {
      rowType = "subheader";
      previousRowWasSubheader = true;
      // do not set activeFormatColumn yet, we'll decide after next row
      // (the next row is columnHeader)
      output.push({ type: rowType, data: row });
      continue;
    }

    // (2) If the previous row was subheader => this is columnHeader
    if (previousRowWasSubheader) {
      rowType = "columnHeader";
      previousRowWasSubheader = false;

      // set activeFormatColumn based on your conditions
      if (typeof row[0] === "string" && row[0].includes("--")) {
        // row[4]
        activeFormatColumn = 4;
      } else if (shouldFormatCol3(row)) {
        // row[3]
        activeFormatColumn = 3;
      } else {
        activeFormatColumn = null;
      }

      output.push({ type: rowType, data: row, applyColumnFormat: activeFormatColumn });
      continue;
    }

    // (3) totals detection
    if (isTotalsRow(row, i, rows.length)) {
      rowType = "totals";

      // we still want to format the totals row using activeFormatColumn
      // so we DO push { applyColumnFormat: activeFormatColumn }
      output.push({
        type: rowType,
        data: row,
        applyColumnFormat: activeFormatColumn,
      });

      // after pushing the totals row, we reset
      activeFormatColumn = null;
      continue;
    }

    // (4) Otherwise => normal row => also apply activeFormatColumn
    rowType = "normal";
    output.push({
      type: rowType,
      data: row,
      applyColumnFormat: activeFormatColumn,
    });
  }

  return output;
}


/**************************************
 * Should we format col 3?
 * row[2] blank, row[1], row[3] non-empty
 **************************************/
function shouldFormatCol3(row) {
  const col2 = row[2]; // index 2
  const col1 = row[1]; // index 1
  const col3 = row[3]; // index 3

  const col2IsBlank = col2 == null || col2.trim() === "";
  const col1NotEmpty = typeof col1 === "string" && col1.trim() !== "";
  const col3NotEmpty = typeof col3 === "string" && col3.trim() !== "";
  return col2IsBlank && col1NotEmpty && col3NotEmpty;
}






/* ----------------------------------------------------------------
   Helper #1: Check if a row is fully empty (for normal rows)
   - If row is subheader/totals => not empty
   - If row is normal => see if each cell is empty
------------------------------------------------------------------*/
function isRowEmpty(rowObj) {
  // subheader or totals => never considered "empty"
  if (rowObj.type === "subheader" || rowObj.type === "totals") {
    return false;
  }
  // otherwise, rowObj.type === "normal"
  // check if every cell is falsy or blank
  return rowObj.data.every(
    (cell) => cell === "" || cell === null || cell === undefined
  );
}

/* ----------------------------------------------------------------
   1) Subheader Logic
   - We'll filter out empty normal rows in typedRows before building sections
------------------------------------------------------------------*/
function buildSectionsFromTypedRows(typedRows) {
  const sections = [];
  let currentSection = null;

  typedRows.forEach((rowObj) => {
    if (rowObj.type === "subheader") {
      if (currentSection) sections.push(currentSection);
      currentSection = {
        subheaderTitle: rowObj.data[0] || "Untitled",
        rows: [],
      };
    } else {
      if (!currentSection) {
        currentSection = { subheaderTitle: "Other Classes", rows: [] };
      }
      currentSection.rows.push(rowObj);
    }
  });

  if (currentSection) sections.push(currentSection);
  return sections;
}



/* ----------------------------------------------------------------
   2) Renders typedRows with subheader sections
------------------------------------------------------------------*/
function splitOutTrailingTotals(sections) {
  // If no sections exist, just return
  if (!sections.length) return sections;

  // Grab the last section
  const lastSection = sections[sections.length - 1];
  if (!lastSection?.rows?.length) return sections;

  // Find the *first* totals row in the last section
  let firstTotalsIndex = -1;
  for (let i = 0; i < lastSection.rows.length; i++) {
    if (lastSection.rows[i].type === "totals") {
      firstTotalsIndex = i;
      break;
    }
  }

  // If there's no totals row or it appears as the last row, there's no trailing totals
  if (firstTotalsIndex === -1 || firstTotalsIndex === lastSection.rows.length - 1) {
    return sections;
  }

  // Everything after the first totals row is considered trailing totals
  const trailingRows = lastSection.rows.splice(firstTotalsIndex + 1);

  // Create a new section for these trailing totals
  const newSection = {
    subheaderTitle: "Totals",
    rows: [
      // Insert your desired columnHeader row if needed:
      {
        type: "columnHeader",
        data: ["Type", "", "", "", "", "Payment"],
      },
      ...trailingRows,
    ],
  };

  // Push the new section onto our sections array
  sections.push(newSection);

  return sections;
}



/**
 * Extracts the first part of the employee name from a string containing multiple names separated by '/'.
 * @param {string} fullName - The full name string (e.g., "Sue B/alsdf").
 * @returns {string} - The first part of the name (e.g., "Sue B").
 */
export function getFirstName(fullName) {
  debugger;
  if(Array.isArray(fullName)) {
    fullName = fullName[0];
  }
  if (fullName.includes("/")) {
    const parts = fullName.split('/');
    const firstPart = parts[0].trim();
    return firstPart;
  } else {
    return fullName
  }

}


/**
 * Limits the displayed locations to a maximum of three and appends a "+N Locations" indicator for any additional locations.
 * @param {string} locationsStr - The location names separated by '/' (e.g., "Location 1/Location 2/Location 3/Location 4/Location 5").
 * @returns {string} - The formatted location string (e.g., "Location 1, Location 2, Location 3 +2 Locations").
 */
export function getDisplayLocations(locationsStr) {
 
  if(locationsStr.includes("/")) {

  
  if (!locationsStr) return "";
  const locations = locationsStr.split('/').map(loc => loc.trim()).filter(loc => loc !== "");
  const maxDisplay = 3;
  const displayLocations = locations.slice(0, maxDisplay);
  const remaining = locations.length - maxDisplay;
  if (remaining > 0) {
    return `${displayLocations.join(", ")} +${remaining} Location${remaining > 1 ? "s" : ""}`;
  }
  return displayLocations.join(", ");
 } else {
    return locationsStr
  }
}


/* --------------------------------------------------------------- */

export default function StaffEmailerDropzone(props) {
  const { settings } = useSettingsContext();
  const { currentUser } = useAuth();
  const [state, setState] = useState();

  // Provided helper functions
  const createSeperateTables = (rowsFiltered, rows) => {
    var jsxTables = [];
    let sliceIndexes = [];
    for (let i = 0; i < rowsFiltered.length; i++) {
      if (rowsFiltered[i].length === 1) {
        sliceIndexes.push(i);
      }
    }
    let tables = [];
    for (let i = 0; i < sliceIndexes.length; i++) {
      if (i === 0) {
        tables.push(rows.slice(0, sliceIndexes[i]));
      } else {
        tables.push(rows.slice(sliceIndexes[i - 1], sliceIndexes[i]));
      }
    }
    tables.push(rows.slice(sliceIndexes[sliceIndexes.length - 1]));

    // Convert each sub-table to a BasicTable React element
    jsxTables = tables.map((table, index) => {
      return <BasicTable key={index} rows={table} />;
    });
    return jsxTables;
  };

  const filterArray = (arr, val) => arr.filter((el) => el !== val);
  const filterNestedArray = (arr, val) => {
    const firstLevel = filterArray(arr, val);
    return firstLevel.map((el) =>
      Array.isArray(el) ? filterArray(el, val) : el
    );
  };

  const removeEmptyRows = (array) => {
    return array.filter(
      (row) => !row.every((cell) => cell === "" || cell === "'")
    );
  };

  const getRowHeaders = (array) => {
    return array.map((col) => col[0]);
  };

  const deleteExcessRows = (table) => {
    for (let i = table.length - 1; i >= 0; i--) {
      for (let j = 0; j < table[i].length; j++) {
        if (table[i][j].length > 0) {
          return table.slice(0, i + 1);
        }
      }
    }
  };

  // -----------
  // MAIN LOGIC
  // -----------
  const handleUploadExport = async (acceptedFiles) => {
    // Attempt to build the "reportCompilerState"
    let reportCompilerState = false;
    if (
      settings &&
      settings.staff &&
      Object.keys(settings.staff.staff).length > 0
    ) {
      reportCompilerState = await buildReportCompilerState(settings);
    } else {
      console.warn("No staff! settings is not loaded.");
      props.setIsEmailsLoaded(true);
      return;
    }

    // Indicate we have a file
    setState(
      <FileUploadStatus
        menuHidden={true}
        status={"done"}
        fileName={acceptedFiles[0].path}
      />
    );

    // Determine CC email
    let ccEmail = null;
    try {
      let user = await currentUser;
      ccEmail = user.email;
    } catch (e) {
      console.error("Failed to get currentUser: ", e);
    }

    const ccSetting = settings[getUrlVariableValue("settingsId")]?.generalSettings?.emailCcRecipients;
    debugger;
    // If ccSetting is null/undefined, use ccEmail (current user's email)
    // If ccSetting is empty array, use no CC
    // If ccSetting has emails, use those emails
    if (ccSetting === null || ccSetting === undefined) {
      ccEmail = ccEmail;  // Use current user's email
    } else if (Array.isArray(ccSetting) && ccSetting.length === 0) {
      ccEmail = "";  // No CC
    } else if (Array.isArray(ccSetting)) {
      ccEmail = ccSetting.join(",");  // Use provided emails
    }

    // Not loaded yet
    props.setIsEmailsLoaded(false);

    // Parse the Excel into "staffEmailArrays"
    let emailContent = await createStaffEmailArrays(
      acceptedFiles[0],
      reportCompilerState.studiosInformation.staffArray
    );

    // For each staff's data, build the email payload
    emailContent.forEach((staff) => {
      const to = staff.address;
      const cc = ccEmail;
      const subject =
        reportCompilerState.inputFilesArrays.questions.customEmailSubjectLine
          .value;

      // "table" = the row data after removing the first 6 rows
      let rows = removeEmptyRows(staff.content.slice(6));

      let rowsFiltered = filterNestedArray(rows, "");
      let tablesArray = createSeperateTables(rowsFiltered, rows);
      let plainTable = deleteExcessRows(staff.content.slice(6)); // the raw 2D array

      let headers = removeEmptyRows(staff.content.slice(0, 6));
      let filteredHeaders = getRowHeaders(staff.content.slice(0, 6));

      // Extract fields from "filteredHeaders"
      // Extract raw employeeName and locationName from headers
      const rawEmployeeName = filteredHeaders[4] || "";
      const rawLocationName = filteredHeaders[0] || "";

      // Processed names
      const employeeName = getFirstName(rawEmployeeName);
      const locationName = getDisplayLocations(rawLocationName);

      debugger;
      const payPeriod = filteredHeaders[1] || "";
      const customEmailTextContent =
        settings[getUrlVariableValue("settingsId")]?.generalSettings
          ?.customEmailTextContent || "";

      // The "totals" presumably in the last row's 6th cell
      const totalPeriodEarnings = plainTable[plainTable.length - 1][5] || 0;

      // 1) Parse rows w/ new logic to get row types + applyColumnFormat
      let typedRows = parseRowsWithColumnRules(plainTable);
      // 1) Remove empty rows for each emailCard

      // A) Filter out empty normal rows
      typedRows = typedRows.filter((rowObj) => !isRowEmpty(rowObj));

      // 2) Format each row
      typedRows = typedRows.map(formatRowAccordingToRules);


      // 1) Build sections
      let sections = buildSectionsFromTypedRows(typedRows);

      // 2) Post-process the last section to move trailing totals
      sections = splitOutTrailingTotals(sections);


      // Build the final payload
      const payload = {
        to,
        cc,
        subject,
        employeeName,
        location: locationName,
        payPeriod,
        customEmailTextContent,
        payCheckAmount: toUsd(totalPeriodEarnings),
        sections,
        // Instead of a raw 2D array, store typedRows
        typedRows,
      };

      // Build the local "email card" object for preview
      const emailCard = {
        headers,
        staffEmail: staff.address,
        zeroEarnings: totalPeriodEarnings === 0,
        payCheckAmount: totalPeriodEarnings,
        typedRows, // store typed rows for your preview
        tables: tablesArray, // for your older BasicTable preview if needed
        payload,
      };



      // Add to local state
      props.handleAddEmailCard(emailCard);
      props.addEmail(payload, to, cc, subject, emailCard);

      props.setTableHeaders(headers);
      props.setTables(props.tables.concat(tablesArray));
      props.setTables(tablesArray);
    });

    // Done loading
    props.setIsEmailsLoaded(true);
  };

  // -----------
  //  DROPZONE
  // -----------
  const onDrop = useCallback(async (acceptedFiles) => {
    props.setEmailCards([]);
    props.setEmails([]);
    props.setIsEmailsLoaded(false);
    handleUploadExport(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept:
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
    onDrop,
  });

  // Return the UI for the dropzone
  return (
    <Box className="staff-emailer-dropzone">
      <Box
        {...getRootProps()}
        sx={{
          cursor: "pointer",
          border: "2px dashed #CBD2EA",
          borderRadius: 2,
          backgroundColor: isDragActive ? "#E8ECFE" : "#F4F7FD",
          color: "#5f697a",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "8em",
          textAlign: "center",
          p: 2,
          transition: "background-color 0.2s ease-in-out",
        }}
      >
        <input {...getInputProps()} />
        <FolderRoundedIcon
          fontSize="large"
          sx={{ color: isDragActive ? "#4d5efa" : "#999", mb: 1 }}
        />
        {isDragActive ? (
          <Typography variant="body2">Drop your file here…</Typography>
        ) : (
          <Typography variant="body2">
            Drag &amp; drop or{" "}
            <Button variant="text" size="small">
              Browse
            </Button>
          </Typography>
        )}
      </Box>
      <Box sx={{ mt: 1 }}>{state}</Box>
    </Box>
  );
}
