import { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useSettingsContext } from '../../contexts/SettingsContext';
import { getUrlVariableValue } from '../../utility-functions/utility-functions';

/**
 * This callback lets pages implement logic to switch to a tab given the tab's ID as a string.
 *
 * @callback setTabCallback
 * @param {string} tabId - the ID of the tab that should be opened.
 * */

/**
 * A custom React hook that scrolls the document to the element with the ID specified in the URL hash.
 * The scroll occurs when the component mounts or when the `settings` object from SettingsContext 
 * changes.
 *
 * @param {setTabCallback} [setTab] - A function that takes `tabId`, and must set the individual 
 *                                    page's open tab accordingly.
 *
 * @returns {void} This hook does not return any value.
 *
 * @example
 * // For a page with no tabs, this will scroll to the setting based on the id in the URL hash.
 * useAutoScrollToHash();
 *
 * @example
 * // For a page with tabs, this will open the tab indicated in the `tabId` query parameter
 * const [tab, setTab] = useState("General");
 * // ...
 * useAutoScrollToHash((tabId) => {setTab(tabId});
 * // ... render based on the current value of `tab`
 */
const useAutoScrollToHash = (setTab) => {
  const [scrollFinished, setScrollFinished] = useState(false);
  const { settings } = useSettingsContext();
  const params = useParams();
  const location = useLocation();
  const prevTabId = useRef();
  const prevHash = useRef();
  const history = useHistory();

  useEffect(() => {
    (async () => {
      const tabId = getUrlVariableValue('tabId');
      const hash = window.location.hash;
      if (!scrollFinished || prevTabId.current !== tabId || prevHash.current !== hash) {
        prevTabId.current = tabId;
        prevHash.current = hash;
        if (tabId && setTab) {
          setTab(tabId);
          await (new Promise((res, _) => setTimeout(() => res(), 200))); // 200ms delay for tab-change animation
        }
        if (hash) {
          const id = hash.slice(1);
          const element = document.getElementById(id);
          if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'center' });
            setScrollFinished(true);
          }
        } else {
          /* If there's no hash in the URL, you need to set scrollFinished to true, or else there will be an infinte rerender because
           * 1. `if (!scrollFinished || prevTabId.current !== tabId || prevHash.current !== hash) {` will trigger
           * 2. Below, the location will be updated via `history.push()`
           * 3. Because `location` is a dependency of this useEffect, there will be an infinite rerender
           */
          setScrollFinished(true);
        }
        // Clear tabId from history
        const newQueryParams = new URLSearchParams(location.search);
        newQueryParams.delete("tabId");
        let newLocation = {
          pathname: location.pathname,
          search: newQueryParams.toString(),
          hash: hash ? `${hash}` : ""
        };
        history.push(newLocation);
      }
    })();
  }, [params, settings]);
};

export default useAutoScrollToHash;
