import ClassCatchAllList from "./ClassCatchAllBox";
import CommissionCatchAllList from "./CommissionCatchAllBox";
import TimeCatchAllList from "./TimeCatchAllBox";
import React, { useState } from "react";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Typography } from "@mui/material";
import {  Skeleton, Divider } from "@mui/material";
import { getUrlVariableValue } from "../../../utility-functions/utility-functions.js";
import useAutoScrollToHash from "../useAutoScrollToHash";

import { useSettingsContext } from "../../../contexts/SettingsContext";
const tabLookup = {
  class: <ClassCatchAllList></ClassCatchAllList>,
  time: <TimeCatchAllList></TimeCatchAllList>,
  commission: <CommissionCatchAllList></CommissionCatchAllList>,
};

export default function Exceptions() {
const {settings} = useSettingsContext();
  const [tab, setTab] = useState(tabLookup["class"]);

  const handleTabChange = (event) => {
    let id = event.target.id;
    setTab(tabLookup[id]);
  };

  useAutoScrollToHash((tabId) => {
    if (!tabLookup.hasOwnProperty(tabId)) {
      console.warn(`No tab with id: "${tabId}"`);
      return;
    }
    setTab(tabLookup[tabId]);
  });

  return (settings.hasOwnProperty("1") && settings.hasOwnProperty("staff"))  ? (
    <Box className="settings-page">
    <Typography variant="h2" className="settings-page__title">
      Exceptions
    </Typography>
    <Typography variant="p" className="settings-page__title-description">
        {/* <Link href=""><VideocamIcon/>Tutorial</Link> */}
        {`(New Features Coming Soon) - Add one-off exceptions to sessions, time, and commission items.`}
      </Typography>
    <Tabs
      value={tab}
      onChange={handleTabChange}
      className="settings-page__tabs"
      centered
    >
      <Tab label={settings[getUrlVariableValue("settingsId")].generalSettings.sessionName} id="class" value={tabLookup["class"]} />
      <Tab label={settings[getUrlVariableValue("settingsId")].generalSettings.timeName} id="time" value={tabLookup["time"]} />
      <Tab
        label={settings[getUrlVariableValue("settingsId")].generalSettings.commissionName}
        id="commission"
        value={tabLookup["commission"]}
      />
    </Tabs>
    {tab}
  </Box>
  ) : (
    <Box className="settings-page">
      <Skeleton>
        <Typography variant="h2" className="settings-page__title">
          Studios
        </Typography>
      </Skeleton>
      <Divider className="settings-page__divider" />
      <Skeleton className="settings-page__attribute-box settings-page__attribute-box--skeleton"></Skeleton>
    </Box>
  );
}
