import React, { useEffect } from "react";
import IconWrapper from "./IconWrapper";
import LinearProgressBar from "./LinearProgressBar";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import Tooltip from "@mui/material/Tooltip";

import Menu from "./Menu";

export default function FileUploadStatus(props) {
  
  if (props.fileType === "input") {
    if (props.status === "fileError") {
      return (
        <Tooltip
          sx={{
            fontSize: "5rem",
          }}
          title="Invalid Input File"
          placement="top"
          arrow
        >
          <div
            className="file-upload-status file-upload-status--file-error"
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              width: "100%",
              paddingLeft: "1em",
              marginTop: "1em",
              fontSize: "1em",
            }}
          >
            <IconWrapper color={"red"} />
            <p
              style={{
                display: "flex",
                fontSize: ".75em",
                color: "red",
                alignItems: "center",
                marginBottom: "0",
              }}
            >
              {props.fileName}
            </p>
            <ErrorOutlineIcon
              style={{
                marginLeft: "auto",
                color: "red",
              }}
            />
          </div>
        </Tooltip>
      );
    } else if (props.status === "uploadError") {
      return (
        <Tooltip
          sx={{
            fontSize: "5rem",
          }}
          title={"Failed to Upload File"}
          placement="top"
          arrow
        >
          <div
            className="file-upload-status file-upload-status--upload-error"
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              width: "100%",
              paddingLeft: "1em",
              marginTop: "1em",
              fontSize: "1em",
            }}
          >
            <IconWrapper color={"red"} />
            <p
              style={{
                display: "flex",
                fontSize: ".75em",
                color: "red",
                alignItems: "center",
                marginBottom: "0",
              }}
            >
              {props.fileName}
            </p>
            <ErrorOutlineIcon
              style={{
                marginLeft: "auto",
                color: "red",
              }}
            />
          </div>
        </Tooltip>
      );
    } else if (props.status === "loading") {
      return (
        <div
          className="file-upload-status file-upload-status--loading"
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            width: "100%",
            paddingLeft: "1em",
            marginTop: "1em",
            fontSize: "1em",
          }}
        >
          <IconWrapper color={"#4baf4f"} />
          <p
            style={{
              display: "flex",
              fontSize: ".75em",
              alignItems: "flex-start",
              justifyContent: "space-between",
              width: "100%",
              marginBottom: "0",
              flexDirection: "column",
              height: "2.2em",
            }}
          >
            {props.fileName}

            <LinearProgressBar />
          </p>
        </div>
      );
    } else if (props.status === "done") {
      return (
        <div
          className="file-upload-status file-upload-status--done"
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            width: "100%",
            paddingLeft: "1em",
            marginTop: "1em",
            fontSize: "1em",
          }}
        >
          <IconWrapper color={"#4baf4f"} />
          <p
            style={{
              display: "flex",
              fontSize: ".75em",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
              marginBottom: "0",
              flexDirection: "row",
              height: "2.2em",
            }}
          >
            {props.fileName}
            {/* <LinearWithValueLabel /> */}
            <CheckCircleRoundedIcon
              style={{
                color: "#4baf4f",
              }}
            />
            {/* <LinearProgressBar /> */}
          </p>
        </div>
      );
    } else {
      return (
        <div
          className="file-upload-status"
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            width: "100%",
            paddingLeft: "1em",
            marginTop: "1em",
            fontSize: "1em",
          }}
        >
          <IconWrapper color={"#4baf4f"} />
          <p
            style={{
              display: "flex",
              fontSize: ".75em",
              alignItems: "center",
              marginBottom: "0",
            }}
          >
            {props.fileName}
          </p>
        </div>
      );
    }
  } else if (props.fileType === "manualUpload") {
    if (props.status === "done") {
      return (
        <div
          className="file-upload-status file-upload-status--done"
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            width: "100%",
            paddingLeft: "1em",
            marginTop: "1em",
            fontSize: "1em",
          }}
        >
          <IconWrapper color={"#4baf4f"} />
          <p
            style={{
              display: "flex",
              fontSize: ".75em",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
              marginBottom: "0",
              flexDirection: "row",
              height: "2.2em",
            }}
          >
            {props.fileName}
            {/* <LinearWithValueLabel /> */}
            <CheckCircleRoundedIcon
              style={{
                color: "#4baf4f",
              }}
            />
            {/* <LinearProgressBar /> */}
          </p>
          <Menu
            isDropZone={true}
            deleteOnly={props.deleteOnly ? props.deleteOnly : false}
            reports={props.reports}
            fileType={props.fileType}
            studioIndex={props.studioIndex}
            handleSetFileStatusToFileError={
              props.handleSetFileStatusToFileError
            }
            handleSetFileStatusToUploadError={
              props.handleSetFileStatusToUploadError
            }
            handleSetFileStatusToStudioError={
              props.handleSetFileStatusToStudioError
            }
            updateIfStudioIsComplete={props.updateIfStudioIsComplete}
            handleSetFileStatusToLoading={props.handleSetFileStatusToLoading}
            fileIndex={props.fileIndex}
            handleSetFileStatusToDone={props.handleSetFileStatusToDone}
            handleDeleteReport={props.handleDeleteReport}
          />
        </div>
      );
    }
  } else if (props.status === "idle") {
    return (
      <div
        className="file-upload-status file-upload-status--idle"
        data-report={props.fileType}
        data-file-name={props.fileName}
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          width: "100%",
          paddingLeft: "1em",
          marginTop: "1em",
          fontSize: "1em",
        }}
      >
        <IconWrapper color={"#c3c3c3"} />
        <p
          style={{
            display: "flex",
            fontSize: ".75em",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            marginBottom: "0",
            flexDirection: "row",
            height: "2.2em",
          }}
        >
          {props.fileName}
          {/* <LinearWithValueLabel /> */}
          {/* <ReplayCircleFilledRoundedIcon
            style={{ color: "#c3c3c3" }}
          ></ReplayCircleFilledRoundedIcon> */}
          {/* <LinearProgressBar /> */}
        </p>

        {props.menuHidden ? null :
        <Menu
          deleteOnly={props.deleteOnly ? props.deleteOnly : false}
          reports={props.reports}
          fileType={props.fileType}
          studioIndex={props.studioIndex}
          handleSetFileStatusToFileError={props.handleSetFileStatusToFileError}
          handleSetFileStatusToUploadError={
            props.handleSetFileStatusToUploadError
          }
          handleSetFileStatusToStudioError={
            props.handleSetFileStatusToStudioError
          }
          updateIfStudioIsComplete={props.updateIfStudioIsComplete}
          handleSetFileStatusToLoading={props.handleSetFileStatusToLoading}
          fileIndex={props.fileIndex}
          handleSetFileStatusToDone={props.handleSetFileStatusToDone}
          handleUploadReport={props.handleUploadReport}
          handleRetryReport={props.handleRetryReport}
          handleDeleteReport={props.handleDeleteReport}
        />
  }
      </div>
    );
  } else if (props.status === "loading") {
    return (
      <div
        className="file-upload-status file-upload-status--loading"
        data-report={props.fileType}
        data-file-name={props.fileName}
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          width: "100%",
          paddingLeft: "1em",
          marginTop: "1em",
          fontSize: "1em",
        }}
      >
        <IconWrapper color={"#4baf4f"} />
        <p
          style={{
            display: "flex",
            fontSize: ".75em",
            alignItems: "flex-start",
            justifyContent: "space-between",
            width: "100%",
            marginBottom: "0",
            flexDirection: "column",
            height: "2.2em",
          }}
        >
          {props.fileName}
          {/* <LinearWithValueLabel /> */}

          <LinearProgressBar />
        </p>
        {props.menuHidden ? null :
        <Menu
          isDropZone={false}
          cancelOnly={true}
          deleteOnly={true}
          handleDeleteReport={props.handleDeleteReport}
          reports={props.reports}
          fileType={props.fileType}
          studioIndex={props.studioIndex}
          handleSetFileStatusToFileError={props.handleSetFileStatusToFileError}
          handleSetFileStatusToUploadError={
            props.handleSetFileStatusToUploadError
          }
          handleSetFileStatusToStudioError={
            props.handleSetFileStatusToStudioError
          }
          updateIfStudioIsComplete={props.updateIfStudioIsComplete}
          handleSetFileStatusToLoading={props.handleSetFileStatusToLoading}
          fileIndex={props.fileIndex}
          handleSetFileStatusToDone={props.handleSetFileStatusToDone}
          handleUploadReport={props.handleUploadReport}
          handleRetryReport={props.handleRetryReport}
        />}
      </div>
    );
  } else if (props.status === "done") {
    return (
      <div
        className="file-upload-status file-upload-status--done"
        data-report={props.fileType}
        data-file-name={props.fileName}
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          width: "100%",
          paddingLeft: "1em",
          marginTop: "1em",
          fontSize: "1em",
        }}
      >
        <IconWrapper color={"#4baf4f"} />
        <p
          style={{
            display: "flex",
            fontSize: ".75em",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            marginBottom: "0",
            flexDirection: "row",
            height: "2.2em",
          }}
        >
          {props.fileName}
          {/* <LinearWithValueLabel /> */}
          <CheckCircleRoundedIcon
            style={{
              color: "#4baf4f",
            }}
          />
          {/* <LinearProgressBar /> */}
        </p>

        {props.menuHidden ? null :  <Menu
          deleteOnly={props.deleteOnly ? props.deleteOnly : false}
          reports={props.reports}
          fileType={props.fileType}
          studioIndex={props.studioIndex}
          handleSetFileStatusToFileError={props.handleSetFileStatusToFileError}
          handleSetFileStatusToUploadError={
            props.handleSetFileStatusToUploadError
          }
          handleSetFileStatusToStudioError={
            props.handleSetFileStatusToStudioError
          }
          updateIfStudioIsComplete={props.updateIfStudioIsComplete}
          handleSetFileStatusToLoading={props.handleSetFileStatusToLoading}
          fileIndex={props.fileIndex}
          handleSetFileStatusToDone={props.handleSetFileStatusToDone}
          handleUploadReport={props.handleUploadReport}
          handleRetryReport={props.handleRetryReport}
          handleDeleteReport={props.handleDeleteReport}
        />}
       
      </div>
    );
  } else if (props.status === "error") {
    return (
      <div
        className="file-upload-status file-upload-status--error"
        data-report={props.fileType}
        data-file-name={props.fileName}
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          width: "100%",
          paddingLeft: "1em",
          marginTop: "1em",
          fontSize: "1em",
        }}
      >
        <IconWrapper color={"#c3c3c3"} />
        <p
          style={{
            display: "flex",
            fontSize: ".75em",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            marginBottom: "0",
            flexDirection: "row",
            height: "2.2em",
          }}
        >
          {props.fileName}
        </p>

        {props.menuHidden ? null :
        <Menu
          deleteOnly={props.deleteOnly ? props.deleteOnly : false}
          reports={props.reports}
          fileType={props.fileType}
          fileIndex={props.fileIndex}
          studioIndex={props.studioIndex}
          handleSetFileStatusToLoading={props.handleSetFileStatusToLoading}
          handleSetFileStatusToDone={props.handleSetFileStatusToDone}
          handleUploadReport={props.handleUploadReport}
          handleRetryReport={props.handleRetryReport}
          handleDeleteReport={props.handleDeleteReport}
        /> }
      </div>
    );
  } else if (props.status === "fileError") {
    return (
      <Tooltip
        sx={{
          fontSize: "5rem",
        }}
        title="Invalid Report"
        placement="top"
        arrow
      >
        <div
          className="file-upload-status file-upload-status--file-error"
          data-report={props.fileType}
          data-file-name={props.fileName}
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            width: "100%",
            paddingLeft: "1em",
            marginTop: "1em",
            fontSize: "1em",
          }}
        >
          <IconWrapper color={"red"} />
          <p
            style={{
              display: "flex",
              fontSize: ".75em",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
              marginBottom: "0",
              flexDirection: "row",
              height: "2.2em",
            }}
          >
            {props.fileName}
            {/* <LinearWithValueLabel /> */}
            <ErrorOutlineIcon
              style={{
                marginLeft: "auto",
                color: "red",
              }}
            />
            {/* <LinearProgressBar /> */}
          </p>

          {props.menuHidden ? null :
          <Menu
            deleteOnly={props.deleteOnly ? props.deleteOnly : false}
            reports={props.reports}
            fileType={props.fileType}
            studioIndex={props.studioIndex}
            fileIndex={props.fileIndex}
            handleSetFileStatusToLoading={props.handleSetFileStatusToLoading}
            handleSetFileStatusToUploadError={
              props.handleSetFileStatusToUploadError
            }
            handleSetFileStatusToStudioError={
              props.handleSetFileStatusToStudioError
            }
            handleSetFileStatusToFileError={
              props.handleSetFileStatusToFileError
            }
            updateIfStudioIsComplete={props.updateIfStudioIsComplete}
            handleSetFileStatusToDone={props.handleSetFileStatusToDone}
            handleUploadReport={props.handleUploadReport}
            handleRetryReport={props.handleRetryReport}
            handleDeleteReport={props.handleDeleteReport}
          /> }
        </div>
      </Tooltip>
    );
  } else if (props.status === "uploadError") {
    return (
      <Tooltip
        sx={{
          fontSize: "5rem",
        }}
        title="Failed Uploading File"
        placement="top"
        arrow
      >
        <div
          className="file-upload-status file-upload-status--upload-error"
          data-report={props.fileType}
          data-file-name={props.fileName}
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            width: "100%",
            paddingLeft: "1em",
            marginTop: "1em",
            fontSize: "1em",
          }}
        >
          <IconWrapper color={"red"} />
          <p
            style={{
              display: "flex",
              fontSize: ".75em",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
              marginBottom: "0",
              flexDirection: "row",
              height: "2.2em",
            }}
          >
            {props.fileName}
            {/* <LinearWithValueLabel /> */}
            <ErrorOutlineIcon
              style={{
                marginLeft: "auto",
                color: "red",
              }}
            />
            {/* <LinearProgressBar /> */}
          </p>

          {props.menuHidden ? null :
          <Menu
            deleteOnly={props.deleteOnly ? props.deleteOnly : false}
            reports={props.reports}
            fileType={props.fileType}
            studioIndex={props.studioIndex}
            fileIndex={props.fileIndex}
            handleSetFileStatusToLoading={props.handleSetFileStatusToLoading}
            handleSetFileStatusToFileError={
              props.handleSetFileStatusToFileError
            }
            handleSetFileStatusToUploadError={
              props.handleSetFileStatusToUploadError
            }
            handleSetFileStatusToStudioError={
              props.handleSetFileStatusToStudioError
            }
            updateIfStudioIsComplete={props.updateIfStudioIsComplete}
            handleSetFileStatusToDone={props.handleSetFileStatusToDone}
            handleUploadReport={props.handleUploadReport}
            handleRetryReport={props.handleRetryReport}
            handleDeleteReport={props.handleDeleteReport}
          /> }
        </div>
      </Tooltip>
    );
  } else if (props.status === "studioError") {
    return (
      <Tooltip
        sx={{
          fontSize: "5rem",
        }}
        title="Wrong Studio"
        placement="top"
        arrow
      >
        <div
          className="file-upload-status file-upload-status--studio-error"
          data-report={props.fileType}
          data-file-name={props.fileName}
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            width: "100%",
            paddingLeft: "1em",
            marginTop: "1em",
            fontSize: "1em",
          }}
        >
          <IconWrapper color={"red"} />
          <p
            style={{
              display: "flex",
              fontSize: ".75em",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
              marginBottom: "0",
              flexDirection: "row",
              height: "2.2em",
            }}
          >
            {props.fileName}
            {/* <LinearWithValueLabel /> */}
            <ErrorOutlineIcon
              style={{
                marginLeft: "auto",
                color: "red",
              }}
            />
            {/* <LinearProgressBar /> */}
          </p>

          {props.menuHidden ? null :
          <Menu
            deleteOnly={props.deleteOnly ? props.deleteOnly : false}
            reports={props.reports}
            fileType={props.fileType}
            studioIndex={props.studioIndex}
            fileIndex={props.fileIndex}
            handleSetFileStatusToLoading={props.handleSetFileStatusToLoading}
            handleSetFileStatusToFileError={
              props.handleSetFileStatusToFileError
            }
            handleSetFileStatusToUploadError={
              props.handleSetFileStatusToUploadError
            }
            handleSetFileStatusToStudioError={
              props.handleSetFileStatusToStudioError
            }
            updateIfStudioIsComplete={props.updateIfStudioIsComplete}
            handleSetFileStatusToDone={props.handleSetFileStatusToDone}
            handleUploadReport={props.handleUploadReport}
            handleRetryReport={props.handleRetryReport}
            handleDeleteReport={props.handleDeleteReport}
          /> }
        </div>
      </Tooltip>
    );
  }
}
