// import XLSX from "xlsx";
import XLSX from "@sheet/core";

/**** CLASSES ****/
import {
  BookingEvent,
  ClassAttributes,
  EventAttributes,
} from "../../utility-functions/ReportCompiler/classes/BookingEvent.js";
import { TimeEvent } from "../../utility-functions/ReportCompiler/classes/TimeEvent.js";

/**** HELPER FUNCTIONS ****/
import { sortArrayLastName } from "../../utility-functions/GeneralFunctions/SortArrayByLastName.js";

import {
  getObjectLength,
  findStudioNumberFromInputId,
  getStaff,
  getLocations,
  checkDay,
  checkQualifications,
  addHolidayPay,
} from "../../utility-functions/utility-functions.js";
/**** XLSX CONVERTERS ****/
import * as BookingEventLogConverter from "../../utility-functions/XLSXConverters/BookingEventLogConverter.js";
import * as MemberLogConverter from "../../utility-functions/XLSXConverters/MemberLogConverter.js";
import * as WhenIWorkFileConverter from "../../utility-functions/XLSXConverters/WhenIWorkFileConverter.js";
import * as SlingFileConverter from "../../utility-functions/XLSXConverters/SlingFileConverter.js";
import * as ProsprFileConverter from "../../utility-functions/XLSXConverters/ProsprFileConverter.js";
import * as TimeFileConverter from "../../utility-functions/XLSXConverters/TimeFileConverter.js";
import * as SessionFileConverter from "../../utility-functions/XLSXConverters/SessionFileConverter.js";
import * as AgreementFileConverter from "../../utility-functions/XLSXConverters/AgreementFileConverter.js";
import * as GrossSalesFileConverter from "../../utility-functions/XLSXConverters/GrossSalesFileConverter.js";
import * as DeputyFileConverter from "../../utility-functions/XLSXConverters/DeputyFileConverter.js";
import * as GustoFileConverter from "../../utility-functions/XLSXConverters/GustoFileConverter.js";
import * as PaycomFileConverter from "../../utility-functions/XLSXConverters/PaycomFileConverter.js";
import { getUrlVariableValue } from "../../utility-functions/utility-functions.js";
import {
  buildSettings,
  buildStaff,
} from "../../utility-functions/FirebaseConverter/ProfileSettingsConverter.js";
import {
  findHourlyPayRate,
  findPayRates,
  addOvertime,
  addEarnings,
} from "../../utility-functions/utility-functions.js";
import { ClassCreation } from "../ClassCreation/utility-functions.js";
import { CommissionCreation } from "../CommissionCreation/utility-functions.js";
import { ExcelOutput } from "../ExcelOutput/utility-functions.js";

import { initializeSessionSnapshot } from "../../utility-functions/AWS/S3-Helper-Functions.js";

export const initialReportCompiler = {
  inputFilesArrays: {
    input1Pay: [],
    questions: {},
    BEL: [],
    MEM: [],
    TIME: [],
    PAY: [],
    AGREE: [],
    SALE: [],
  },
  studiosInformation: {
    instructorsArray: [],
    staffArray: [],
    classes: [],
    commissions: [],
  },
  payrollInformation: {
    numberOfStudios: 0,
    belPayPeriods: [new Date(0), new Date()],
    studiosInInput: [],
    studiosInOutput: [],
    classesInFiles: [],
    staffInFiles: [],
  },
  scrapeInfo: {
    reportsNeeded: [],
    fullNameReportsNeeded: [],
    isScrapeActive: false,
  },
  output: {
    loading: false,
    downloaded: false,
    name: "",
    workbook: false,
  },
};
export function sortStaffByLocationAndName(staffArray) {
  // Sort by location first
  staffArray.sort((a, b) => {
    if (a.location < b.location) return -1;
    if (a.location > b.location) return 1;
    // If locations are the same, sort by name
    if (a.staffName < b.staffName) return -1;
    if (a.staffName > b.staffName) return 1;
    return 0;
  });

  return staffArray;
}

export function deleteDuplicateTimeObjects(reportCompilerState) {
  const filteredTimeArray = reportCompilerState.inputFilesArrays["TIME"].filter(
    (value, index) => {
      const _value = JSON.stringify(value);
      return (
        index ===
        reportCompilerState.inputFilesArrays["TIME"].findIndex((obj) => {
          return JSON.stringify(obj) === _value;
        })
      );
    }
  );
  return filteredTimeArray;
}

export function pushToCollection(collection, ...values) {
  if (typeof collection === "object" && getObjectLength(collection) < 1) {
    return { ...values };
  } else if (Array.isArray(collection) && collection.length < 1) {
    return { ...values };
  } else {
    return {
      ...collection,
      ...values,
    };
  }
}

/**
 * Converts the given JSON data into a format that can be used in the report compiler.
 *
 * @param {string} fileId - The id of the input file.
 * @param {Object} tabOne - The JSON data to convert.
 * @param {Object} tabThree - The tabThree data to convert.
 * @param {Object} tabTwo - The tabTwo JSON data to convert.
 * @param {Object} user - The user who uploaded the file (optional).
 * @returns {Promise<void>} A Promise that resolves when the conversion is complete.
 */
export function convertExcelFile(
  fileId,
  tabOne,
  tabThree,
  tabTwo,
  studioName,
  reportCompilerState,
  selectedDates
) {
  const studioNumber = findStudioNumberFromInputId(fileId);
  let output = null;

  if (fileId.includes("bel")) {
    output = BookingEventLogConverter.readBookingEventLog(
      tabOne,
      selectedDates
    );
  } else if (fileId.includes("mem")) {
    output = MemberLogConverter.readActiveMemberLog(tabOne);
  } else if (fileId.includes("time")) {
    if (reportCompilerState.inputFilesArrays.questions.useWhenIWorkTime.value) {
      output = WhenIWorkFileConverter.readWhenIWorkFile(
        tabTwo,
        reportCompilerState.payrollInformation.studiosInInput,
        reportCompilerState.studiosInformation
      );
    } else if (reportCompilerState.inputFilesArrays.questions.useWhenIWorkTimeDetail.value) {
      output = WhenIWorkFileConverter.readWhenIWorkFileDetail(
        tabOne,
        reportCompilerState.payrollInformation.studiosInInput,
        reportCompilerState.studiosInformation
      );
    } else if (
      reportCompilerState.inputFilesArrays.questions.useSlingTime.value
    ) {
      output = SlingFileConverter.readSlingFile(
        tabOne,
        reportCompilerState.studiosInformation
      );
    } else if (
      reportCompilerState.inputFilesArrays.questions.useSlingTimeBasic.value
    ) {
      output = SlingFileConverter.slingBasicFileConverter(
        tabOne,
        reportCompilerState
      );
    } else if (
      reportCompilerState.inputFilesArrays.questions.useSlingTimeAdvanced.value
    ) {
      output = SlingFileConverter.slingAdvancedFileConverter(
        tabOne,
        reportCompilerState
      );
    } else if (
      reportCompilerState.inputFilesArrays.questions.useGustoTime.value
    ) {
      output = GustoFileConverter.gustoFileConverter(
        tabOne,
        reportCompilerState
      );
    } else if (
      reportCompilerState.inputFilesArrays.questions.usePaycomTime.value
    ) {
      output = PaycomFileConverter.paycomFileConverter(
        tabOne,
        reportCompilerState
      );
    } else if (
      reportCompilerState.inputFilesArrays.questions.useDeputyTime.value
    ) {
      output = DeputyFileConverter.deputyFileConverter(
        tabOne,
        studioName,
        reportCompilerState
      );
    } else if (
      reportCompilerState.inputFilesArrays.questions.useDeputyDetailTime.value
    ) {
      output = DeputyFileConverter.deputyDetailFileConverter(
        tabOne,
        reportCompilerState
      );
    } else if (
      reportCompilerState.inputFilesArrays.questions.useProsprTime.value
    ) {
      output = ProsprFileConverter.readProsprFile(
        tabOne,
        reportCompilerState.payrollInformation.studiosInInput,
        reportCompilerState.studiosInformation
      );
    }
    else if (
      reportCompilerState.inputFilesArrays.questions.useClubReadyTimePunchDetail
        .value
    ) {
      output = TimeFileConverter.readClubReadyTimeClockDetail(
        tabOne,
        reportCompilerState.studiosInformation,
        reportCompilerState.inputFilesArrays.questions.punchDetailInStaffTabs
          .value
      );
    }
    else {
      output = TimeFileConverter.readClubReadyTimeClockSummary(
        tabOne,
        reportCompilerState.studiosInformation
      );
    }
  } else if (fileId.includes("pay")) {
    output = SessionFileConverter.readSessionPayroll(tabOne, tabTwo);
  } else if (fileId.includes("agree")) {
    output = AgreementFileConverter.readAgreementsLogDetail(
      tabOne,
      reportCompilerState.inputFilesArrays.questions.diffCommissionPayCol,
      reportCompilerState.inputFilesArrays.questions.salesBuckets || null
    );
    let pastAgreements = [];
    //get all past agreements here
    let apiRequestExample = getAPIRequestJson(output.dataArray);
    output = AgreementFileConverter.addPastAgreements(output, pastAgreements);
  } else if (fileId.includes("sale") || fileId.includes("gross")) {
    output = GrossSalesFileConverter.readGrossSalesDetail(
      tabOne,

      reportCompilerState.inputFilesArrays.questions
        .confirmCommissionFromGrossSales.value,
      reportCompilerState.inputFilesArrays.questions.retailColumn.value
    );
  }

  return output;
}

function getAPIRequestJson(output) {
  let apiRequestArray = [];
  for (let i = 0; i < output.length; i++) {
    if (output[i].previousAgreementNumber > 0) {
      apiRequestArray.push({
        location: output[i].location,
        type: "agree",
        "First Name": output[i].clientFirstName,
        "Last Name": output[i].clientLastName,
      })
    }
  }

  return apiRequestArray;
}
export function readExcelFile(workbook, reportCompilerState) {
  if (workbook?.workbook) {
    workbook = workbook.workbook;
  }
  let tabOne = [];
  let tabTwo = [];
  let tabThree = [];

  var first_sheet_name = workbook.SheetNames[0];
  var worksheetOne = workbook.Sheets[first_sheet_name];
  tabOne = XLSX.utils.sheet_to_json(worksheetOne, {
    header: 1,
    raw: false,
    defval: "",
  });

  if (reportCompilerState.inputFilesArrays.questions.useWhenIWorkTime.value) {
    let workSheetTwo = workbook.Sheets["Summary By User"];
    tabTwo = XLSX.utils.sheet_to_json(workSheetTwo, {
      header: 1,
      raw: false,
      defval: "",
    });
  }

  var third_sheet_name = workbook.SheetNames[2];
  var worksheetThree = workbook.Sheets[third_sheet_name];
  tabThree = XLSX.utils.sheet_to_json(worksheetThree, {
    header: 1,
    raw: false,
    defval: "",
  });

  var second_sheet_name = workbook.SheetNames[1];
  let attendeeInfo = workbook.Sheets[second_sheet_name];
  tabTwo = XLSX.utils.sheet_to_json(attendeeInfo, {
    header: 1,
    raw: false,
    defval: "",
  });
  return { tabOne, tabTwo, tabThree };
}
export function addTimeEvent(timeException, reportCompilerState) {
  let nonZeroTimeEvent =
    parseFloat(timeException.hours) > 0 || parseFloat(timeException.total) > 0;
  let locationsFound = getPulledLocations(reportCompilerState);
  let staff = getStaff(timeException.staff, reportCompilerState);
  let locations = getLocations(timeException.location, reportCompilerState);

  let addedStaff = [];
  if (
    !nonZeroTimeEvent &&
    !timeException.location.includes("*Studios Worked At*")
  ) {
    locations = locations.filter((value) => locationsFound.includes(value));
  }
  let dayMatch = checkDay(timeException);
  let timeEvents = [];
  if (!dayMatch) {
    return [timeEvents, addedStaff];
  }
  for (let i = 0; i < staff.length; i++) {
    for (let x = 0; x < locations.length; x++) {
      let rate = 0;
      if (isNaN(parseFloat(timeException.rate))) {
        try {
          if (timeException.rate === "REG") {
            rate = staff[i].getHourlyRate(locations[x]);
          } else if (timeException.rate === "OT") {
            rate = staff[i].getHourlyRate(locations[x]) * 1.5;
          } else if (timeException.rate === "DOT") {
            rate = staff[i].getHourlyRate(locations[x]) * 2;
          }
        } catch (e) {
          console.log(e);
          rate = 0;
        }
      } else {
        // if(parseFloat(timeException.rate) === -100){
        //   rate = staff[i].getHourlyRate(locations[x]);
        // } else if(parseFloat(timeException.rate) === -200){
        //   rate = staff[i].getHourlyRate(locations[x]) * 1.5;
        // } else if(parseFloat(timeException.rate) === -300){
        //   rate = staff[i].getHourlyRate(locations[x]) * 2;
        // }else{
        rate = parseFloat(timeException.rate);
        // if(parseFloat(timeException.hours) === 1 && parseFloat(timeException.total) > 0){
        //   rate = parseFloat(timeException.total);
        // }
        //}
      }
      const catchAllEvent = new TimeEvent(
        staff[i],
        locations[x],
        timeException.hours,
        parseFloat(rate),
        timeException.type,
        parseFloat(timeException.total)
      );
      catchAllEvent.exception = true;
      catchAllEvent.comment = timeException.comment;

      // inputFilesArrays[timeLocation].push(catchAllEvent);
      if (catchAllEvent) {
        timeEvents.push(catchAllEvent);
        addedStaff.push({
          name: staff[i],
          type: "time",
        });
      } else {
        console.error("catchAllEvent is undefined in addTimeEvent()");
      }
    }
  }
  return [timeEvents, addedStaff];
}
export function removeTimeEvent(timeException, reportCompilerState) {
  // **** scott - this is removing all time punch detail events
  let timeEvents = reportCompilerState.inputFilesArrays.TIME;
  let newTimeEvents = [];
  let deletedTimeEvents = [];
  for (let i = 0; i < timeEvents.length; i++) {
    let deleteTimeEvent = checkQualifications(
      timeEvents[i],
      timeException,
      reportCompilerState
    );
    if (timeEvents[i]) {
      if (!deleteTimeEvent) {
        newTimeEvents.push(timeEvents[i]);
      } else {
        deletedTimeEvents.push(timeEvents[i]);
      }
    } else {
      console.error("timeEvents[i] is undefinend in removeTimeEvent()");
    }
  }

  return newTimeEvents;
}

export function replaceTimeEvent(timeException, reportCompilerState) {
  // **** scott - this is removing all time punch detail events
  let timeEvents = reportCompilerState.inputFilesArrays.TIME;
  let updatedTimeEvents = [];
  for (let i = 0; i < timeEvents.length; i++) {
    if (timeEvents[i]) {
      let replaceTimeEvent = checkReplaceQualifications(
        timeEvents[i],
        timeException,
        reportCompilerState
      );
      updatedTimeEvents.push(replaceTimeEvent);
    }
  }

  return updatedTimeEvents;
}

function checkReplaceQualifications(
  timeEvent,
  timeException,
  reportCompilerState
) {
  let locations = getLocations(timeException.location, reportCompilerState);
  if (timeEvent?.staffName) {
    let staffNameMatch = false;
    for (let i = 0; i < timeException.staff.length; i++) {
      if (timeEvent.staffName.includes(timeException.staff[i])) {
        staffNameMatch = true;
      }
    }

    if (!staffNameMatch) {
      return timeEvent;
    }

    if (!locations.includes(timeEvent.location)) {
      return timeEvent;
    }
    if (
      timeEvent.description !== timeException.type &&
      timeException.type !== "" &&
      timeException.type !== "Default"
    ) {
      return timeEvent;
    }
    timeEvent.overrideRate = timeException.rate;
    return timeEvent;
  } else {
    console.error("timeEvent is undefined");
  }

  return timeEvent;
}

export function getPulledLocations(reportCompilerState) {
  let agree = getUniqueLocations(reportCompilerState.inputFilesArrays.AGREE);
  let bel = getUniqueLocations(reportCompilerState.inputFilesArrays.BEL);
  let pay = getUniqueLocations(reportCompilerState.inputFilesArrays.PAY);
  let time = getUniqueLocations(reportCompilerState.inputFilesArrays.TIME);
  let sale = getUniqueLocations(reportCompilerState.inputFilesArrays.SALE);

  let combinedArray = [...agree, ...bel, ...pay, ...time, ...sale];

  return [...new Set(combinedArray)];
}

function getUniqueLocations(items) {
  const locations = new Set(items.map((item) => item.location));
  return Array.from(locations);
}

export function getUnattendedBookingEvents(reportCompilerState) {
  const bookingEvents = reportCompilerState.inputFilesArrays.BEL;
  const payTable = reportCompilerState.inputFilesArrays.PAY;
  let session_name =
    reportCompilerState.inputFilesArrays.questions.namingConventions
      .sessionName;
  let clients_name =
    reportCompilerState.inputFilesArrays.questions.namingConventions
      .clientsName;
  let unattendedBookingEvents = [];
  for (let i = 0; i < payTable.length; i++) {
    if (payTable[i] !== null) {
      let classAccountedFor = true;
      if (
        payTable[i].customer.includes(
          "0 " + session_name + " " + clients_name
        ) &&
        !payTable[i].customer.includes("10")
      ) {
        classAccountedFor = false;
        for (let z = 0; z < bookingEvents.length; z++) {
          if (
            payTable[i].date.getTime() ===
            bookingEvents[z].classDate.getTime() &&
            payTable[i].instructor === bookingEvents[z].classInstructor
          ) {
            classAccountedFor = true;
          }
        }
      }

      if (!classAccountedFor) {
        const classAttributes = new ClassAttributes(
          payTable[i].instructor,
          payTable[i].className,
          payTable[i].location,
          payTable[i].date,
          0
        );
        const eventAttributes = new EventAttributes(
          "No Bookings",
          "None",
          "N/A",
          "N/A",
          "N/A",
          new Date()
        );

        const bookingEventUnattended = new BookingEvent(
          classAttributes,
          eventAttributes,
          false
        );

        // inputFilesArrays.BEL.push(bookingEventUnattended);
        unattendedBookingEvents.push(bookingEventUnattended);
      }
      classAccountedFor = false;
    }
  }
  if (unattendedBookingEvents.length > 0) {
    return unattendedBookingEvents;
    // reportCompilerDispatch({
    //   type: "UPDATE_REPORT_COMPILER_PROPERTY",
    //   path: `inputFilesArrays.BEL`,
    //   value: bookingEventUnattended,
    //   replace: false,
    // });
  } else {
    return false;
  }
}
// ~~~~ This is not altering the state/output, ask scott what this does and what "unadded" array should be added to.
export function adjustAgreements(reportCompilerState) {
  let unadded = [];
  if (
    reportCompilerState.inputFilesArrays.questions
      .confirmCommissionFromGrossSales
  ) {
    for (let i = 0; i < reportCompilerState.inputFilesArrays.SALE.length; i++) {
      let salesItem = reportCompilerState.inputFilesArrays.SALE[i];
      if (salesItem.id === "") {
        continue;
      }
      let found = false;
      for (
        let j = 0;
        j < reportCompilerState.inputFilesArrays.AGREE.length;
        j++
      ) {
        let agreeItem = reportCompilerState.inputFilesArrays.AGREE[j];
        if (
          salesItem.id === agreeItem.id &&
          salesItem.description === agreeItem.description
        ) {
          found = true;
          if (
            //salesItem.salesPerson !== agreeItem.salespeople.PrimarySalesperson
            salesItem.salespeople.PrimarySalesperson !==
            agreeItem.salespeople.PrimarySalesperson
          ) {
            // agreeItem.salespeople.PrimarySalesperson = salesItem.salesPerson;
            agreeItem.salespeople.PrimarySalesperson =
              salesItem.salespeople.PrimarySalesperson;
          }
        }
      }
      if (!found) {
        unadded.push(salesItem);
      }
    }
    return unadded;
  }
  return false;
}

export function calculateTimeDifference(inTime, outTime) {
  if (!inTime || !outTime) {
    return 0;
  }
  if (inTime === "???" || outTime === "???") {
    return 0;
  }
  if (!inTime.includes(":") || !outTime.includes(":")) {
    return 0;
  }

  const inTimeArray = inTime.split(":");
  const outTimeArray = outTime.split(":");

  let inTimeHours = parseInt(inTimeArray[0]);
  let inTimeMinutes = parseInt(inTimeArray[1].substr(0, 2));
  let outTimeHours = parseInt(outTimeArray[0]);
  let outTimeMinutes = parseInt(outTimeArray[1].substr(0, 2));

  if (inTime.indexOf("PM") !== -1 && inTimeHours !== 12) {
    inTimeHours += 12;
  }

  if (inTime.indexOf("AM") !== -1 && inTimeHours === 12) {
    inTimeHours = 0;
  }

  if (outTime.indexOf("PM") !== -1 && outTimeHours !== 12) {
    outTimeHours += 12;
  }

  if (outTime.indexOf("AM") !== -1 && outTimeHours === 12) {
    outTimeHours = 0;
  }

  const inTimeInMinutes = inTimeHours * 60 + inTimeMinutes;
  const outTimeInMinutes = outTimeHours * 60 + outTimeMinutes;
  const timeDifferenceInMinutes = outTimeInMinutes - inTimeInMinutes;
  const timeDifferenceInHours = timeDifferenceInMinutes / 60;

  return parseFloat(timeDifferenceInHours.toFixed(2));
}

/*** HANDLE DOWNLOAD FUNCTIONS */
export function mergeAllStudioReportData(newState) {
  newState = checkBelIssues(newState);
  let reportsNeeded = newState.scrapeInfo.reportsNeeded;
  for (let i = 0; i < reportsNeeded.length; i++) {
    for (let reportArray in newState.inputFilesArrays) {
      if (
        reportArray.includes(reportsNeeded[i]) &&
        !reportArray.includes("BACKUP") &&
        !reportArray.includes("Uploaded")
      ) {
        if (reportsNeeded[i].toUpperCase() === "GROSS") {
          newState.inputFilesArrays["SALE"] = newState.inputFilesArrays[
            "SALE"
          ].concat(newState.inputFilesArrays[reportArray]);
        } else {
          newState.inputFilesArrays[reportsNeeded[i].toUpperCase()] =
            newState.inputFilesArrays[reportsNeeded[i].toUpperCase()].concat(
              newState.inputFilesArrays[reportArray]
            );
        }
      } else if (
        reportArray.includes("time") &&
        reportsNeeded[i] == "time" &&
        !reportArray.includes("Uploaded")
      ) {
        newState.inputFilesArrays["TIME"] = newState.inputFilesArrays[
          "TIME"
        ].concat(newState.inputFilesArrays[reportArray]);
      }
    }

    if (reportsNeeded[i] === "time") {
      newState.inputFilesArrays.TIME = deleteDuplicateTimeObjects(newState);

      newState.inputFilesArrays.TIME = sortStaffByLocationAndName(
        newState.inputFilesArrays.TIME
      );
    }
  }

  return newState;
}

function checkBelIssues(newState) {
  for (let reportArray in newState.inputFilesArrays) {
    if (reportArray.includes("BACKUPpay")) {
      let number = reportArray.replace("BACKUPpay", "");
      if (newState.inputFilesArrays["bel" + number] === undefined) {
        newState.inputFilesArrays["bel" + number] =
          newState.inputFilesArrays[reportArray];
      }
    }
  }
  return newState;
}

export function grabRelevantStaff(newState) {
  let fullStaffList = newState.inputFilesArrays.input1Pay;
  let staffInInput = newState.payrollInformation.staffInFiles;
  const staffNames = [...new Set(staffInInput.map((item) => item.name))];

  let locationsRunning = newState.payrollInformation.studiosInInput;

  let staff = [];
  let instructors = [];

  let staffTypes = newState.inputFilesArrays.questions.staffTypes;
  let specialistTypes = [];
  for (let k = 0; k < fullStaffList.length; k++) {
    for (let j = 0; j < staffNames.length; j++) {
      for (let l = 0; l < locationsRunning.length; l++) {
        if (
          fullStaffList[k].isNamed(staffNames[j]) &&
          fullStaffList[k].getLocations().includes(locationsRunning[l])
        ) {
          staff.push(fullStaffList[k]);
          j = staffNames.length;
          break;
        }
      }
    }
  }

  for (let i = 0; i < staffTypes.length; i++) {
    if (staffTypes[i].sessionPay) {
      specialistTypes.push(staffTypes[i].name);
    }
  }

  for (let m = 0; m < staff.length; m++) {
    if (specialistTypes.includes(staff[m].getProperty("Any", "type"))) {
      instructors.push(staff[m]);
    }
  }
  // let builtInstructorArray = builtStaff.filter(staffMember => {

  //   if (staffMember.infoObject) {
  //       for (let key in staffMember.infoObject) {
  //           if (specialistTypes.includes(staffMember.infoObject[key].type)) {
  //               return true;
  //           }
  //       }
  //   }

  //   return false;
  // });

  // for (let i = 0; i < fullStaffList.length; i++) {
  //   for (let j = 0; j < locations.length; j++) {
  //     if (fullStaffList[i].getLocations().includes(locations[j])) {
  //       staff.push(fullStaffList[i]);
  //       if (
  //         specialistTypes.includes(fullStaffList[i].getProperty(locations[j], "type"))
  //       ) {
  //         instructors.push(fullStaffList[i]);
  //       }
  //       break;
  //     }
  //   }
  // }

  if (newState.inputFilesArrays.questions.organizeStaffLastName.value) {
    staff.sort(sortArrayLastName);
    instructors.sort(sortArrayLastName);
  }

  return [staff, instructors];
}

export function getUniqueLocationsFromUpperCaseKeys(obj) {
  let uniqueLocations = [];

  // Get keys
  let keys = Object.keys(obj);

  // Filter keys that are all uppercase
  let upperCaseKeys = keys.filter((key) => key === key.toUpperCase());

  // Iterate over each uppercase key
  upperCaseKeys.forEach((key) => {
    // Iterate over each object in the array
    obj[key].forEach((item) => {
      // Check if the location is already in the uniqueLocations array
      if (!uniqueLocations.includes(item.location)) {
        // If not, add it
        uniqueLocations.push(item.location);
      }
    });
  });

  return uniqueLocations;
}

export function mergeStudioCustomerData(newState) {
  var allStudioCustomerData = [];
  if (newState.payrollInformation.studiosInInput.length > 0) {
    newState.payrollInformation.studiosInInput.forEach((studio, i) => {
      if (newState.inputFilesArrays.hasOwnProperty(`customer${i + 1}`)) {
        allStudioCustomerData.concat(newState.inputFilesArrays[`customer${i}`]);
      }
    });
  }

  return allStudioCustomerData;
}

export function addTimeClockCatchAll(newState) {
  let timeLogic = newState.inputFilesArrays.questions.timeCatchAllObject;
  for (let n = 0; n < timeLogic.length; n++) {
    if (timeLogic[n].utility === "Add") {
      let [addedTimeEvents, staffToAdd] = addTimeEvent(timeLogic[n], newState);
      if (addedTimeEvents || staffToAdd.length > 0) {
        newState.inputFilesArrays.TIME =
          newState.inputFilesArrays.TIME.concat(addedTimeEvents);
        newState.payrollInformation.staffInFiles =
          newState.payrollInformation.staffInFiles.concat(staffToAdd);
      }
    } else if (timeLogic[n].utility === "Remove") {
      newState.inputFilesArrays.TIME = removeTimeEvent(timeLogic[n], newState);
    } else if (timeLogic[n].utility === "Replace Rate") {
      newState.inputFilesArrays.TIME = replaceTimeEvent(timeLogic[n], newState);
    }
  }
  return newState;
}

export function addUnattendedBookingEvents(newState) {
  let unattendedBookingEvents = getUnattendedBookingEvents(newState);
  if (unattendedBookingEvents) {
    newState.inputFilesArrays.BEL = newState.inputFilesArrays.BEL.concat(
      unattendedBookingEvents
    );
    //newState.inputFilesArrays.BEL.push(unattendedBookingEvents);
  }
  return newState;
}

export function resetGlobalVariables() {
  return initialReportCompiler;
}

export function resetReportData(newState) {
  let fileTypes = ["bel", "mem", "time", "pay", "agree", "sale"];
  for (let i = 0; i < fileTypes.length; i++) {
    newState.inputFilesArrays[fileTypes[i].toUpperCase()] = [];
  }
  newState.studiosInformation.classes = [];
  return newState;
}

export function getStudioAndReportTypeForWorkbook(
  workbook,
  reportCompilerState
) {
  const firstSheetName = workbook.SheetNames[0];
  const worksheet = workbook.Sheets[firstSheetName];
  let json = XLSX.utils.sheet_to_json(worksheet, {
    header: 1,
    raw: false,
    defval: "",
  });
  let fileType = findFileTypeFromJson(json[0], reportCompilerState);
  let studio = findStudioFromJson(json[0], reportCompilerState);
  return { fileType: fileType, studio: studio };
}

export async function getStudioAndReportTypeForFile(file, reportCompilerState) {
  const data = await file.arrayBuffer();
  const workbook = XLSX.read(data, { type: "array" });
  const firstSheetName = workbook.SheetNames[0];
  const worksheet = workbook.Sheets[firstSheetName];
  let json = XLSX.utils.sheet_to_json(worksheet, {
    header: 1,
    raw: false,
    defval: "",
  });
  let fileType = findFileTypeFromJson(json[0], reportCompilerState);
  let studio = findStudioFromJson(json[0], reportCompilerState);
  return { fileType: fileType, studio: studio };
}

// ~~~~ scott smith we might have to change logic to account for new file types and location, this isnt battle tested
export function getStudioAndReportTypeFromJSON(jsonData) {
  // List of keys to search for
  const keysToFind = [
    "EmployeeName",
    "Type",
    "Location",
    "Date",
    "Time",
    "TotalTime",
    "timeClockURL",
  ];

  // Recursive function to search through the objects
  const searchObject = (obj) => {
    if (typeof obj !== "object" || obj === null) return null;

    // Check if the object contains all the required keys
    if (keysToFind.every((key) => key in obj)) {
      return { fileType: "time", studio: obj["Location"] };
    }

    // Otherwise, continue searching in the nested objects
    for (const key in obj) {
      const result = searchObject(obj[key]);
      if (result) return result;
    }

    return null;
  };

  return searchObject(jsonData);
}

// ~~~~ TEST TO MAKE SURE NEW VERSION WORKS(OLD VERSION WAS ITHIN REPORT CONTEXT AND DID NOT PASS IN STATE)
export function findStudioFromJson(firstRow, reportCompilerState) {
  //let studios = findAllStudios();
  // if (firstRow.includes("Location")) {
  //   return null;
  // }
  if (firstRow[0].includes("No booking events during this time period")) {
    return "All";
  }

  if (
    reportCompilerState.inputFilesArrays.questions.useProsprTime.value &&
    firstRow.includes("Location")
  ) {
    return "All";
    //return closestMatch()
  }
  if (
    reportCompilerState.inputFilesArrays.questions.useWhenIWorkTime.value &&
    firstRow.includes("Double OT")
  ) {
    return "All";
  }
  if (
    firstRow.includes("Employee ID") &&
    reportCompilerState.inputFilesArrays.questions.useWhenIWorkTimeDetail.value
  ) {
    return "All";
  }

  if (
    reportCompilerState.inputFilesArrays.questions.useSlingTime.value &&
    firstRow.includes("EMPL. ID")
  ) {
    return "All";
  }
  if (
    reportCompilerState.inputFilesArrays.questions.useSlingTimeBasic.value &&
    (firstRow.includes("LEGAL NAME") || firstRow.includes("SHIFT DURATION") || firstRow.includes("NOTES"))
  ) {
    return "All";
  }
  if(
    reportCompilerState.inputFilesArrays.questions.useSlingTimeAdvanced.value &&
    firstRow.includes("LEGAL NAME")
  ){
    return "All";
  }
  if (
    reportCompilerState.inputFilesArrays.questions.useGustoTime.value &&
    firstRow[0].includes("LLC")
  ) {
    return "All";
  }
  if (
    reportCompilerState.inputFilesArrays.questions.usePaycomTime.value &&
    firstRow[0].includes("Allocation Department Code")
  ) {
    return "All";
  }
  if (
    reportCompilerState.inputFilesArrays.questions.useDeputyTime.value &&
    firstRow[0].includes("Employee First Name")
  ) {
    return "All";
  }
  if (
    reportCompilerState.inputFilesArrays.questions.useDeputyDetailTime.value &&
    firstRow[0].includes("Employee Export Code")
  ) {
    return "All";
  }
  return firstRow[0].substring(
    firstRow[0].indexOf("-") + 2,
    firstRow[0].lastIndexOf("(") - 1
  );
}

export function findFileTypeFromJson(firstRow, reportCompilerState) {
  if (firstRow[0].includes("No booking events during this time period")) {
    return "bel";
  }
  if (firstRow[0].includes("Booking Events")) {
    return "bel";
  } else if (firstRow[0].includes("Member")) {
    return "mem";
  } else if (
    firstRow[0].includes("Time Clock") &&
    reportCompilerState.inputFilesArrays.questions.includeTimeClock.value
  ) {
    return "time";
  } else if (
    firstRow.includes("Local Time") &&
    firstRow[0].includes("Employee Name") &&
    reportCompilerState.inputFilesArrays.questions.useClubReadyTimePunchDetail
      .value
  ) {
    return "time";
  } else if (
    firstRow.includes("Double OT") &&
    firstRow[0].includes("First Name") &&
    reportCompilerState.inputFilesArrays.questions.useWhenIWorkTime.value
  ) {
    return "time";
  } else if (
    firstRow.includes("Employee ID") &&
    firstRow[0].includes("First Name") &&
    reportCompilerState.inputFilesArrays.questions.useWhenIWorkTimeDetail.value
  ) {
    return "time";
  } else if (
    (firstRow.includes("LEGAL NAME") || firstRow.includes("SHIFT DURATION") || firstRow.includes("NOTES")) &&
    firstRow[0].includes("EMPLOYEE") &&
    reportCompilerState.inputFilesArrays.questions.useSlingTimeBasic.value
  ) {
    return "time";
  } else if (
    (firstRow.includes("LEGAL NAME") || firstRow.includes("LOCATION") || firstRow.includes("REG. HOURS")) &&
    firstRow[0].includes("LEGAL NAME") &&
    reportCompilerState.inputFilesArrays.questions.useSlingTimeAdvanced.value
  ){
    return "time";
  } else if (
    firstRow.includes("EMPL. ID") &&
    firstRow[0].includes("EMPLOYEE") &&
    reportCompilerState.inputFilesArrays.questions.useSlingTime.value
  ) {
    return "time";
  } else if (
    firstRow.includes("Scheduled Start") &&
    firstRow[0].includes("First Name") &&
    reportCompilerState.inputFilesArrays.questions.useProsprTime.value
  ) {
    return "time";
  } else if (
    firstRow[0].includes("LLC") &&
    reportCompilerState.inputFilesArrays.questions.useGustoTime.value
  ) {
    return "time";
  } else if (
    firstRow[0].includes("Allocation Department Code") &&
    reportCompilerState.inputFilesArrays.questions.usePaycomTime.value
  ) {
    return "time";
  } else if (
    firstRow[0].includes("Employee First Name") &&
    reportCompilerState.inputFilesArrays.questions.useDeputyTime.value
  ) {
    return "time";
  } else if (
    firstRow[0].includes("Employee Export Code") &&
    reportCompilerState.inputFilesArrays.questions.useDeputyDetailTime.value
  ) {
    return "time";
  } else if (
    firstRow[0].includes("Agreements") &&
    reportCompilerState.inputFilesArrays.questions.includeAgreements.value
  ) {
    return "agree";
  } else if (firstRow[0].includes("Bookings")) {
    return "pay";
  } else if (
    firstRow[0].includes("Products") &&
    !reportCompilerState.inputFilesArrays.questions.useGrossSalesDetail.value
  ) {
    return "sale";
  } else if (
    firstRow[0].includes("Gross") &&
    reportCompilerState.inputFilesArrays.questions.useGrossSalesDetail.value
  ) {
    return "gross";
  } else if (firstRow.includes("Location")) {
    return "input";
  }
  return null;
}

export function checkDuplicateFileNames(checkName) {
  for (let i = 0; i < excelFileNames.length; i++) {
    if (excelFileNames[i][0] === checkName) {
      alert(
        "File '" +
        checkName +
        "' already added\n (Ignore if this was on purpose)"
      );
    }
  }
}

export function levenshteinDistance(s, t) {
  if (!s.length) return t.length;
  if (!t.length) return s.length;
  const arr = [];
  for (let i = 0; i <= t.length; i++) {
    arr[i] = [i];
    for (let j = 1; j <= s.length; j++) {
      arr[i][j] =
        i === 0
          ? j
          : Math.min(
            arr[i - 1][j] + 1,
            arr[i][j - 1] + 1,
            arr[i - 1][j - 1] + (s[j - 1] === t[i - 1] ? 0 : 1)
          );
    }
  }
  return arr[t.length][s.length];
}

export function addCloseCounterpart(names, reportCompilerState) {
  let multiName = {};
  for (let v = 0; v < names.length; v++) {
    multiName[names[v]] = {
      name: null,
      distance: names[v].length - names[v].length / 2,
    };
    const staffArray = reportCompilerState.studiosInformation.staffArray;
    for (let i = 0; i < staffArray.length; i++) {
      let staffNames = staffArray[i].getNames();
      for (let n = 0; n < staffNames.length; n++) {
        let distance = levenshteinDistance(names[v], staffNames[n]);
        if (distance < multiName[names[v]].distance) {
          multiName[names[v]] = {
            name: staffNames[n],
            distance: distance,
          };
        }
      }
    }
  }
  return multiName;
}

export function isNotStaff(name, reportCompilerState) {
  const staffArray = reportCompilerState.studiosInformation.staffArray;
  for (let i = 0; i < staffArray.length; i++) {
    if (staffArray[i].isNamed(name)) {
      return false;
    }
  }
  return true;
}

/**** ~~~~ HUGE FUNCTION THAT WE NEED TO MAKE SURE THAT THE SESSION SNAPSHOT DATA PROPERLY COMPILES INTO THIS FUNCTION *****/
export function buildReportCompilerState(settings) {

  //lets do some paywell pay period prediction shit here
  let payPeriods = findPayPeriods(settings[getUrlVariableValue("settingsId")], new Date());
  // ~~~~ RESET SCRAPE UI

  let newState = JSON.parse(JSON.stringify(initialReportCompiler));

  let selectedSettingsId = getUrlVariableValue("settingsId");
  let selectedSettingsPreset = settings[selectedSettingsId];

  /**** SET PAYWELL QUESTIONS */

  let questions = buildSettings(
    settings[getUrlVariableValue("settingsId")],
    settings.staff
  );
  // newState = buildReportCompilerStateQuestions(selectedSettingsPreset, settings.staff);

  newState.inputFilesArrays.questions = questions;


  /**** SET PAYWELL STAFF */
  // newState = buildReportCompilerStateStaff(settings, selectedSettingsPreset, newState);

  let builtStaff = buildStaff(settings.staff, selectedSettingsPreset);
  if (questions.organizeStaffLastName.value) {
    newState.studiosInformation.staffArray = builtStaff.sort(sortArrayLastName);
  } else {
    newState.studiosInformation.staffArray = builtStaff;
  }

  newState.inputFilesArrays.input1Pay = builtStaff;

  /**** SET PAYWELL INSTRUCTORS */
  let staffTypes =
    settings[getUrlVariableValue("settingsId")].generalSettings.staffTypes;
  let specialistTypes = [];
  for (let i = 0; i < staffTypes.length; i++) {
    if (staffTypes[i].sessionPay) {
      specialistTypes.push(staffTypes[i].name);
    }
  }
  // let builtInstructorArray = builtStaff.filter(
  //   (s) => s.type === "Instructor"
  // );
  // let builtInstructorArray = builtStaff.filter(
  //   (s) => specialistTypes.includes(s.type)
  // );
  let builtInstructorArray = builtStaff.filter((staffMember) => {
    if (staffMember.infoObject) {
      for (let key in staffMember.infoObject) {
        if (specialistTypes.includes(staffMember.infoObject[key].type)) {
          return true;
        }
      }
    }

    return false;
  });

  if (questions.organizeStaffLastName.value) {
    builtInstructorArray.sort(sortArrayLastName);
  }

  newState.studiosInformation.instructorsArray = builtInstructorArray;

  /**** SET PAYWELL STUDIO INFORMATION */
  const studiosArray = selectedSettingsPreset.generalSettings.studios;

  newState.payrollInformation.numberOfStudios = studiosArray.length;
  newState.payrollInformation.payPeriods = payPeriods;

  const sortedStudios = studiosArray.sort();

  newState.payrollInformation.studiosInInput = sortedStudios;

  /**** SET SCRAPE INFO  ****/

  let reportsNeeded = ["bel", "mem"];
  let fullNameReportsNeeded = ["Booking Events Log", "Active Members Log"];

  if (questions.useClubReadyTimePunchDetail?.value) {
    reportsNeeded.push("time");
    fullNameReportsNeeded.push("ClubReady Time Punch Detail");
  } else {
    if (questions.includeTimeClock?.value) {
      reportsNeeded.push("time");
      fullNameReportsNeeded.push("Time Clock Payroll");
    }
  }
  reportsNeeded.push("pay");
  fullNameReportsNeeded.push("Session Payroll Detail");

  if (questions.includeAgreements?.value) {
    reportsNeeded.push("agree");
    fullNameReportsNeeded.push("Agreements Log");
  }
  if (questions.includeSalesFile?.value) {
    if (questions.useGrossSalesDetail?.value) {
      reportsNeeded.push("gross");
      fullNameReportsNeeded.push("Gross Sales Detail");
    } else {
      reportsNeeded.push("sale");
      fullNameReportsNeeded.push("Product Sales Log");
    }
  }


  // TESTING
  // reportsNeeded = ["timeDetailData"];
  // fullNameReportsNeeded = ["ClubReady Time Punch Detail"];
  newState.scrapeInfo = {
    reportsNeeded,
    fullNameReportsNeeded,
    isScrapeActive: false,
  };

  return newState;
}

export function findPayPeriods(settings, day, previous = true) {

  try{
    let paymentFrequency = settings.generalSettings.payFrequency;
    let processor = settings.generalSettings.processor;
    if(!paymentFrequency) {
      return null;
    }

    let dayDate = new Date(day);

    let output = null;
    if(paymentFrequency === "Weekly") {
      output = findWeeklyPayPeriods(settings.generalSettings, dayDate);
    } else if(paymentFrequency === "Bi-Weekly") {
      output = findBiWeeklyPayPeriods(settings.generalSettings, dayDate);
    } else if(paymentFrequency === "Monthly") {
      output = findMonthlyPayPeriods(settings.generalSettings, dayDate);
    } else if(paymentFrequency === "Semi-Monthly" && processor.includes("Gusto")) {
      output = findEvenSemiMonthlyPayPeriods(settings.generalSettings, dayDate);
    } else if(paymentFrequency === "Semi-Monthly") {
      output = findSemiMonthlyPayPeriods(settings.generalSettings, dayDate);
    }

    let currentPayPeriod = -1;
    let now = new Date();
    now = new Date(day);

    // Strip time from the current date (set hours, minutes, seconds, milliseconds to 0)
    now.setHours(0, 0, 0, 0);

    for (let i = 0; i < output.length; i++) {
      let start = new Date(output[i][0]);
      let end = new Date(output[i][1]);
      let checkDate = new Date(output[i][2]);

      // Strip time from start and end dates
      start.setHours(0, 0, 0, 0);
      end.setHours(0, 0, 0, 0);
      
      // Add one day to the end date (to include the full end day)
      let copyEnd = new Date(end);
      //copyEnd.setDate(copyEnd.getDate() + 1);

      // Check if now is between start and end (inclusive) or equal to the end date
      if ((now >= start && now < copyEnd)) {
        if(previous){
          currentPayPeriod = i-1;
        } else {
          currentPayPeriod = i;
        }
        // You can add any further actions here
        break; // Stop the loop since we found the current pay period
      }
      if(now.getTime() === end.getTime()){
        if(previous){
          currentPayPeriod = i;
        } else {
          currentPayPeriod = i+1;
        }
        break;
      }
    }


    if(settings.generalSettings.payPeriodExamples.length > 0){
      return [output[currentPayPeriod][0], output[currentPayPeriod][1]];
      //return output;
    }

    return null;
  } catch(e) {
    console.log(e);
    return null;
  }
}

function findWeeklyPayPeriods(generalSettings, today) {
  const { weeklyPayDay, payPeriodExamples, holidaysObserved } = generalSettings;

  let payPeriodsArray = [];
  let startDate = new Date(payPeriodExamples[0].startDate + 'T00:00');
  let endDate = new Date(payPeriodExamples[0].endDate + 'T00:00');
  while(endDate.getFullYear() - today.getFullYear() < 2) {
    if(areDatesWithinXMonths(startDate, today, 1)) {
      payPeriodsArray.push([startDate, endDate]);
    }
    let copyStart = new Date(startDate);
    startDate = new Date(copyStart.setDate(copyStart.getDate() + 7));
    let copyNewStart = new Date(startDate);
    endDate = new Date(copyNewStart.setDate(copyNewStart.getDate() + 6));
  }

  payPeriodsArray = addWeeklyPayDays(payPeriodsArray, weeklyPayDay, holidaysObserved);
  return payPeriodsArray;
}

function findBiWeeklyPayPeriods(generalSettings, today) {
  const { weeklyPayDay, payPeriodExamples, holidaysObserved } = generalSettings;

  let payPeriodsArray = [];
  let startDate = new Date(payPeriodExamples[0].startDate + 'T00:00');
  let endDate = new Date(payPeriodExamples[0].endDate + 'T00:00');
  while(endDate.getFullYear() - today.getFullYear() < 2) {
    if(areDatesWithinXMonths(startDate, today, 2)) {
      payPeriodsArray.push([startDate, endDate]);
    }
    let copyStart = new Date(startDate);
    startDate = new Date(copyStart.setDate(copyStart.getDate() + 14));
    let copyNewStart = new Date(startDate);
    endDate = new Date(copyNewStart.setDate(copyNewStart.getDate() + 13));
  }

  payPeriodsArray = addWeeklyPayDays(payPeriodsArray, weeklyPayDay, holidaysObserved);
  return payPeriodsArray;
}

function addWeeklyPayDays(payPeriodsArray, weeklyPayday, holidaysObserved){
  let output = [];
  for(let i = 0; i < payPeriodsArray.length; i++) {
    let start = payPeriodsArray[i][0];
    let end = payPeriodsArray[i][1];
    let checkDate = getNextWeekdayFromDate(end, weeklyPayday);
    checkDate = getAdjustedPayDate(checkDate.getFullYear(), checkDate.getMonth(), checkDate.getDate(), holidaysObserved);
    output.push([start, end, checkDate]);
  }
  return output;
}

function getNextWeekdayFromDate(date, targetWeekday) {
  const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  
  // Find the index of the target weekday
  const targetIndex = daysOfWeek.findIndex(day => day.toLowerCase() === targetWeekday.toLowerCase());
  
  if (targetIndex === -1) {
      throw new Error("Invalid weekday name provided");
  }
  
  let currentDayIndex = date.getDay();
  let daysAhead = targetIndex - currentDayIndex;

  if (daysAhead <= 0) daysAhead += 7; // Ensure the target day is after the current date

  let nextWeekday = new Date(date);
  nextWeekday.setDate(date.getDate() + daysAhead);
  return nextWeekday;
}

function areDatesWithinXMonths(date1, date2, months = 2) {
  // Calculate the difference in months between two dates
  const year1 = date1.getFullYear();
  const month1 = date1.getMonth();
  const year2 = date2.getFullYear();
  const month2 = date2.getMonth();

  // Compute total months for more straightforward comparison
  const totalMonths1 = year1 * 12 + month1;
  const totalMonths2 = year2 * 12 + month2;

  // Calculate the month difference
  const monthDifference = Math.abs(totalMonths1 - totalMonths2);

  // Check if the month difference is less than or equal to 2
  return monthDifference <= months;
}

function findMonthlyPayPeriods(generalSettings, today) {
  const { monthlyPayDays, holidaysObserved } = generalSettings;

  today = new Date(today.setMonth(today.getMonth()+2));

  let surroundingPayPeriods = [];
  for(let i = 0; i < 8; i++) {
    let payPeriod = getMonthlyPayPeriodArray(generalSettings, today);
    surroundingPayPeriods.unshift(payPeriod);
    let lastDateCopy = new Date(payPeriod[0]);
    today = new Date(lastDateCopy.setDate(lastDateCopy.getDate() - 1));
  }

  surroundingPayPeriods = addMonthlyPayDays(surroundingPayPeriods, [monthlyPayDays[0]], holidaysObserved);
  return surroundingPayPeriods;
}

function getMonthlyPayPeriodArray(generalSettings, today) {
  const {
    payPeriodDays1,
  } = generalSettings;

  let consisten1EndDay = payPeriodDays1[1];
  let consistent1StartDay = payPeriodDays1[0];

  const currentYear = today.getFullYear();
  const currentMonth = today.getMonth();


  let payPeriod1End = getDateFromDayString(currentYear, currentMonth, consisten1EndDay);
  let payPeriod1Start = getDateFromDayString(currentYear, currentMonth, consistent1StartDay);

  let payPeriod = [payPeriod1Start, payPeriod1End];

  return payPeriod;
}

function findEvenSemiMonthlyPayPeriods(generalSettings, today) {
  const {
    monthlyPayDays,   // ["15th", "Last Day of Month"]
    holidaysObserved  // []
  } = generalSettings;

  today = new Date(today.setMonth(today.getMonth() + 3));

  let surroundingPayPeriods = [];
  for(let i = 0; i < 10; i++) {
    let payPeriods = getEvenSemiPayPeriodArray(generalSettings, today);
    surroundingPayPeriods.unshift(payPeriods[1]);
    surroundingPayPeriods.unshift(payPeriods[0]);
    let lastDateCopy = new Date(payPeriods[0][0]);
    today = new Date(lastDateCopy.setDate(lastDateCopy.getDate() - 1));
  }

  surroundingPayPeriods = addMonthlyPayDays(surroundingPayPeriods, monthlyPayDays, holidaysObserved);
  return surroundingPayPeriods;
}

function getEvenSemiPayPeriodArray(generalSettings, today) {
  const {
    payPeriodDays1,   // ["10th", "23rd"]
    payPeriodDays2,   // ["24th", "9th"]
  } = generalSettings;

  let consistent2EndDay = payPeriodDays2[1];
  let consistent1StartDay = payPeriodDays1[0];

  let todayCopy = new Date(today);

  const currentYear = todayCopy.getFullYear();
  const currentMonth = todayCopy.getMonth();

  let payPeriod2End = getDateFromDayString(currentYear, currentMonth, consistent2EndDay);
  let ppe2Copy = new Date(payPeriod2End);
  let payPeriod2Start = new Date(ppe2Copy.setDate(ppe2Copy.getDate() - 14));
  let pps2Copy = new Date(payPeriod2Start);

  let payPeriod1End = new Date(pps2Copy.setDate(pps2Copy.getDate() - 1));
  let ppe1Copy = new Date(payPeriod1End);
  let payPeriod1Start = getDateFromDayString(ppe1Copy.getFullYear(), ppe1Copy.getMonth(), consistent1StartDay);
  if(payPeriod1Start > payPeriod1End) {
    payPeriod1Start = getDateFromDayString(ppe1Copy.getFullYear(), ppe1Copy.getMonth() - 1, consistent1StartDay);
  }

  let first = [payPeriod1Start, payPeriod1End];
  let second = [payPeriod2Start, payPeriod2End];
  let monthPayPeriod = [first, second];

  return monthPayPeriod;
}

function findSemiMonthlyPayPeriods(generalSettings, today) {
  const { monthlyPayDays, holidaysObserved } = generalSettings;

  today = new Date(today.setMonth(today.getMonth()+2));

  let surroundingPayPeriods = [];
  for(let i = 0; i < 8; i++) {
    let payPeriods = getSemiPayPeriodArray(generalSettings, today);
    surroundingPayPeriods.unshift(payPeriods[1]);
    surroundingPayPeriods.unshift(payPeriods[0]);
    let lastDateCopy = new Date(payPeriods[0][0]);
    today = new Date(lastDateCopy.setDate(lastDateCopy.getDate() - 1));
  }

  surroundingPayPeriods = addMonthlyPayDays(surroundingPayPeriods, monthlyPayDays, holidaysObserved);
  return surroundingPayPeriods;
}

function getSemiPayPeriodArray(generalSettings, today) {
  const {
    payPeriodDays1,
    payPeriodDays2,
  } = generalSettings;

  let consistent2EndDay = payPeriodDays2[1];
  let consistent2StartDay = payPeriodDays2[0];
  let consisten1EndDay = payPeriodDays1[1];
  let consistent1StartDay = payPeriodDays1[0];

  const currentYear = today.getFullYear();
  const currentMonth = today.getMonth();

  let payPeriod2End = getDateFromDayString(currentYear, currentMonth, consistent2EndDay);
  let ppe2Copy = new Date(payPeriod2End);
  let payPeriod2Start = getDateFromDayString(ppe2Copy.getFullYear(), ppe2Copy.getMonth(), consistent2StartDay);
  if(payPeriod2Start > payPeriod2End) {
    payPeriod2Start = getDateFromDayString(ppe2Copy.getFullYear(), ppe2Copy.getMonth() - 1, consistent2StartDay);
  }
  let pps2Copy = new Date(payPeriod2Start);

  let payPeriod1End = getDateFromDayString(pps2Copy.getFullYear(), pps2Copy.getMonth(), consisten1EndDay);
  if(payPeriod1End > payPeriod2Start) {
    payPeriod1End = getDateFromDayString(pps2Copy.getFullYear(), pps2Copy.getMonth() - 1, consisten1EndDay);
  }
  let ppe1Copy = new Date(payPeriod1End);
  let payPeriod1Start = getDateFromDayString(ppe1Copy.getFullYear(), ppe1Copy.getMonth(), consistent1StartDay);
  if(payPeriod1Start > payPeriod1End) {
    payPeriod1Start = getDateFromDayString(ppe1Copy.getFullYear(), ppe1Copy.getMonth() - 1, consistent1StartDay);
  }

  let first = [payPeriod1Start, payPeriod1End];
  let second = [payPeriod2Start, payPeriod2End];
  let monthPayPeriod = [first, second];

  return monthPayPeriod;
}

function addMonthlyPayDays(payPeriodsArray, monthlyPayDays, holidaysObserved){
  let output = [];
  for(let i = 0; i < payPeriodsArray.length; i++) {
    let start = payPeriodsArray[i][0];
    let end = payPeriodsArray[i][1];
    let checkDate = getNextDayFromDate(end, monthlyPayDays);
    checkDate = getAdjustedPayDate(checkDate.getFullYear(), checkDate.getMonth(), checkDate.getDate(), holidaysObserved);
    output.push([start, end, checkDate]);
  }
  return output;
}

function getNextDayFromDate(endDate, payDates) {
  let endDateCopy = new Date(endDate);
  let closestPayDay = new Date(endDateCopy.setMonth(endDateCopy.getMonth() + 2));
  for(let i = 0; i < payDates.length; i++) {
    let date = payDates[i];
    let monthPayDay = getDateFromDayString(endDate.getFullYear(), endDate.getMonth(), date);
    let nextMonthPayDay = getDateFromDayString(endDate.getFullYear(), endDate.getMonth() + 1, date);
    if(nextMonthPayDay > endDate && nextMonthPayDay < closestPayDay) {
      closestPayDay = nextMonthPayDay;
    }
    if(monthPayDay > endDate && monthPayDay < closestPayDay) {
      closestPayDay = monthPayDay;
    }
  }
  return closestPayDay;
}

function getDateFromDayString(year, month, dayString) {
  if (dayString.includes('Last Day')) {
    return new Date(year, month + 1, 0); // Last day of the current month
  }
  return new Date(year, month, parseInt(dayString.replace(/\D/g, ''), 10));
}


  function formatDate(date) {
    return date.toISOString().slice(0, 10);
  }

  function getAdjustedPayDate(year, month, day, holidaysObserved) {
    function getStandardUSHolidays(year) {
      return [
        `${year}-01-01`, // New Year's Day (January 1)
        getNthWeekdayOfMonth(year, 0, 2, 1), // Martin Luther King Jr. Day (Third Monday in January)
        getNthWeekdayOfMonth(year, 0, 2, 2), // Presidents' Day (Third Monday in February)
        getLastWeekdayOfMonth(year, 4, 1), // Memorial Day (Last Monday in May)
        `${year}-07-04`, // Independence Day (July 4)
        getNthWeekdayOfMonth(year, 0, 1, 8), // Labor Day (First Monday in September)
        `${year}-11-11`, // Veterans Day (November 11)
        getNthWeekdayOfMonth(year, 3, 4, 11), // Thanksgiving Day (Fourth Thursday in November)
        `${year}-12-25`, // Christmas Day (December 25)
      ];
    }

    function getNthWeekdayOfMonth(year, month, n, weekday) {
      const firstDay = new Date(year, month, 1).getDay();
      const offset = (weekday - firstDay + 7) % 7;
      return formatDate(new Date(year, month, 1 + offset + (n - 1) * 7));
    }

    function getLastWeekdayOfMonth(year, month, weekday) {
      const lastDay = new Date(year, month + 1, 0);
      const lastDayOfWeek = lastDay.getDay();
      const offset = (lastDayOfWeek - weekday + 7) % 7;
      return formatDate(new Date(lastDay.getFullYear(), lastDay.getMonth(), lastDay.getDate() - offset));
    }

    function isWeekend(date) {
      const dayOfWeek = date.getDay();
      return dayOfWeek === 6 || dayOfWeek === 0;
    }

    function isHoliday(date, holidays) {
      const formattedDate = formatDate(date);
      return holidays.includes(formattedDate);
    }

    function adjustPayDate(date, holidays) {
      while (isWeekend(date) || isHoliday(date, holidays)) {
        date.setDate(date.getDate() - 1);
      }
      return date;
    }

    if (!holidaysObserved || holidaysObserved.length === 0) {
      holidaysObserved = getStandardUSHolidays(year);
    }

    let payDate = new Date(year, month, day);
    payDate = adjustPayDate(payDate, holidaysObserved);

    return payDate;
  }


export function buildReportCompilerStateStaff(
  settings,
  selectedSettingsPreset,
  reportCompilerState
) {
  // Clone reportCompilerState to avoid direct mutation
  let newState = {
    ...reportCompilerState,
    studiosInformation: { ...reportCompilerState.studiosInformation },
  };

  var questions = newState.inputFilesArrays.questions;

  let builtStaff = buildStaff(settings.staff, selectedSettingsPreset);
  if (questions.organizeStaffLastName.value) {
    newState.studiosInformation.staffArray = builtStaff.sort(sortArrayLastName);
  } else {
    newState.studiosInformation.staffArray = builtStaff;
  }

  newState.inputFilesArrays = {
    ...newState.inputFilesArrays,
    input1Pay: builtStaff,
  };

  let staffTypes =
    settings[getUrlVariableValue("settingsId")].generalSettings.staffTypes;
  let specialistTypes = staffTypes
    .filter((st) => st.sessionPay)
    .map((st) => st.name);

  let builtInstructorArray = builtStaff.filter((staffMember) => {
    return (
      staffMember.infoObject &&
      Object.values(staffMember.infoObject).some((info) =>
        specialistTypes.includes(info.type)
      )
    );
  });

  if (questions.organizeStaffLastName.value) {
    builtInstructorArray.sort(sortArrayLastName);
  }

  newState.studiosInformation.instructorsArray = builtInstructorArray;

  // Update UI Error Correction
  newState = buildReportCompilerStateErrorList(newState);

  return newState;
}

export function buildReportCompilerStateErrorList(reportCompilerState) {
  let newState = {
    ...reportCompilerState,
    errorCorrection: { ...reportCompilerState.errorCorrection },
  };

  let staffFields = reportCompilerState.inputFilesArrays.questions.staffFields;
  let staffTypes = reportCompilerState.inputFilesArrays.questions.staffTypes;
  let namingConventions = reportCompilerState.inputFilesArrays.questions.namingConventions;

  var excelOutputInstance = new ExcelOutput(newState);

  let unaddedClasses = excelOutputInstance.createUnaddedSessions();
  let unaddedStaff = excelOutputInstance.createUnaddedStaffLists();

  let unaddedStaffObjs = unaddedStaff.unaddedStaffObjs;
  let unaddedCloseStaffObjs = unaddedStaff.unaddedCloseStaffObjs;
  let unaddedStaffLocationObjs = unaddedStaff.unaddedStaffLocationObjs;
  let incorrectTypeStaffObjs = unaddedStaff.incorrectTypeStaffObjs;

  const uniqueUnaddedClasses = Array.from(
    new Set(unaddedClasses.map((obj) => JSON.stringify(obj)))
  ).map((str) => JSON.parse(str));
  const uniqueUnaddedStaffObjs = Array.from(
    new Set(unaddedStaffObjs.map((obj) => JSON.stringify(obj)))
  ).map((str) => JSON.parse(str));
  const uniqueUnaddedCloseStaffObjs = Array.from(
    new Set(unaddedCloseStaffObjs.map((obj) => JSON.stringify(obj)))
  ).map((str) => JSON.parse(str));
  const uniqueUnaddedStaffLocationObjs = Array.from(
    new Set(unaddedStaffLocationObjs.map((obj) => JSON.stringify(obj)))
  ).map((str) => JSON.parse(str));
  const uniqueIncorrectTypeStaffObjs = Array.from(
    new Set(incorrectTypeStaffObjs.map((obj) => JSON.stringify(obj)))
  ).map((str) => JSON.parse(str));

  let classList = [];

  if (uniqueUnaddedClasses) {
    uniqueUnaddedClasses.map((classObj) => {
      classList.push({
        label: (
          <span>
            <span className="list__item-text--wrong">{classObj}</span>
          </span>
        ),
        name: classObj,
        nearestName: null,
        errorType: "unaddedClass",
        errorMessage: "Unadded " + namingConventions.sessionsName,
      });
    });
  }
  let staffList = [];
  if (uniqueUnaddedStaffObjs) {
    uniqueUnaddedStaffObjs.map((staffObj) => {
      staffList.push({
        label: (
          <span>
            <span className="list__item-text--wrong">{staffObj.name}</span>
          </span>
        ),
        name: staffObj.name,
        nearestName: null,
        errorType: "unaddedStaff",
        errorMessage: staffObj.error,
      });
    });
  }
  if (uniqueUnaddedCloseStaffObjs) {
    uniqueUnaddedCloseStaffObjs.map((staffObj) => {
      staffList.push({
        label: (
          <span>
            Found{" "}
            <span className="list__item-text--wrong">
              {staffObj.name}
            </span>
            , did you mean:{" "}
            <span className="list__item-text--correct">
              {staffObj.nearestName}
            </span>
          </span>
        ),

        name: staffObj.name,
        nearestName: staffObj.nearestName,
        errorType: "unaddedAlternateName",
        errorMessage: staffObj.error,
      });
    });
  }
  let locationName = namingConventions?.locationName || "location";
  if (uniqueUnaddedStaffLocationObjs) {
    uniqueUnaddedStaffLocationObjs.map((staffObj) => {
      staffList.push({
        label: (
          <span>
            <span className="list__item-text--correct">{staffObj.name}</span> is
            not assigned to {locationName.toLowerCase()}:{" "}
            <span className="list__item-text--wrong">{staffObj.location}</span>
          </span>
        ),
        name: staffObj.name,
        location: staffObj.location,
        errorType: "unaddedLocation",
        errorMessage: staffObj.error,
      });
    });
  }
  let sessionName = namingConventions?.sessionsName || "sessions";
  if (uniqueIncorrectTypeStaffObjs) {
    uniqueIncorrectTypeStaffObjs.map((staffObj) => {
      staffList.push({
        label: (
          <span>
            <span className="list__item-text--correct">{staffObj.name}</span>{" "}
            had {sessionName.toLowerCase()} but is type:{" "}
            <span className="list__item-text--wrong">{staffObj.type}</span>
          </span>
        ),
        name: staffObj.name,
        type: staffObj.type,
        correctStaffType: staffTypes.map(staff => staff.name).filter(
          (type) => type !== staffObj.type
        ),

        errorType: "wrongStaffType",
        errorMessage: staffObj.error,
      });
    });
  }
  // Your logic remains the same, just ensure to update newState instead of reportCompilerState
  // ...
  // At the end, instead of directly modifying reportCompilerState, update newState accordingly
  newState.errorCorrection = {
    classList,
    staffList,
  };

  return newState;
}

export function buildReportCompilerStateQuestions(settings, selectedSettingsPreset, reportCompilerState) {
  // Clone reportCompilerState to avoid direct mutation
  let newState = {
    ...reportCompilerState,
    inputFilesArrays: {
      ...reportCompilerState.inputFilesArrays,
      questions: { ...reportCompilerState.inputFilesArrays.questions },
    },
  };

  // Assuming buildSettings is a function that generates the questions based on the settings
  let questions = buildSettings(selectedSettingsPreset, settings.staff);

  // Update the questions in the cloned state
  newState.inputFilesArrays.questions = questions;

  // Update UI Error Correction
  newState = buildReportCompilerStateErrorList(newState);

  return newState;
}



export function addExcelData(action, reportCompilerState) {
  let selectedDates = action.selectedDates;
  let { tabOne, tabTwo, tabThree } = readExcelFile(
    action.workbook,
    reportCompilerState
  );

  //only for deputy file which needs to indicate location in excel name
  let fileName = action?.fileName;
  let studioName = action.studio ?? "Unknown Studio";
  if (
    fileName &&
    reportCompilerState.inputFilesArrays.questions.useDeputyTime.value
  ) {
    studioName = fileName.substring(0, fileName.indexOf("_"));
  }
  let formattedExcelData = convertExcelFile(
    action.fileId,
    tabOne,
    tabThree,
    tabTwo,
    studioName,
    reportCompilerState,
    selectedDates
  );

  if (action.fildId == "time1file") {
    // Additional logic specific to time1file
  }

  let fileField = action.fileId.replace("file", "");

  if (formattedExcelData.correctFileType) {
    reportCompilerState.inputFilesArrays[fileField] =
      formattedExcelData.dataArray;
    if (formattedExcelData.backupBELArray) {
      reportCompilerState.inputFilesArrays["BACKUP" + fileField] =
        formattedExcelData.backupBELArray;
    }

    if (formattedExcelData.payPeriod) {
      reportCompilerState.payrollInformation.belPayPeriods =
        formattedExcelData.payPeriod;
    }

    if (formattedExcelData.classesInFile) {
      reportCompilerState.payrollInformation.classesInFiles = [
        ...reportCompilerState.payrollInformation.classesInFiles,
        formattedExcelData.classesInFile,
      ];
    }

    if (formattedExcelData.staffInFile) {
      reportCompilerState.payrollInformation.staffInFiles =
        reportCompilerState.payrollInformation.staffInFiles.concat(
          formattedExcelData.staffInFile
        );
    }

    if (formattedExcelData.customerDetailArray) {
      let studioIndex = fileField.replace("pay", "");
      reportCompilerState.inputFilesArrays[`customer${studioIndex}`] =
        formattedExcelData.dataArray;
    }
  }

  return reportCompilerState;
}

export function compileTimePunchDetailData(action, reportCompilerState) {
  let entries = [];
  var timeObjects = action.timeObjects;
  var studioId = action.studioId;
  Object.entries(timeObjects).map(([staffName, info]) => {
    let punchObjs = info;
    for (let i = 2; i < punchObjs.length; i++) {
      if (
        punchObjs[i - 1].EmployeeName &&
        punchObjs[i - 1].EmployeeName.includes("Group")
      ) {
        if (
          i + 1 === punchObjs.length ||
          punchObjs[i + 1] === undefined ||
          punchObjs[i + 1].EmployeeName.includes("Group")
        ) {
          entries.push({
            name: staffName,
            location: punchObjs[i].Location,
            date: punchObjs[i].Date,
            timeIn: punchObjs[i].Time,
            timeOut: "???",
            comment: "NO PUNCH-OUT FOUND",
          });
        } else if (punchObjs[i + 1].Date !== punchObjs[i].Date) {
          entries.push({
            name: staffName,
            location: punchObjs[i].Location,
            date: punchObjs[i].Date,
            timeIn: punchObjs[i].Time,
            timeOut: "???",
            comment: "PUNCH-OUT DATE DIFFERENT - " + punchObjs[i + 1].Date,
          });
        } else {
          entries.push({
            name: staffName,
            location: punchObjs[i].Location,
            date: punchObjs[i].Date,
            timeIn: punchObjs[i].Time,
            timeOut: punchObjs[i + 1].Time,
            comment: "",
          });
        }
      }
    }
  });
  let timeEvents = [];
  for (let x = 0; x < entries.length; x++) {
    if (entries[x].name === undefined) {
      continue;
    }
    let hours = calculateTimeDifference(entries[x].timeIn, entries[x].timeOut);
    let rate = findHourlyPayRate(
      entries[x].name,
      entries[x].location,
      reportCompilerState.studiosInformation
    );
    let date = new Date(entries[x].date);
    let options = {
      weekday: "short",
      year: "numeric",
      month: "numeric",
      day: "numeric",
    };
    let formattedDate = date.toLocaleString("en-US", options);
    let timeDetail = new TimeEvent(
      entries[x].name,
      entries[x].location,
      hours,
      rate,
      formattedDate + " (" + entries[x].timeIn + "-" + entries[x].timeOut + ")",
      hours * rate
    );

    timeDetail.punchIn = entries[x].timeIn;
    timeDetail.punchOut = entries[x].timeOut;
    if (
      !reportCompilerState.inputFilesArrays.questions.punchDetailInStaffTabs
    ) {
      timeDetail.detail = true;
    }
    timeDetail.comment = entries[x].comment;
    const newStaff = {
      name: entries[x].name,
      type: "time",
      location: entries[x].location,
    };

    let isDuplicate = reportCompilerState.payrollInformation.staffInFiles.some(
      (staff) =>
        staff.name === newStaff.name &&
        staff.type === newStaff.type &&
        staff.location === newStaff.location
    );

    if (!isDuplicate) {
      reportCompilerState.payrollInformation.staffInFiles.push(newStaff);
    }
    // reportCompilerState.payrollInformation.staffInFiles.push({
    //   name: entries[x].name,
    //   type: "time",
    //   location: entries[x].location,
    // });
    timeEvents.push(timeDetail);
  }
  reportCompilerState.inputFilesArrays[studioId] = timeEvents;
  return reportCompilerState;
}


export function handleBeforeExcelCreation(action, reportCompilerState, settings) {
  reportCompilerState = resetReportData(reportCompilerState);

  /*** MERGE ALL STUDIO REPORT DATA ****/
  reportCompilerState = mergeAllStudioReportData(reportCompilerState);

  reportCompilerState.studiosInformation.studiosInOutput =
    getUniqueLocationsFromUpperCaseKeys(reportCompilerState.inputFilesArrays);

  /*** ADD TIME CLOCK CATCH ALL ****/
  reportCompilerState = addTimeClockCatchAll(reportCompilerState);

  

  /*** MERGE ALL STUDIO CUSTOMER DATA****/
  reportCompilerState.inputFilesArrays.CUSTOMER =
    mergeStudioCustomerData(reportCompilerState);

  /**** FIND PAY RATES ****/
  reportCompilerState.inputFilesArrays.TIME = findPayRates(
    reportCompilerState.inputFilesArrays,
    reportCompilerState.studiosInformation
  );

  if(reportCompilerState.inputFilesArrays.questions.useComplexTimeSettings){
    let [newTime, staffInEarnings] = addEarnings(
      reportCompilerState.inputFilesArrays.TIME,
      reportCompilerState.inputFilesArrays.input1Pay
    );
    reportCompilerState.inputFilesArrays.TIME = newTime;
    reportCompilerState.payrollInformation.staffInFiles = reportCompilerState.payrollInformation.staffInFiles.concat(staffInEarnings);
  }

  let [staffArray, instructorsArray] = grabRelevantStaff(reportCompilerState);
  reportCompilerState.studiosInformation.staffArray = staffArray;
  reportCompilerState.studiosInformation.instructorsArray = instructorsArray;

  /**** ADD UNATTENDED BOOKING EVENTS ****/
  reportCompilerState = addUnattendedBookingEvents(reportCompilerState);
  /**** INIT CLASS CREATION AND BUILD CLASSES ****/
  var classCreationInstance = new ClassCreation(reportCompilerState, []);
  classCreationInstance.createClasses();
  reportCompilerState.studiosInformation.classes =
    classCreationInstance.classes;

  // /**** INIT COMMISSION CREATION AND CREATE COMMISSIONS ARARAY ****/
  var commissionCreationInstance = new CommissionCreation(reportCompilerState);
  commissionCreationInstance.createCommission();
  reportCompilerState = commissionCreationInstance.reportCompilerState;
  
  if(reportCompilerState.inputFilesArrays.questions.useComplexTimeSettings){
    reportCompilerState.inputFilesArrays.TIME = addOvertime(
      reportCompilerState
    );

    reportCompilerState.inputFilesArrays.TIME = addHolidayPay(
      reportCompilerState
    );
  }
  
  return reportCompilerState;
}
export function handleExcelCreation(action, reportCompilerState, settings) {
  /**** INIT EXCEL CREATION AND WRITE EXCEL ****/
  var excelOutputInstance = new ExcelOutput(reportCompilerState);
  excelOutputInstance.writeExcel();
  reportCompilerState = excelOutputInstance.reportCompilerState;

  var testOutputS3Path;
  if (action?.testMode) {
    //
    // // ~~~~ need to think of way to decipher between live run and auto test run. one will upload initial session output while test will append the newly tested output.
    // console.log("UPLOADING TEST OUTPUT TO S3 AND THEN RUNNING COMPARISON LAMBDA")
    // const uid = getUID();
    // testOutputS3Path = await uploadTestOutput(
    //   action.latestSessionPath,
    //   reportCompilerState.output.workbook,
    //   reportCompilerState.output.name
    // );
    // testOutputS3Path = uploadTestOutput(
    //   action.latestSessionPath,
    //   reportCompilerState.output.workbook,
    //   reportCompilerState.output.name
    // );
    // reportCompilerState.output.testOutputS3Path = testOutputS3Path;
    return reportCompilerState;

    // TRIGGER TEST OUTPUT COMPARISON LAMBDA FUNCTION
  } else {
    /**** INIT SESSION SNAPSHOT ****/
    /**
     output workbook = reportCompilerState.output.workbook;
     staff = settings.staff.staff;
     settingsPreset = settings[getUrlVariableValue("settingsId")]
     selectedDates = reportCompilerState.scrapeInfo.selectedDates
     */

     
    initializeSessionSnapshot(
      settings[getUrlVariableValue("settingsId")],
      settings.staff.staff,
      reportCompilerState.output.workbook,
      reportCompilerState.output.session,
      reportCompilerState.scrapeInfo.selectedDates
    );
  }

  return reportCompilerState;
}
export async function handleDownload(action, reportCompilerState, settings) {
  console.log("handledownload")
  reportCompilerState = handleBeforeExcelCreation(action, reportCompilerState, settings);

  // ASYNC LOGIC
  /**** INIT COMMISSION CREATION AND CREATE COMMISSIONS ARARAY ****/
  var commissionCreationInstance = new CommissionCreation(reportCompilerState);
  commissionCreationInstance.createCommission();
  console.log("AWAITING compilePreviousAgreementData")

  if (reportCompilerState.inputFilesArrays.questions.salesBuckets && reportCompilerState.inputFilesArrays.questions.salesBuckets.length > 0) {
    await commissionCreationInstance.compilePreviousAgreementData(); // 10 search sets per request, 3 API requests
    console.log("DONE compilePreviousAgreementData")
  }


  reportCompilerState = commissionCreationInstance.reportCompilerState;
  reportCompilerState = handleExcelCreation(action, reportCompilerState, settings);
  return reportCompilerState;
}

export function minimumFilesUploaded(reportCompilerState) {
  for (
    let i = 1;
    i <= reportCompilerState.payrollInformation.numberOfStudios;
    i++
  ) {
    if (
      reportCompilerState.inputFilesArrays["Uploaded-" + "bel" + i] ===
      undefined
    ) {
      return false;
    }
  }

  return true;
}

export function getReportIndex(report, reportCompilerState) {
  return reportCompilerState.scrapeInfo.reportsNeeded.indexOf(report);
}
