import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Autocomplete,
  Link,
} from '@mui/material';
import VideocamIcon from '@mui/icons-material/Videocam';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import StoreIcon from '@mui/icons-material/Store';
import './MutableLocationTable.scss';
import isAlphaNumeric from '../../utility-functions/GeneralFunctions/IsAlphaNumeric';

/**
 * A component for rendering a mutable table of locations / studios.
 *
 * @param {Object} props - The properties object.
 * @param {string} props.locationName - The label to use for a location, such as "Location" or "Studio".
 * @param {string} props.locationsName - The plural of the label to use for a location, such as "Locations" or "Studios".
 * @param {string[]} props.locationList - The current list of locations.
 * @param {function(string): void} props.onCreate - A callback function to create a location, receiving the new location's name as a parameter.
 * @param {function(string): void} props.onDelete - A callback function to delete a location, receiving the location as a parameter.
 * @param {string[]} props.allClubReadyLocations - A list of all of the user's available ClubReady locations, which will be visible in the autocomplete
 * @param {boolean} props.hasMaxLocations - Whether the user has reached their maximum number of allowed locations
 * @returns {JSX.Element} A React component rendering the mutable location table.
 */
const MutableLocationTable = ({ locationName, locationsName, locationList, onCreate, onDelete, allClubReadyLocations, hasMaxLocations }) => {
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [confirmationText, setConfirmationText] = useState('');
  const [newLocationName, setNewLocationName] = useState('');
  const [createErrors, setCreateErrors] = useState([]);

  const handleOpenDeleteDialog = (item) => {
    setSelectedLocation(item);
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setSelectedLocation(null);
    setConfirmationText('');
  };

  const handleDelete = () => {
    if (selectedLocation && confirmationText === selectedLocation) {
      onDelete(selectedLocation)
      handleCloseDeleteDialog();
    }
  };

  const handleOpenCreateDialog = () => {
    const trimmedName = newLocationName.trim();
    if (trimmedName) {
      setOpenCreateDialog(true);
    }
  };

  const handleCloseCreateDialog = () => {
    setOpenCreateDialog(false);
    setCreateErrors([]);
  };

  const handleCreate = () => {
    const trimmedName = newLocationName.trim();
    if (trimmedName && !locationList.includes(trimmedName)) {
      onCreate(trimmedName);
      setNewLocationName('');
      handleCloseCreateDialog();
    }
  };

  return (
    <div>
      <div style={{ marginBottom: '20px', display: 'flex', flexDirection: 'column', gap: '5px' }}>
        <div className="inputContainer">
          <Autocomplete
            fullWidth
            options={allClubReadyLocations.filter(location => !locationList.includes(location))}
            noOptionsText={!hasMaxLocations ? `No ${locationsName.toLowerCase()} found in your account with this name.` : `Maximum number of ${locationsName.toLowerCase()} reached.`}
            renderInput={(params) => (
              <TextField
                {...params}
                label={`${locationName} name`}
                error={createErrors?.length > 0 && !hasMaxLocations}
                disabled={hasMaxLocations}
                helperText={hasMaxLocations ? `Maximum number of ${locationsName.toLowerCase()} reached` : createErrors.join("\n")}
                placeholder={`Enter new ${locationName?.toLowerCase() || 'location'} name`}
              />
            )}
            inputValue={newLocationName}
            onInputChange={(_, newName, reason) => {
              if (hasMaxLocations) return;

              // This occurs when the user clicks off of the input box, or submits the new studio to be created
              if (reason === "reset" && (
                newName === "" // case where the user DOES have input in the box and simply clicks off
                ||
                locationList.includes(newName.trim()) // case where the user submits the new studio's name
              )) return;

              if (newName === undefined || newName === null) return;

              setNewLocationName(newName);
              const isNameConflict = locationList.includes(newName);
              const hasInvalidCharacter = !newName?.split('').every((item) => {
                return item === "-" || isAlphaNumeric(item) || item === " ";
              });
              let newErrors = [];
              if (isNameConflict) {
                newErrors.push(`A ${locationName.toLowerCase()} with this name already exists`);
              }
              if (hasInvalidCharacter) {
                newErrors.push("Name contains at least one invalid character. Please only include capital letters, lowercase letters, numbers, spaces, and \"-\".")
              }
              setCreateErrors(newErrors);
            }}
          />

          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenCreateDialog}
            disabled={!newLocationName.trim() || createErrors?.length > 0 || hasMaxLocations}
            className="addButton"
          >
            Add
          </Button>
        </div>
      </div>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{locationName ? locationName : "Location"}{" Name"}</TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {locationList.map((item) => (
              <TableRow key={item}>
                <TableCell><p className="locationName"><StoreIcon />{' '}{item}</p></TableCell>
                <TableCell align="right">
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={() => handleOpenDeleteDialog(item)}
                  >
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Create Confirmation Dialog */}
      <Dialog open={openCreateDialog} onClose={handleCloseCreateDialog}>
        <DialogTitle>Confirm Creation</DialogTitle>
        <DialogContent className="createDialog">
          {(!allClubReadyLocations.includes(newLocationName) &&
            newLocationName !== "" // add this to avoid showing the warning during the Dialog close animation
          )
            ? <>
              <p className="warningText">
                <WarningAmberIcon /><strong>Warning</strong>: there are no {locationsName?.toLowerCase() || 'locations'} in any of your connected ClubReady accounts with the name
                "<strong>{newLocationName.trim()}</strong>".               </p>
              <p className="infoText">
                <Link href="/resources/Adding-And-Removing-Locations"><VideocamIcon />Watch this video</Link>
                {' '} for more information about adding & removing {locationsName?.toLowerCase() || 'locations'}.
              </p>
            </>
            : <p>
              {`Are you sure you want to create a new ${locationName?.toLowerCase() || 'location'} named `}
              <strong>{"\""}{newLocationName.trim()}{"\""}</strong>?
            </p>
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseCreateDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleCreate} color="primary">
            Create
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent className="deleteDialog">
          <p className="warningText">
            <WarningAmberIcon /><strong>Warning</strong>: Deleting a {" " + locationName.toLowerCase() + " "} is permanent.
            <Link href="/resources/Adding-And-Removing-Locations" color="inherit"><VideocamIcon />Watch this video</Link>
            {' '} for more information about adding & removing {locationsName?.toLowerCase() || 'locations'}.
          </p>
          <p>
            {" To confirm deletion, please type the name of the " + locationName.toLowerCase() + ", "}
            <strong>{"\""}{selectedLocation}{"\""}</strong>
          </p>
          <TextField
            fullWidth
            value={confirmationText}
            onChange={(e) => setConfirmationText(e.target.value)}
            placeholder={"Type the resource name, " + `"${selectedLocation}" (without quotes)`}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleDelete}
            color="error"
            disabled={confirmationText !== selectedLocation}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default MutableLocationTable;
