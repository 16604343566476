// src/components/NoDataPlaceholder.jsx
import React from "react";
import { Box, Typography, Button } from "@mui/material";
import FolderOffIcon from "@mui/icons-material/FolderOff"; // or any icon you like

export default function NoDataPlaceholder({ onClickAction }) {
  return (
    <Box
      sx={{
        textAlign: "center",
        border: "1px dashed #ddd",
        borderRadius: 2,
        p: 3,
        color: "#666",
      }}
    >
      <FolderOffIcon sx={{ fontSize: 48, color: "#ccc", mb: 1 }} />
      <Typography variant="h6" sx={{ mb: 1 }}>
        No Paystub Data
      </Typography>
      <Typography variant="body2" sx={{ mb: 2 }}>
        Upload a file on the left to see your staff paystubs here.
      </Typography>
      {onClickAction && (
        <Button variant="outlined" onClick={onClickAction}>
          Browse Files
        </Button>
      )}
    </Box>
  );
}
