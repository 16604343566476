import React, { useEffect, useState } from "react";
import { Typography, Box, Divider, Skeleton, Link } from "@mui/material";
import AttributeListBox from "../../Components/AttributeListBox";
import { clubPilatesLocation } from "../CommonVariables";
import { RevertList } from "../../Components/RevertList";
import VideocamIcon from '@mui/icons-material/Videocam';

import { useAuth } from "../../../contexts/AuthContext";
import { useSettingsContext } from "../../../contexts/SettingsContext";
import { getUrlVariableValue } from "../../../utility-functions/utility-functions.js";
import MutableLocationTable from "../../Components/MutableLocationTable";
import { useClubReady } from "../../../contexts/ClubReadyContext";
import { getLocationName, getLocationNamePlural } from "../../../utility-functions/parseSettingsObject";

function getClubReadyStudios(data) {
  if (!data?.logins || !data.logins?.length || data.logins?.length === 0) return [];
  return data.logins.reduce((accumulator, login) => {
    if (!login?.locations) return accumulator;
    return accumulator.concat(login.locations);
  }, []);
}

export default function Studiomanager(props) {
  const { getUID } = useAuth();
  const [isStudioSelected, setIsStudioSelected] = useState(false);
  const { settings, changes, setChanges, dispatch } = useSettingsContext();
  const { scrapeAccounts } = useClubReady();
  const [clubReadyStudios, setClubReadyStudios] = useState(getClubReadyStudios(scrapeAccounts));
  const [locationName, setLocationName] = useState(getLocationName(settings));
  const [locationNamePlural, setLocationNamePlural] = useState(getLocationNamePlural(settings));

  useEffect(() => {
    setLocationName(getLocationName(settings));
    setLocationNamePlural(getLocationNamePlural(settings));
  }, [settings]);

  useEffect(() => {
    setClubReadyStudios(getClubReadyStudios(scrapeAccounts));
  }, [scrapeAccounts]);

  function handleChange(value, id) {
    let newRev = new RevertList(changes.getValues());
    newRev.addElement(
      id,
      settings[getUrlVariableValue("settingsId")].generalSettings[id],
      value
    );
    setChanges(newRev);

    // UPDATE STUDIOS
    dispatch({
      type: "UPDATE_STUDIO_SETTINGS",
      settingsId: getUrlVariableValue("settingsId"),
      uid: getUID(),
      studios: value,
    });

    // setStudioSettings({
    //   ...studioSettings,
    //   generalSettings: {
    //     ...settings[getUrlVariableValue("settingsId")].generalSettings,
    //     [id]: value,
    //   },
    // });
  }


  const order = [
    "Club",
    "Pure",
    "9Round",
    "Stretch",
    "Row",
    "Yoga",
    "Cycle",
    "BFT",
    "Rumble",
    "Stride",
    "AKT",
  ];

  const sortByFirstWord = (a, b) => {
    const firstWordA = a.split(" ")[0];
    const firstWordB = b.split(" ")[0];
    const indexA = order.indexOf(firstWordA);
    const indexB = order.indexOf(firstWordB);
    if (indexA < indexB) {
      return -1;
    } else if (indexA > indexB) {
      return 1;
    } else {
      return 0;
    }
  };

  const groupByLogic = (option) => {
    if (option.includes("Club Pilates")) {
      return "Club Pilates";
    } else if (option.includes("Pure Barre")) {
      return "Pure Barre";
    } else if (option.includes("StretchLab")) {
      return "Stretch Lab";
    } else if (option.includes("9Round")) {
      return "9Round";
    } else if (option.includes("Row House")) {
      return "Row House";
    } else if (option.includes("Yoga Six")) {
      return "Yoga Six";
    } else if (option.includes("Cycle Bar")) {
      return "Cycle Bar";
    } else if (option.includes("BFT")) {
      return "BFT";
    } else if (option.includes("Rumble")) {
      return "Rumble";
    } else if (option.includes("Stride")) {
      return "Stride";
    } else if (option.includes("AKT")) {
      return "AKT";
    } else {
      return "Other";
    }
  };

  /**** ~~~~ need to add logic for if the url settingsId entered does not exist */
  return (settings.hasOwnProperty("1") && settings.hasOwnProperty("staff"))  ? (
    <Box className="settings-page">
      <Typography variant="h2" className="settings-page__title">
        {"Manage "}{locationNamePlural}
      </Typography>
      <Typography variant="p" className="settings-page__title-description">
        <Link href="/resources/Adding-And-Removing-Locations"><VideocamIcon />Tutorial</Link>
        {` - Add new ${(settings[getUrlVariableValue("settingsId")]?.generalSettings?.locationsName || "Studios").toLowerCase()} or remove existing ones from your account. `}
      </Typography>
      <Divider className="settings-page__divider" />

      <MutableLocationTable 
        locationName={locationName}
        locationsName={locationNamePlural}
        locationList={settings[getUrlVariableValue("settingsId")]?.generalSettings?.studios}
        hasMaxLocations={settings[getUrlVariableValue("settingsId")]?.generalSettings?.studios.length >= 10}
        onCreate={(studio) => {
          const locationList = settings[getUrlVariableValue("settingsId")].generalSettings.studios;
          handleChange(locationList.concat([studio]))
        }}
        onDelete={(studio) => {
          const locationList = settings[getUrlVariableValue("settingsId")].generalSettings.studios;
          handleChange(
            locationList.filter(currStudio => currStudio !== studio), // filter out the studio being deleted from the list
            "studios"
          );
        }}
      allClubReadyLocations={clubReadyStudios}
      />
    </Box>
  ) : (
    <Box className="settings-page">
      <Skeleton>
        <Typography variant="h2" className="settings-page__title">
          {locationNamePlural}
        </Typography>
      </Skeleton>
      <Divider className="settings-page__divider" />
      <Skeleton className="settings-page__attribute-box settings-page__attribute-box--skeleton"></Skeleton>
    </Box>
  );
}
