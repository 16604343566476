import React, { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Button, Box, Divider } from "@mui/material";
import InputBoxElement from "../Components/InputBox/InputBox";
import SelectorBox from "../Components/SelectorBox/SelectorBox";
import BooleanToggleElement from "../Components/BooleanToggle";
import { Stack } from "@mui/material";
import AttributeListBox from "../Components/AttributeListBox";
import { IconButton } from "@mui/material";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { RevertList } from "../Components/RevertList";
import { v4 as uuidv4 } from "uuid";
import { SingleValueInput } from "../Components/AttributeListBox";
import { SingleAttributeListBox } from "../Components/AttributeListBox";
import { specialProcessorValues } from "../StudioSettings/CommonVariables";
import { useSettingsContext } from "../../contexts/SettingsContext";
import { classesExamples } from "../StudioSettings/CommonVariables";
import BasicModal from "../../components/Modal/Modal";
import PropTypes from "prop-types";
import {
  GridRowModes,
  DataGridPro,
  GridToolbarContainer,
  GridActionsCellItem,
} from "@mui/x-data-grid-pro";
import { randomId } from "@mui/x-data-grid-generator";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";

import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Avatar from "@mui/material/Avatar";

import EditRoundedIcon from "@mui/icons-material/EditRounded";
import CloseIcon from "@mui/icons-material/Close";

import Modal from "@mui/material/Modal";
import NorthIcon from "@mui/icons-material/North";
import SouthIcon from "@mui/icons-material/South";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useAuth } from "../../contexts/AuthContext";
import { getUrlVariableValue } from "../../utility-functions/utility-functions.js";
import {  Skeleton } from "@mui/material";

export default function ProcessorMappingList() {
  const { settings, dispatch, changes, setChanges } = useSettingsContext();
  
  const { currentUser, getUID } = useAuth();

  const [modalColumn, setModalColumn] = useState(null);
  const [columnHeaderChange, setColumnHeaderChange] = useState(0);

  const handleHeaderChange = (id, name) => {
    // Set column header name
    dispatch({
      type: "UPDATE_SETTINGS_PROPERTY",
      settingsId: getUrlVariableValue("settingsId"),
      uid: getUID(),
      path: `${getUrlVariableValue(
        "settingsId"
      )}.formatSettings.processorMapping.columns.${id}.name`,
      value: name,
    });
    setColumnHeaderChange(columnHeaderChange + 1);
  };

  const handleAddException = (event) => {
    let id = uuidv4();
    let excelId = uuidv4();

    const columnDefault = {
      name: "text~Default Column",
      id: id,
      columnNumber:
        Object.entries(
          settings[getUrlVariableValue("settingsId")].formatSettings.processorMapping
            .columns
        ).length + 1,
      columnType: {
        name: "",

        constant: {
          empty: false,
          value: "text~",
        },

        staff: {
          attribute: "",
          duplicate: false,
          primary: "text~",
        },

        excel: {
          [excelId]: {
            primaryLookupColumn: "A",
            primaryValues: [],

            secondary: true,
            secondaryLookupColumn: null,
            secondaryValues: [],

            valueColumn: "F",

            rowAttribute: false,
            rowColumnName: "",
            rowColumnValue: "text~",

            secondRowColumnName: "",
            secondRowColumnValue: "text~",
            id: excelId,
          },
        },
      },
    };

    // Set Columns
    dispatch({
      type: "UPDATE_SETTINGS_PROPERTY",
      settingsId: getUrlVariableValue("settingsId"),
      uid: getUID(),
      path: `${getUrlVariableValue(
        "settingsId"
      )}.formatSettings.processorMapping.columns.${id}`,
      value: columnDefault,
    });
    // setColumns({ ...columns, [id]: columnDefault });

    let newRev = new RevertList(changes.getValues());
    newRev.addElement("column-" + id, "n/a", "added");
    setChanges(newRev);

  };

  const handleDeleteException = (id) => {
    let localColumnList = {
      ...settings[getUrlVariableValue("settingsId")].formatSettings.processorMapping
        .columns,
    };
    delete localColumnList[id];
    //  Replace all columns with new localColumnList which filtered out deleted exception
    dispatch({
      type: "UPDATE_SETTINGS_PROPERTY",
      settingsId: getUrlVariableValue("settingsId"),
      uid: getUID(),
      path: `${getUrlVariableValue(
        "settingsId"
      )}.formatSettings.processorMapping.columns`,
      value: localColumnList,
    });

    let newRev = new RevertList(changes.getValues());
    newRev.addElement("column-" + id, "n/a", "delete");
    setChanges(newRev);

    let columnList = {
      ...settings[getUrlVariableValue("settingsId")].formatSettings.processorMapping
        .columns,
    };
    let delColNum = columnList[id].columnNumber;

    Object.keys(columnList).forEach(function(key) {
      columnList[key].columnNumber > delColNum
        ? columnList[key].columnNumber--
        : columnList[key].columnNumber;
    });
    delete columnList[id];

    // Replace all columns with columnList
    dispatch({
      type: "UPDATE_SETTINGS_PROPERTY",
      settingsId: getUrlVariableValue("settingsId"),
      uid: getUID(),
      path: `${getUrlVariableValue(
        "settingsId"
      )}.formatSettings.processorMapping.columns`,
      value: columnList,
    });

    setModalColumn(null);
  };

  const handleMappingChange = (value, id) => {
    let newRev = new RevertList(changes.getValues());
    newRev.addElement(
      "processor-" + id,
      settings[getUrlVariableValue("settingsId")].formatSettings.processorMapping[id],
      value
    );
    setChanges(newRev);

    // Update Mapping Change
    dispatch({
      type: "UPDATE_SETTINGS_PROPERTY",
      settingsId: getUrlVariableValue("settingsId"),
      uid: getUID(),
      path: `${getUrlVariableValue("settingsId")}.formatSettings.processorMapping.${id}`,
      value: value,
    });
  };

  const handleEditException = (id) => {
    setModalColumn(id);
  };

  const handleModalClose = (id) => {
    setModalColumn(null);
  };

  const handleCopyException = (uid) => {
    let id = uuidv4();

    let col =  settings[getUrlVariableValue("settingsId")].formatSettings.processorMapping.columns[uid];
    const objCopy = JSON.parse(JSON.stringify(col));
    objCopy.name = objCopy.name + "(1)";
    objCopy.id = id;
    objCopy.columnNumber = Object.keys( settings[getUrlVariableValue("settingsId")].formatSettings.processorMapping.columns).length + 1;

    let newRev = new RevertList(changes.getValues());
    newRev.addElement("column-" + id, "n/a", "added");
    setChanges(newRev);

    // Add column copy
    dispatch({
      type: "UPDATE_SETTINGS_PROPERTY",
      settingsId: getUrlVariableValue("settingsId"),
      uid: getUID(),
      path: `${getUrlVariableValue(
        "settingsId"
      )}.formatSettings.processorMapping.columns.${id}`,
      value: objCopy,
    });
  };

  const handleMoveException = (direction, id) => {
    let oldColumnNumber =  settings[getUrlVariableValue("settingsId")].formatSettings.processorMapping.columns[id].columnNumber;
    let newColumnNumber = -1;
    if (direction === "up") {
      newColumnNumber =  settings[getUrlVariableValue("settingsId")].formatSettings.processorMapping.columns[id].columnNumber - 1;
    } else if (direction === "down") {
      newColumnNumber =  settings[getUrlVariableValue("settingsId")].formatSettings.processorMapping.columns[id].columnNumber + 1;
    }

    let nextExceptionId = findColumn(newColumnNumber);
    if (nextExceptionId === null) {
      return;
    }

    let newMovedColumn = { ... settings[getUrlVariableValue("settingsId")].formatSettings.processorMapping.columns[id], columnNumber: newColumnNumber };
    let newReplacedColumn = {
      ... settings[getUrlVariableValue("settingsId")].formatSettings.processorMapping.columns[nextExceptionId],
      columnNumber: oldColumnNumber,
    };

    // Add newMovedColumn
    dispatch({
      type: "UPDATE_SETTINGS_PROPERTY",
      settingsId: getUrlVariableValue("settingsId"),
      uid: getUID(),
      path: `${getUrlVariableValue(
        "settingsId"
      )}.formatSettings.processorMapping.columns.${id}`,
      value: newMovedColumn,
    });
    // Add newReplacedColumn
    dispatch({
      type: "UPDATE_SETTINGS_PROPERTY",
      settingsId: getUrlVariableValue("settingsId"),
      uid: getUID(),
      path: `${getUrlVariableValue(
        "settingsId"
      )}.formatSettings.processorMapping.columns.${nextExceptionId}`,
      value: newReplacedColumn,
    });

    let newRev = new RevertList(changes.getValues());
    newRev.addElement(
      "column-" + id + "-columnNumber",
      oldColumnNumber,
      newColumnNumber
    );
    newRev.addElement(
      "column-" + nextExceptionId + "-columnNumber",
      newColumnNumber,
      oldColumnNumber
    );
    setChanges(newRev);
  };

  function findColumn(columnNumber) {
    let values = Object.values(
      settings[getUrlVariableValue("settingsId")].formatSettings.processorMapping.columns
    );
    for (let i = 0; i < values.length; i++) {
      if (values[i].columnNumber === columnNumber) {
        return values[i].id;
      }
    }
    return null;
  }

  function findExcelRow(n) {
    var ordA = "a".charCodeAt(0);
    var ordZ = "z".charCodeAt(0);
    var len = ordZ - ordA + 1;

    var s = "";
    while (n >= 0) {
      s = String.fromCharCode((n % len) + ordA) + s;
      n = Math.floor(n / len) - 1;
    }
    return s.toUpperCase();
  }

  function compareColumnIndex(a, b) {
    const [firstId, firstObj] = a;
    const [secondId, secondObj] = b;
    let firstIndex = firstObj.columnNumber;
    let secondIndex = secondObj.columnNumber;

    return firstIndex - secondIndex;
  }

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    bgcolor: "background.paper",

    boxShadow: 24,
    p: 4,

    minHeight: 400,
  };

  function getSubheader(column) {
    let columnType = column.name.replace("text~", "");
    let value = "";

    if (columnType === "Constant Value") {
      let constantEmpty = column.constant.empty;
      if (constantEmpty) {
        value = "(Blank)";
      } else {
        value = column.constant.value.replace("text~", "");
      }
    } else if (columnType === "Staff Attribute") {
      value = column.staff.attribute;
    } else if (columnType === "Excel Attribute") {
      value = "...";
    } else {
      return "";
    }
    return columnType + ": " + value;
  }

  return (settings.hasOwnProperty("1") && settings.hasOwnProperty("staff"))  ? (
    <Box className="settings-page">
    <Typography variant="h2" className="settings-page__title">
      Mapping
    </Typography>
    <Typography variant="p" className="settings-page__title-description">
        {/* <Link href=""><VideocamIcon/>Tutorial</Link> */}
        {`Configure a custom report to match your payroll processor import template, or let us create a custom one for you.`}
      </Typography>
    <Divider className="settings-page__divider" />
    <Stack
      direction="column"
      spacing={2}
      justifyContent="flex-start"
      alignItems="flex-start"
    >
      <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
        <Typography variant="h5">Payroll Processor:</Typography>
        <SingleAttributeListBox
          freeSolo
          onChange={handleMappingChange}
          id="name"
          default={
            settings[getUrlVariableValue("settingsId")].formatSettings.processorMapping
              .name
          }
          size={200}
          options={["Gusto", "Paychex", "ADP"]}
        ></SingleAttributeListBox>
      </div>

      <Stack
        direction="row"
        spacing={1}
        justifyContent="flex-start"
        alignItems="center"
      >
        <Typography variant="h5" className="settings-page__text">
          Advanced Processor Settings
        </Typography>
        <Box className="settings-page__box settings-page__box--inline">
          <BasicModal openIcon={<EditRoundedIcon />}>
            {<AdvancedProcessorSettings></AdvancedProcessorSettings>}
          </BasicModal>
        </Box>

      </Stack>

      <Stack
        sx={{ width: 1 }}
        spacing={1}
        key={"column-type-stack"}
        direction="column"
        marginTop={"30px"}
      >
        <Typography variant="h4">Column Mapping</Typography>
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "flex-end",
          }}
        >
          <Button startIcon={<AddIcon />} onClick={handleAddException}>
            Add Column
          </Button>
        </div>

        {Object.entries( settings[getUrlVariableValue("settingsId")].formatSettings.processorMapping.columns)
          .sort(compareColumnIndex)
          .map(([key, entry], index) => (
            <Card key={"column-type" + entry.id}>
              <CardHeader
                key={"column-header" + entry.id}
                avatar={
                  <Avatar
                    key={"column-avatar" + entry.id}
                    sx={{ bgcolor: "#008000" }}
                  >
                    {findExcelRow(entry.columnNumber - 1)}
                  </Avatar>
                }
                action={
                  <Box spacing={2}>
                    <IconButton
                      aria-label="settings"
                      key={"column-type-copy" + index}
                      onClick={() => {
                        handleCopyException(entry.id);
                      }}
                      size="large"
                    >
                      <ContentCopyIcon />
                    </IconButton>
                    <IconButton
                      aria-label="settings"
                      key={"column-type-up" + index}
                      onClick={() => {
                        handleMoveException("up", entry.id);
                      }}
                      size="large"
                    >
                      <NorthIcon />
                    </IconButton>
                    <IconButton
                      aria-label="settings"
                      key={"column-type-down" + index}
                      onClick={() => {
                        handleMoveException("down", entry.id);
                      }}
                      size="large"
                    >
                      <SouthIcon />
                    </IconButton>
                    <IconButton
                      aria-label="settings"
                      key={"column-type-edit" + index}
                      onClick={() => {
                        handleEditException(entry.id);
                      }}
                      size="large"
                    >
                      <EditRoundedIcon sx={{ color: "#1976d2" }} />
                    </IconButton>

                    <IconButton
                      aria-label="settings"
                      key={"column-type-delete" + index}
                      onClick={() => {
                        handleDeleteException(entry.id);
                      }}
                      size="large"
                    >
                      <DeleteIcon sx={{ color: "red" }} />
                    </IconButton>
                  </Box>
                }
                title={
                  <Typography
                    gutterBottom
                    key={"column-title" + entry.id}
                    component="div"
                  >
                    {entry.name.replace("text~", "")}
                  </Typography>
                }
                subheader={getSubheader(entry.columnType)}
              />
            </Card>
          ))}
      </Stack>

      <Modal open={modalColumn !== null} onClose={handleModalClose}>
        <Box
          sx={style}
          style={{
            width: "86%",
            height: "86%",
            overflowY: "scroll",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              marginBottom: "30px",
            }}
          >
            <h3>Edit Column</h3>
            <IconButton
              key={"class-type-close" + modalColumn}
              display="flex"
              justifyContent="flex-end"
              alignItems="flex-end"
              onClick={handleModalClose}
              size="large"
            >
              <CloseIcon sx={{ color: "red" }} />
            </IconButton>
          </Box>

          <ProcessorMappingColumn
            key={modalColumn}
            nameChange={handleHeaderChange}
            id={modalColumn}
            excelType={
              settings[getUrlVariableValue("settingsId")].formatSettings
                .processorMapping.paywellTab
            }
          ></ProcessorMappingColumn>
        </Box>
      </Modal>
    </Stack>
    <Divider className="settings-page__divider" />
  </Box>
  ) : (
    <Box className="settings-page">
      <Skeleton>
        <Typography variant="h2" className="settings-page__title">
          Studios
        </Typography>
      </Skeleton>
      <Divider className="settings-page__divider" />
      <Skeleton className="settings-page__attribute-box settings-page__attribute-box--skeleton"></Skeleton>
    </Box>
  );
}

function AdvancedProcessorSettings() {
  const { settings, dispatch, changes, setChanges } = useSettingsContext();
  
  const { currentUser, getUID } = useAuth();

  const handleMappingChange = (value, id) => {
    let newRev = new RevertList(changes.getValues());
    newRev.addElement(
      "processor-" + id,
      settings[getUrlVariableValue("settingsId")].formatSettings.processorMapping[id],
      value
    );
    setChanges(newRev);

    // Update Mapping Change
    dispatch({
      type: "UPDATE_SETTINGS_PROPERTY",
      settingsId: getUrlVariableValue("settingsId"),
      uid: getUID(),
      path: `${getUrlVariableValue("settingsId")}.formatSettings.processorMapping.${id}`,
      value: value,
    });
  };

  const createColumnArray = (indicator) => {
    let columns =
      settings[getUrlVariableValue("settingsId")].formatSettings.processorMapping.columns;
    let colNameArray = ["NONE"];
    Object.values(columns).map((column) => {
      let type = column.columnType.name;
      if (type === "Constant Value" || type === "Staff Attribute") {
        colNameArray.push(
          indicator +
            column.name.substring(column.name.indexOf("~") + 1) +
            indicator
        );
      }
    });
    return colNameArray;
  };

  return (
    <Stack>
      <Typography>Value Replacements </Typography>
      <ReplacementList></ReplacementList>

      <Divider className="settings-page__divider" />

      <Box>
        <Typography>Delete Empty Rows: </Typography>
        <BooleanToggleElement
          onChange={handleMappingChange}
          default={
            settings[getUrlVariableValue("settingsId")].formatSettings.processorMapping
              .deleteEmptyRows
          }
          id="deleteEmptyRows"
        ></BooleanToggleElement>
      </Box>

      <Divider className="settings-page__divider" />

      <Box>
        <Typography>Breakout by Location: </Typography>
        <BooleanToggleElement
          onChange={handleMappingChange}
          default={
            settings[getUrlVariableValue("settingsId")].formatSettings.processorMapping?.locationSheetBreakout || false
          }
          id="locationSheetBreakout"
        ></BooleanToggleElement>
      </Box>

      <Divider className="settings-page__divider" />

      <Box>
        <Typography>Column Breakout by Name: </Typography>
        <SelectorBox
        id="columnBreakoutName"
        default={
          settings[getUrlVariableValue("settingsId")].formatSettings.processorMapping?.columnBreakoutName || null
        }
        onChange={handleMappingChange}
        size={200}
        label="Column"
        options={[null].concat(createColumnArray(""))}
      ></SelectorBox>
      </Box>

      <Divider className="settings-page__divider" />

      <Box>
        <Typography>Header: </Typography>
        <InputBoxElement
          id="header"
          onChange={handleMappingChange}
          default={
            settings[getUrlVariableValue("settingsId")].formatSettings.processorMapping
              .header
          }
          types={["text"]}
          size={100}
          label="Header"
        ></InputBoxElement>
      </Box>

      <Divider className="settings-page__divider" />

      <Box>
        <Typography>Pay Components: </Typography>
        <AttributeListBox
          multiple={true}
          size={300}
          freeSolo
          onChange={handleMappingChange}
          id="payComponents"
          default={
            settings[getUrlVariableValue("settingsId")].formatSettings.processorMapping
              .payComponents
          }
          label="Components"
          options={[]}
        ></AttributeListBox>
      </Box>
    </Stack>
  );
}

function ReplacementList(props) {
  const { settings, dispatch, changes, setChanges } = useSettingsContext();
  
  const { currentUser, getUID } = useAuth();

  const handleMappingChange = (value, id) => {
    let newRev = new RevertList(changes.getValues());
    newRev.addElement(
      "processor-" + id,
      settings[getUrlVariableValue("settingsId")].formatSettings.processorMapping[id],
      value
    );
    setChanges(newRev);

    // Update Mapping Change
    //  settings[getUrlVariableValue("settingsId")].formatSettings.processorMapping.columns
    dispatch({
      type: "UPDATE_SETTINGS_PROPERTY",
      settingsId: getUrlVariableValue("settingsId"),
      uid: getUID(),
      path: `${getUrlVariableValue("settingsId")}.formatSettings.processorMapping.${id}`,
      value: value,
    });
  };

  function EditToolbar(props) {
    const { setRowModesModel } = props;

    const handleClick = () => {
      const id = randomId();
      // setRows((oldRows) => [
      //   ...oldRows,
      //   { id: id, value: "", replacement: "", isNew: true },
      // ]);
      // Add New Replacement
      dispatch({
        type: "UPDATE_SETTINGS_PROPERTY",
        settingsId: getUrlVariableValue("settingsId"),
        uid: getUID(),
        path: `${getUrlVariableValue(
          "settingsId"
        )}.formatSettings.processorMapping.replacements`,
        value: [
          ...settings[getUrlVariableValue("settingsId")].formatSettings.processorMapping
            .replacements,
          { id: id, value: "", replacement: "", isNew: true },
        ],
      });
      setRowModesModel((oldModel) => ({
        ...oldModel,
        [id]: { mode: GridRowModes.Edit, fieldToFocus: "value" },
      }));
    };

    return (
      <GridToolbarContainer>
        <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
          Add Replacement
        </Button>
      </GridToolbarContainer>
    );
  }

  EditToolbar.propTypes = {
    setRowModesModel: PropTypes.func.isRequired,
  };
  // const [rows, setRows] = React.useState(
  //   studioSettings.formatSettings.processorMapping.replacements
  // );
  const rows =
    settings[getUrlVariableValue("settingsId")].formatSettings.processorMapping
      .replacements;

  const [rowModesModel, setRowModesModel] = React.useState({});

  const handleRowEditStart = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id) => () => {
    let newRows = rows.filter((row) => row.id !== id);
    // setRows(newRows);
    // Set All Replacements
    dispatch({
      type: "UPDATE_SETTINGS_PROPERTY",
      settingsId: getUrlVariableValue("settingsId"),
      uid: getUID(),
      path: `${getUrlVariableValue(
        "settingsId"
      )}.formatSettings.processorMapping.replacements`,
      value: newRows,
    });
    handleMappingChange(newRows, "replacements");
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      // setRows(row.filter((row = row.id !== id)));

      // Set All Replacements
      dispatch({
        type: "UPDATE_SETTINGS_PROPERTY",
        settingsId: getUrlVariableValue("settingsId"),
        uid: getUID(),
        path: `${getUrlVariableValue(
          "settingsId"
        )}.formatSettings.processorMapping.replacements`,
        value: rows.filter((row) => row.id !== id),
      });
    }
    handleMappingChange(rows, "replacements");
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    let newRows = rows.map((row) => (row.id === newRow.id ? updatedRow : row));
    // setRows(newRows);
    // Set All Replacements
    dispatch({
      type: "UPDATE_SETTINGS_PROPERTY",
      settingsId: getUrlVariableValue("settingsId"),
      uid: getUID(),
      path: `${getUrlVariableValue(
        "settingsId"
      )}.formatSettings.processorMapping.replacements`,
      value: newRows,
    });

    handleMappingChange(newRows, "replacements");

    return updatedRow;
  };

  const columns = [
    { field: "value", headerName: "Value", width: 320, editable: true },
    {
      field: "replacement",
      headerName: "Replacement",
      width: 320,
      editable: true,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    <Box
      sx={{
        height: 500,
        width: "100%",
        "& .actions": {
          color: "text.secondary",
        },
        "& .textPrimary": {
          color: "text.primary",
        },
      }}
    >
      <DataGridPro
        rows={rows}
        columns={columns}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
        onRowEditStart={handleRowEditStart}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        components={{
          Toolbar: EditToolbar,
        }}
        componentsProps={{
          toolbar: { setRowModesModel },
        }}
        experimentalFeatures={{ newEditingApi: true }}
      />
    </Box>
  );
}

function ProcessorMappingColumn(props) {
  const { settings, dispatch, changes, setChanges } = useSettingsContext();
  
  const { currentUser, getUID } = useAuth();

  const values =
    settings[getUrlVariableValue("settingsId")].formatSettings.processorMapping.columns[
      props.id
    ];

  const handleTypeChange = (event) => {
    let value = event.target.value;

    let newRev = new RevertList(changes.getValues());
    newRev.addElement(
      values.id + "-type",
      settings[getUrlVariableValue("settingsId")].formatSettings.processorMapping.columns[
        values.id
      ].columnType.name,
      value
    );
    setChanges(newRev);

    // Update Column Type Name
    dispatch({
      type: "UPDATE_SETTINGS_PROPERTY",
      settingsId: getUrlVariableValue("settingsId"),
      uid: getUID(),
      path: `${getUrlVariableValue(
        "settingsId"
      )}.formatSettings.processorMapping.columns.${values.id}.columnType.name`,
      value: value,
    });
  };

  const handleNameChange = (value, id) => {
    let newColumn = { ...values, ["name"]: value };

    let newRev = new RevertList(changes.getValues());
    newRev.addElement(
      values.id + "-name",
      settings[getUrlVariableValue("settingsId")].formatSettings.processorMapping.columns[
        values.id
      ].name,
      value
    );
    setChanges(newRev);

    // Update Column Name
    dispatch({
      type: "UPDATE_SETTINGS_PROPERTY",
      settingsId: getUrlVariableValue("settingsId"),
      uid: getUID(),
      path: `${getUrlVariableValue(
        "settingsId"
      )}.formatSettings.processorMapping.columns.${values.id}.name`,
      value: value,
    });

    if (props.nameChange) {
      props.nameChange(values.id, value);
    }
  };

  function selectAttribute(columnType, excelType) {
    if (columnType === "Constant Value") {
      return (
        <ConstantValue
          key={"constant" + values.id}
          parentId={values.id}
        ></ConstantValue>
      );
    } else if (columnType === "Staff Attribute") {
      return (
        <StaffAttribute
          key={"staff" + values.id}
          parentId={values.id}
        ></StaffAttribute>
      );
    } else if (columnType === "Excel Attribute") {
      return (
        <ExcelAttributeList
          key={"excel" + values.id}
          parentId={values.id}
          type={excelType}
        ></ExcelAttributeList>
      );
    }
  }

  return (
    <Box
      key={"listbox" + values.id}
      sx={{ width: 1 }}
      style={{ display: "flex", flexDirection: "column", rowGap: "20px" }}
      spacing={2}
    >
      <Stack
        direction="row"
        spacing={2}
        justifyContent="flex-start"
        alignItems="center"
        style={{ marginBottom: "20px" }}
      >
        <Typography>Column Name: </Typography>
        <InputBoxElement
          key={"name" + values.id}
          onChange={handleNameChange}
          id="name"
          default={values.name}
          types={["text"]}
          question="Label question here?"
          size={140}
          label="Label"
          style={{ height: "fit-content" }}
        ></InputBoxElement>
      </Stack>
      <ToggleButtonGroup
        sx={{ width: 1, display: "flex", justifyContent: "flex-start" }}
        onChange={handleTypeChange}
        color="primary"
        value={values.columnType.name}
        // style={{ marginBottom: "20px" }}
        exclusive
      >
        (
        <ToggleButton key="Constant Value" value="Constant Value">
          Constant Value
        </ToggleButton>
        ) (
        <ToggleButton key="Staff Attribute" value="Staff Attribute">
          Staff Attribute
        </ToggleButton>
        ) (
        <ToggleButton key="Excel Attribute" value="Excel Attribute">
          Excel Attribute
        </ToggleButton>
        )
      </ToggleButtonGroup>

      {selectAttribute(values.columnType.name, props.excelType)}
    </Box>
  );
}

function ConstantValue(props) {
  const { settings, dispatch, changes, setChanges } = useSettingsContext();
  
  const { currentUser, getUID } = useAuth();

  var constantValues =
    settings[getUrlVariableValue("settingsId")].formatSettings.processorMapping.columns[
      props.parentId
    ].columnType.constant;

  const handleChange = (value, id) => {
    // setConstantValues({ ...constantValues, [id]: value });

    let newRev = new RevertList(changes.getValues());
    newRev.addElement(
      props.parentId + "-" + id,
      settings[getUrlVariableValue("settingsId")].formatSettings.processorMapping.columns[
        props.parentId
      ].columnType.constant[id],
      value
    );
    setChanges(newRev);

    // Update Column Type Constant
    //  settings[getUrlVariableValue("settingsId")].formatSettings.processorMapping.columns
    dispatch({
      type: "UPDATE_SETTINGS_PROPERTY",
      settingsId: getUrlVariableValue("settingsId"),
      uid: getUID(),
      path: `${getUrlVariableValue(
        "settingsId"
      )}.formatSettings.processorMapping.columns.${
        props.parentId
      }.columnType.constant.${id}`,
      value: value,
    });
  };

  return (
    <Box style={{ display: "flex", alignItems: "center", gap: "4px" }}>
      <Typography>Empty</Typography>
      <BooleanToggleElement
        default={constantValues.empty}
        id="empty"
        onChange={handleChange}
      ></BooleanToggleElement>
      {!constantValues.empty ? (
        <ValueInput
          id={props.parentId}
          value={constantValues.value}
        ></ValueInput>
      ) : null}
    </Box>
  );
}

function ValueInput(props) {
  const { settings, dispatch, changes, setChanges } = useSettingsContext();
  
  const { currentUser, getUID } = useAuth();

  const handleChange = (value, id) => {
    let newRev = new RevertList(changes.getValues());
    newRev.addElement(
      id,
      settings[getUrlVariableValue("settingsId")].formatSettings.processorMapping.columns[
        props.id
      ].columnType.constant[id],
      value
    );
    setChanges(newRev);

    // Update Column Type Constant
    dispatch({
      type: "UPDATE_SETTINGS_PROPERTY",
      settingsId: getUrlVariableValue("settingsId"),
      uid: getUID(),
      path: `${getUrlVariableValue(
        "settingsId"
      )}.formatSettings.processorMapping.columns.${
        props.id
      }.columnType.constant.${id}`,
      value: value,
    });
  };

  return (
    <Box style={{ display: "flex", alignItems: "center", gap: "4px" }}>
      <Typography>Value</Typography>
      <InputBoxElement
        id="value"
        default={props.value}
        onChange={handleChange}
        types={["text"]}
        size={200}
        label="Value"
      ></InputBoxElement>
    </Box>
  );
}

function StaffAttribute(props) {
  const { settings, dispatch, changes, setChanges } = useSettingsContext();
  
  const { currentUser, getUID } = useAuth();

  // const [staffValues, setStaffValues] = useState(
  //   studioSettings.formatSettings.processorMapping.columns[props.parentId]
  //     .columnType.staff
  // );
  const staffValues =
    settings[getUrlVariableValue("settingsId")].formatSettings.processorMapping.columns[
      props.parentId
    ].columnType.staff;

  const handleChange = (value, id) => {
    // setStaffValues({ ...staffValues, [id]: value });

    let newRev = new RevertList(changes.getValues());
    newRev.addElement(
      props.parentId + "-" + id,
      settings[getUrlVariableValue("settingsId")].formatSettings.processorMapping.columns[
        props.parentId
      ].columnType.staff[id],
      value
    );
    setChanges(newRev);

    // Update Column Type Staff
    dispatch({
      type: "UPDATE_SETTINGS_PROPERTY",
      settingsId: getUrlVariableValue("settingsId"),
      uid: getUID(),
      path: `${getUrlVariableValue(
        "settingsId"
      )}.formatSettings.processorMapping.columns.${
        props.parentId
      }.columnType.staff.${id}`,
      value: value,
    });
  };

  return (
    <Box style={{ display: "flex" }}>
      <Typography style={{ display: "flex", alignItems: "center" }}>
        Staff Attribute
      </Typography>
      <SelectorBox
        id="attribute"
        default={settings[getUrlVariableValue("settingsId")].formatSettings.processorMapping.columns[
          props.parentId
        ].columnType.staff.attribute}
        onChange={handleChange}
        size={200}
        label="Attribute"
        options={[
          "Last Name",
          "First Name",
          "Full Name",
          "ID",
          "Secondary ID",
          "Email",
          "Type",
          "Titles",
          "Locations",
          "Home Location",
          "Alternate Names",
          "*Pay Component*",
          "Pay Period Start Date",
          "Pay Period End Date"
        ]}
      ></SelectorBox>
      {isDuplicateAttribute(settings[getUrlVariableValue("settingsId")].formatSettings.processorMapping.columns[
      props.parentId
    ].columnType.staff.attribute) ? (
        <Box>
          <Typography>Duplicate Element</Typography>
          <BooleanToggleElement
            id="duplicate"
            default={settings[getUrlVariableValue("settingsId")].formatSettings.processorMapping.columns[
              props.parentId
            ].columnType.staff.duplicate}
            onChange={handleChange}
          ></BooleanToggleElement>
          <Typography></Typography>
          {settings[getUrlVariableValue("settingsId")].formatSettings.processorMapping.columns[
      props.parentId
    ].columnType.staff.duplicate ? (
            <DuplicatePrimaryString
              id={props.parentId}
              value={settings[getUrlVariableValue("settingsId")].formatSettings.processorMapping.columns[
                props.parentId
              ].columnType.staff.primary}
            ></DuplicatePrimaryString>
          ) : null}{" "}
        </Box>
      ) : null}
    </Box>
  );
}

function isDuplicateAttribute(attribute) {
  if (
    attribute === "Titles" ||
    attribute === "Locations" ||
    attribute === "Alternate Names"
  ) {
    return true;
  }
  return false;
}

function DuplicatePrimaryString(props) {
  const { settings, dispatch, changes, setChanges } = useSettingsContext();
  
  const { currentUser, getUID } = useAuth();

  const handleChange = (value, id) => {
    let newRev = new RevertList(changes.getValues());
    newRev.addElement(
      props.id + "-" + id,
      settings[getUrlVariableValue("settingsId")].formatSettings.processorMapping.columns[
        props.id
      ].columnType.staff[id],
      value
    );
    setChanges(newRev);

    // Update Column Type Staff
    dispatch({
      type: "UPDATE_SETTINGS_PROPERTY",
      settingsId: getUrlVariableValue("settingsId"),
      uid: getUID(),
      path: `${getUrlVariableValue(
        "settingsId"
      )}.formatSettings.processorMapping.columns.${
        props.id
      }.columnType.staff.${id}`,
      value: value,
    });
  };

  return (
    <Box>
      <Typography>Primary Default String</Typography>
      <InputBoxElement
        id="primary"
        onChange={handleChange}
        default={props.value}
        types={["text"]}
        size={200}
        label="Value"
      ></InputBoxElement>
    </Box>
  );
}

function ExcelAttributeList(props) {
  const { settings, dispatch, changes, setChanges } = useSettingsContext();
  
  const { currentUser, getUID } = useAuth();

  var excelValues =
    settings[getUrlVariableValue("settingsId")].formatSettings.processorMapping.columns[
      props.parentId
    ].columnType.excel;

  const handleAddException = (event) => {
    let id = uuidv4();

    let newRev = new RevertList(changes.getValues());
    newRev.addElement(id, "n/a", "added");
    setChanges(newRev);

    const defaultExcelException = {
      primaryLookupColumn: "A",
      primaryValues: [],

      secondary: true,
      secondaryLookupColumn: "B",
      secondaryValues: [],

      valueColumn: "",

      rowAttribute: false,
      rowColumnName: "text~",
      rowColumnValue: "text~",

      secondRowColumnName: "",
      secondRowColumnValue: "text~",
      id: id,
    };

    // Update Column Type Excel
    dispatch({
      type: "UPDATE_SETTINGS_PROPERTY",
      settingsId: getUrlVariableValue("settingsId"),
      uid: getUID(),
      path: `${getUrlVariableValue(
        "settingsId"
      )}.formatSettings.processorMapping.columns.${
        props.parentId
      }.columnType.excel.${id}`,
      value: defaultExcelException,
    });
  };

  const handleDeleteException = (id) => {
    let newRev = new RevertList(changes.getValues());
    newRev.addElement(id, "n/a", "delete");
    setChanges(newRev);

    let exceptionList =
      settings[getUrlVariableValue("settingsId")].formatSettings.processorMapping.columns[
        props.parentId
      ].columnType.excel;

    delete exceptionList[id];

    // Replace Column Type Excel with exceptionnList without deleted exception
    dispatch({
      type: "UPDATE_SETTINGS_PROPERTY",
      settingsId: getUrlVariableValue("settingsId"),
      uid: getUID(),
      path: `${getUrlVariableValue(
        "settingsId"
      )}.formatSettings.processorMapping.columns.${
        props.parentId
      }.columnType.excel`,
      value: exceptionList,
    });
  };

  const handleDuplicateException = (id) => {
    let newId = uuidv4();

    let newRev = new RevertList(changes.getValues());
    newRev.addElement(newId, "n/a", "duplicate");
    setChanges(newRev);

    let exceptionList =
      settings[getUrlVariableValue("settingsId")].formatSettings.processorMapping.columns[
        props.parentId
      ].columnType.excel;

      let duplicatedException = JSON.parse(JSON.stringify(exceptionList[id]));
      duplicatedException.id = newId;

    // Update Column Type Excel
    dispatch({
      type: "UPDATE_SETTINGS_PROPERTY",
      settingsId: getUrlVariableValue("settingsId"),
      uid: getUID(),
      path: `${getUrlVariableValue(
        "settingsId"
      )}.formatSettings.processorMapping.columns.${
        props.parentId
      }.columnType.excel.${newId}`,
      value: duplicatedException,
    });

  }

  return (
    <Stack
      direction="column"
      justifyContent="flex-start"
      alignItems="flex-end"
      rowGap={"20px"}
      width={"100%"}
    >
      <Button startIcon={<AddIcon />} onClick={handleAddException}>
        Add Attribute
      </Button>

      {Object.entries(
        settings[getUrlVariableValue("settingsId")].formatSettings.processorMapping
          .columns[props.parentId].columnType.excel
      ).map(([key, entry], index) => (
        <Stack key={"excel-type-stack" + index} direction="row" width="100%">
          <Accordion key={"excelAttribute-type" + index} sx={{ width: "100%" }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Option {index + 1}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {props.type === "Staff" ? (
                <ExcelAttribute
                  key={"excelAttribute" + props.parentId + entry.id}
                  parentId={props.parentId}
                  excelId={entry.id}
                  value={entry}
                ></ExcelAttribute>
              ) : null}
            </AccordionDetails>
          </Accordion>

          <IconButton
            key={"excel-type-duplicate" + index}
            onClick={() => {
              handleDuplicateException(entry.id);
            }}
            size="large"
          >
            <ContentCopyIcon sx={{ color: "#1976d2" }}/>
          </IconButton>

          <IconButton
            key={"excel-type-delete" + index}
            onClick={() => {
              handleDeleteException(entry.id);
            }}
            size="large"
          >
            <DeleteIcon sx={{ color: "red" }} />
          </IconButton>
        </Stack>
      ))}
    </Stack>
  );
}

function ExcelAttribute(props) {
  const { settings, dispatch, changes, setChanges } = useSettingsContext();
  
  const { currentUser, getUID } = useAuth();

  var excelAttribute =
    settings[getUrlVariableValue("settingsId")].formatSettings.processorMapping.columns[
      props.parentId
    ].columnType.excel[props.excelId];

  const handleChange = (value, id) => {

    let newRev = new RevertList(changes.getValues());
    newRev.addElement(
      props.parentId + "-" + props.excelId + "-" + id,
      settings[getUrlVariableValue("settingsId")].formatSettings.processorMapping.columns[
        props.parentId
      ].columnType.excel[excelAttribute.id][id],
      value
    );
    setChanges(newRev);

    // Update Column Type Excel Attribute
    dispatch({
      type: "UPDATE_SETTINGS_PROPERTY",
      settingsId: getUrlVariableValue("settingsId"),
      uid: getUID(),
      path: `${getUrlVariableValue(
        "settingsId"
      )}.formatSettings.processorMapping.columns.${
        props.parentId
      }.columnType.excel.${excelAttribute.id}.${id}`,
      value: value,
    });
  };

  const createColumnArray = (indicator) => {
    let columns =
      settings[getUrlVariableValue("settingsId")].formatSettings.processorMapping.columns;
    let colNameArray = ["NONE"];
    Object.values(columns).map((column) => {
      let type = column.columnType.name;
      if (type === "Constant Value" || type === "Staff Attribute") {
        colNameArray.push(
          indicator +
            column.name.substring(column.name.indexOf("~") + 1) +
            indicator
        );
      }
    });
    return colNameArray;
  };

  const createProcessorValues = () => {
    let specialProcessorValues = [
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "*Count*",
      "Non-Instruction Hours",
    ];

    let buckets = Object.keys(settings[getUrlVariableValue("settingsId")].classSettings.classBuckets);
    // Get all matches instead of just the first
    let bucketBaseRates = buckets.map((session) => (session + " Class Base Amount"));
    let bucketBonusRates = buckets.map((session) => (session + " Class Bonus Amount"));

    let extraBucket = [
      "Class Base Amount",
      "Class Bonus Amount",
    ]

    let attributes = settings[getUrlVariableValue("settingsId")].generalSettings.staffFields;
    let staffAttributes = attributes.map((attribute) => "Staff Attribute: " + attribute.field);

    let otherStaffValues = buckets.map((session) => ("Staff Attribute: " + session + " Base"));

    let extraAttributes = [
      "Staff Attribute:",
      "Staff Attribute: Hourly Rate",
      "Staff Attribute: Secondary Hourly Rate",
      "Staff Attribute: Overtime Rate",
      "Staff Attribute: Double Overtime Rate",
      "Staff Attribute: Group Class Base",
      "Staff Attribute: Private Class Base",
      "Staff Attribute: Semi - Private Class Base",
      "Staff Attribute: Intro Class Base",
    ]

    let trueValues = [
      "True Class Hours",
      "True Non-Class Hours",
    ]

    let perHeadValues = buckets.map((session) => ("Per Head Bonus: " + session));
    let extraPerHead = [
      "Per Head Bonus",
      "Group Class Per Head Bonus",
    ]

    let intro = [
      "Intro Conversion",
      "Attendees",
      'Intro Conversion Percent',
    ]

    let bonusThreshold = buckets.map((session) => (session + " Bonus Threshold Attendees"));
    let extraBonusThreshold = ["Bonus Threshold Attendees"];

    let custom = ["=", "#"]

    let more = [
    "Gusto Non-Job Hours",
    "Gusto Hours",
    "Commission Proportion",
    "Commission Proportion Updated",
    "Class Bookings",
    "Unique Instances"]

    let sessionLength = ["Session Length", "Session Length [30] {Intro: 60} (Hours)"];

    let options = specialProcessorValues.concat(
      bucketBaseRates,
      bucketBonusRates,
      extraBucket,
      staffAttributes,
      otherStaffValues,
      extraAttributes,
      trueValues,
      perHeadValues,
      extraPerHead,
      intro,
      bonusThreshold,
      extraBonusThreshold,
      custom,
      more,
      sessionLength
    );

    return options;
  }

  const getBucketNames = () => {
    let bucketNames = Object.keys(settings[getUrlVariableValue("settingsId")].classSettings.classBuckets);
    let bucketAllNames = bucketNames.map((session) => ("*All " + session + "*"));
    return bucketAllNames;
  }

  const getSessionNames = () => {
    let sessionBuckets = Object.values(settings[getUrlVariableValue("settingsId")].classSettings.classBuckets);
    let sessionNames = [];
    sessionBuckets.map((session) => {
      sessionNames = sessionNames.concat(session.names);
    });
    return sessionNames;
  };

  return (
    <Stack sx={{ width: 1 }} spacing={2}>
      <Stack
        direction="row"
        spacing={1}
        justifyContent="flex-start"
        alignItems="flex-end"
        sx={{ width: 1 }}
      >
        <Typography>For each employee, if column is </Typography>
        <SelectorBox
          id="primaryLookupColumn"
          default={excelAttribute.primaryLookupColumn}
          onChange={handleChange}
          size={300}
          label="Lookup Column"
          options={["A", "B", "C", "D", "E", "F", "G"]}
        ></SelectorBox>
      </Stack>

      <Stack
        direction="row"
        spacing={1}
        justifyContent="flex-start"
        alignItems="flex-end"
        sx={{ width: 1 }}
      >
        <Typography> and value includes </Typography>
        <AttributeListBox
          multiple={true}
          size={300}
          freeSolo
          onChange={handleChange}
          id="primaryValues"
          default={excelAttribute.primaryValues}
          label="Values"
          options={[
            "*All Classes*",
            "*All Sales*",
            "*All Time*",
            "*All Agreements*",
            "*All Retail*",
            "Regular Totals",
            "Summary Totals",
            "Overtime",
            "Salary Totals",
            "Reimbursement Totals",
            "Bonus Totals",
            "Double Overtime Totals",
            "Sick Totals",
            "Vacation Totals",
          ]
            // .concat(classesExamples)
            .concat(getBucketNames())
            .concat(getSessionNames())
            .concat(createColumnArray("|"))}
        ></AttributeListBox>
      </Stack>

      <Stack
        direction="row"
        spacing={1}
        justifyContent="flex-start"
        alignItems="flex-end"
      >
        <Typography>Use Secondary Column: </Typography>
        <BooleanToggleElement
          default={excelAttribute.secondary}
          id="secondary"
          onChange={handleChange}
        ></BooleanToggleElement>
      </Stack>
      {excelAttribute.secondary ? (
        <Stack spacing={1}>
          {" "}
          <Stack
            direction="row"
            spacing={2}
            justifyContent="flex-start"
            alignItems="flex-end"
          >
            <Typography>Secondary Lookup Column: </Typography>
            <SelectorBox
              id="secondaryLookupColumn"
              default={excelAttribute.secondaryLookupColumn}
              onChange={handleChange}
              size={200}
              label="Lookup Column"
              options={["A", "B", "C", "D", "E", "F", "G"]}
            ></SelectorBox>
          </Stack>{" "}
          <Stack
            direction="row"
            spacing={1}
            justifyContent="flex-start"
            alignItems="flex-end"
          >
            <Typography>Secondary Values: </Typography>
            <AttributeListBox
              multiple={true}
              size={300}
              freeSolo
              onChange={handleChange}
              id="secondaryValues"
              default={excelAttribute.secondaryValues}
              label="Label"
              options={["*All Studios*"].concat(
                settings[
                  getUrlVariableValue("settingsId")
                ].generalSettings.studios.concat(createColumnArray("|"))
              )}
            ></AttributeListBox>
          </Stack>{" "}
        </Stack>
      ) : null}

      <Stack
        direction="row"
        spacing={1}
        justifyContent="flex-start"
        alignItems="flex-end"
      >
        <Typography>Value Column: </Typography>
        <SingleAttributeListBox
          id="valueColumn"
          freeSolo={true}
          default={excelAttribute.valueColumn}
          onChange={handleChange}
          size={200}
          label="Value Column"
          //options={specialProcessorValues}
          options={createProcessorValues()}
        ></SingleAttributeListBox>
      </Stack>

      <Typography>Include Row Attribute</Typography>
      <BooleanToggleElement
        default={excelAttribute.rowAttribute}
        id="rowAttribute"
        onChange={handleChange}
      ></BooleanToggleElement>
      {excelAttribute.rowAttribute ? (
        <ProcessorRowAttribute
          parentId={props.parentId}
          excelId={props.excelId}
          columns={createColumnArray("")}
        ></ProcessorRowAttribute>
      ) : null}
    </Stack>
  );
}

function ProcessorRowAttribute(props) {
  const { settings, dispatch, changes, setChanges } = useSettingsContext();
  
  const { currentUser, getUID } = useAuth();

  var excelAttribute =
    settings[getUrlVariableValue("settingsId")].formatSettings.processorMapping.columns[
      props.parentId
    ].columnType.excel[props.excelId];

  const handleChange = (value, id) => {
    // setExcelAttribute({ ...excelAttribute, [id]: value });

    let newRev = new RevertList(changes.getValues());
    newRev.addElement(
      props.parentId + "-" + props.excelId + "-" + id,
      settings[getUrlVariableValue("settingsId")].formatSettings.processorMapping.columns[
        props.parentId
      ].columnType.excel[excelAttribute.id][id],
      value
    );
    setChanges(newRev);

    dispatch({
      type: "UPDATE_SETTINGS_PROPERTY",
      settingsId: getUrlVariableValue("settingsId"),
      uid: getUID(),
      path: `${getUrlVariableValue(
        "settingsId"
      )}.formatSettings.processorMapping.columns.${
        props.parentId
      }.columnType.excel.${excelAttribute.id}.${id}`,
      value: value,
    });
  };

  return (
    <Box>
      <Typography>Column Name</Typography>
      <SelectorBox
        id="rowColumnName"
        default={excelAttribute.rowColumnName}
        onChange={handleChange}
        size={200}
        label="Column Name"
        options={props.columns}
      ></SelectorBox>
      <Typography>Column Value</Typography>
      <InputBoxElement
        id="rowColumnValue"
        default={excelAttribute.rowColumnValue}
        onChange={handleChange}
        types={["text"]}
        size={200}
        label="Column Value"
      ></InputBoxElement>

      <Typography>Second Column Name</Typography>
      <SelectorBox
        id="secondRowColumnName"
        default={excelAttribute.secondRowColumnName}
        onChange={handleChange}
        size={200}
        label="Second Column Name"
        options={props.columns}
      ></SelectorBox>
      <Typography>Second Column Value</Typography>
      <InputBoxElement
        id="secondRowColumnValue"
        default={excelAttribute.secondRowColumnValue}
        onChange={handleChange}
        types={["text"]}
        size={200}
        label="Second Column Value"
      ></InputBoxElement>
    </Box>
  );
}
