import { Divider } from "@mui/material";
import { Box } from "@mui/system";
import './SettingsFormContainer.scss';

/**
 * Recursively inserts `<Divider>` components between elements of the provided children.
 *
 * @param {React.ReactNode[] | React.ReactNode} children - An array of React nodes to be processed. Can contain nested arrays of nodes.
 * @returns {React.ReactNode[]} A new array of React nodes with `<Divider>` components inserted between elements.
 */
function insertDividers(children) {
  if (!children) return [];
  if (!Array.isArray(children)) return [children];
  return children.filter(curr => !!curr).reduce((accum, curr, index, arr) => {
    if (Array.isArray(curr)) {
      return accum.concat(insertDividers(curr))
    } else if (index === arr.length - 1) {
      return accum.concat([curr])
    } else {
      return accum.concat([curr,
        <Divider className="settings-page__divider settings-page__divider--light" />])
    }
  }, [])
}

/**
 * A container component that wraps its children and inserts dividers between them.
 *
 * @component
 * @param {object} props - The component props.
 * @param {React.ReactNode} props.children - The child elements to be displayed inside the container. 
 * Dividers will be added between these elements.
 * @returns {JSX.Element} A container element with the given children and dividers inserted between them.
 */
export default function SettingsFormContainer({ children, style }) {
  return !!style
    ? <Box className="SettingsFormContainer" sx={{marginTop: '15px'}}>
      {insertDividers(children)}
    </Box>
    : <Box className="SettingsFormContainer">
      {insertDividers(children)}
    </Box>
}
