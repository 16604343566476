import { Typography } from "@mui/material";
import BooleanToggleElement from "../../Components/BooleanToggle";
import SelectorBox from "../../Components/SelectorBox/SelectorBox";
import MultipleSelectCheckmarks from "../../../components/MultipleSelectCheckbox/MultipleSelectCheckbox";
import { Stack, Box } from "@mui/material";
import { Divider } from "@mui/material";
import ClassTotalOptions from "./ClassTotalOptions";
import ClassesHoursFlag from "./ClassesHoursFlag";
import { useSettingsContext } from "../../../contexts/SettingsContext";
import "./formatForm.scss";
import { useState } from "react";
import { RevertList } from "../../Components/RevertList";
import { useAuth } from "../../../contexts/AuthContext";
import { getUrlVariableValue } from "../../../utility-functions/utility-functions.js";
import {  Skeleton } from "@mui/material";
import SettingsFormContainer from "../../../components/SettingsForm/SettingsFormContainer.jsx";
import SettingsFormInput from "../../../components/SettingsForm/SettingsFormInput.jsx";
import { settingIds, settingTooltips } from "../CommonVariables";
import useAutoScrollToHash from "../useAutoScrollToHash";

export default function FormatForm(props) {
  const { getUID } = useAuth();
  const { settings, dispatch, changes, setChanges } = useSettingsContext();

  useAutoScrollToHash();

  const [
    classesHoursFlagToggleContent,
    setClassesHoursFlagToggleContent,
  ] = useState();
  function handleChange(value, id) {
    let newRev = new RevertList(changes.getValues());
    newRev.addElement(
      id,
      settings[getUrlVariableValue("settingsId")].formatSettings.general[id],
      value
    );
    setChanges(newRev);

    dispatch({
      type: "UPDATE_SETTINGS_PROPERTY",
      settingsId: getUrlVariableValue("settingsId"),
      uid: getUID(),
      path: `${getUrlVariableValue("settingsId")}.formatSettings.general.${id}`,
      value: value,
    });
  }
  const modifier = (className, value) => `${className}${!value ? "--off" : ""}`;

  return (settings.hasOwnProperty("1") && settings.hasOwnProperty("staff"))  ? (
    <Stack className="settings-page" spacing={1}>
    <Typography variant="h2" className="settings-page__title">
      Format
    </Typography>
    <Typography variant="p" className="settings-page__title-description">
        {/* <Link href=""><VideocamIcon/>Tutorial</Link> */}
        {`Adjust how you'd like your output XLSX files to be formatted.`}
      </Typography>
    <Divider className="settings-page__divider" />

    <SettingsFormContainer>

      <SettingsFormInput 
        label="Organize staff by last name:"
        grayOut={!settings[getUrlVariableValue("settingsId")].formatSettings.general.organizeStaffLastName}
        inputElement={
          <BooleanToggleElement
            onChange={handleChange}
            id="organizeStaffLastName"
            default={
              settings[getUrlVariableValue("settingsId")].formatSettings.general
                .organizeStaffLastName
            }
          ></BooleanToggleElement>
        }
        tooltip={settingTooltips?.formatSettings?.general?.organizeStaffLastName}
        id={settingIds?.formatSettings?.general?.organizeStaffLastName}
      />

      <SettingsFormInput 
        label={"Include a 'Cost-Per-" + settings[getUrlVariableValue("settingsId")].generalSettings.sessionName + "' metric in the summary tab:"}
        grayOut={!settings[getUrlVariableValue("settingsId")].formatSettings.general.displayCostPerClass}
        inputElement={
          <BooleanToggleElement
            onChange={handleChange}
            id="displayCostPerClass"
            default={
              settings[getUrlVariableValue("settingsId")].formatSettings.general
                .displayCostPerClass
            }
          />
        }
        tooltip={settingTooltips?.formatSettings?.general?.displayCostPerClass}
        id={settingIds?.formatSettings?.general?.displayCostPerClass}
      />

      <SettingsFormInput 
        label={"In Staff Tabs, include " + settings[getUrlVariableValue("settingsId")].generalSettings.commissionName +" as a:"}
        grayOut={
          settings[getUrlVariableValue("settingsId")].formatSettings.general
            .staffCommissionFormat === "DO NOT INCLUDE"
        }
        inputElement={
          <SelectorBox
            id="staffCommissionFormat"
            onChange={handleChange}
            default={
              settings[getUrlVariableValue("settingsId")].formatSettings.general
                .staffCommissionFormat
            }
            size={200}
            options={[
              "DO NOT INCLUDE",
              "Single Summary Line",
              "Detailed Breakdown",
            ]}
            label="Staff Tab Commission Format"
            name="Staff Tab Commission Format"
          ></SelectorBox>
        }
        tooltip={settingTooltips?.formatSettings?.general?.staffCommissionFormat}
        id={settingIds?.formatSettings?.general?.staffCommissionFormat}
      />

      <SettingsFormInput 
        label="Include Pay-Type totals in Staff Tabs:"
        grayOut={
          !settings[getUrlVariableValue("settingsId")].formatSettings.general
          .staffPayTypeTotals
        }
        inputElement={
          <BooleanToggleElement
            id="staffPayTypeTotals"
            onChange={handleChange}
            default={
              settings[getUrlVariableValue("settingsId")].formatSettings.general
                .staffPayTypeTotals
            }
          ></BooleanToggleElement>
        }
        tooltip={settingTooltips?.formatSettings?.general?.staffPayTypeTotals}
        id={settingIds?.formatSettings?.general?.staffPayTypeTotals}
      />

      <SettingsFormInput 
        label={"Include " + settings[getUrlVariableValue("settingsId")].generalSettings.locationName + " totals in Staff Tabs:"}
        grayOut={
          !settings[getUrlVariableValue("settingsId")].formatSettings.general
            .staffStudioTotals
        }
        inputElement={
          <BooleanToggleElement
            id="staffStudioTotals"
            onChange={handleChange}
            default={
              settings[getUrlVariableValue("settingsId")].formatSettings.general
                .staffStudioTotals
            }
          ></BooleanToggleElement>
        }
        tooltip={settingTooltips?.formatSettings?.general?.staffStudioTotals}
        id={settingIds?.formatSettings?.general?.staffStudioTotals}
      />

      <SettingsFormInput 
        label="Include only 'non-zero' commissionable items in Staff Tabs:"
        grayOut={
          !settings[getUrlVariableValue("settingsId")].formatSettings.general
            .staffNonZeroCommission
        }
        inputElement={
          <BooleanToggleElement
            id="staffNonZeroCommission"
            onChange={handleChange}
            default={
              settings[getUrlVariableValue("settingsId")].formatSettings.general
                .staffNonZeroCommission
            }
          ></BooleanToggleElement>
        }
        tooltip={settingTooltips?.formatSettings?.general?.staffNonZeroCommission}
        id={settingIds?.formatSettings?.general?.staffNonZeroCommission}
      />

      {/*This element can't be converted to a SettingsFormInput because of the special dropdown behavior*/}
      <Box
        className={`settings-page__box settings-page__box--grid-lg  ${modifier(
          "settings-page__box",
          settings[getUrlVariableValue("settingsId")].formatSettings.general
            .classCountVsHourFlag.valid
        )}`}
      >
        <ClassesHoursFlag
          description={` Include a flag marking any ${settings[getUrlVariableValue("settingsId")].generalSettings.specialistName} with dissimilar ${settings[getUrlVariableValue("settingsId")].generalSettings.sessionName} counts
          and ${settings[getUrlVariableValue("settingsId")].generalSettings.timeName} clocked in in the summary tab:`}
          tooltip={settingTooltips?.formatSettings?.general?.classCountVsHourFlag}
          id={settingIds?.formatSettings?.general?.classCountVsHourFlag}
        ></ClassesHoursFlag>
      </Box>

      <SettingsFormInput 
        label="Display Schedule Breakdown Tab (BETA):"
        grayOut={
          !settings[getUrlVariableValue("settingsId")].formatSettings.general?.displayScheduleTab
        }
        inputElement={
          <BooleanToggleElement
            id="displayScheduleTab"
            onChange={handleChange}
            default={
              settings[getUrlVariableValue("settingsId")].formatSettings.general?.displayScheduleTab
            }
          ></BooleanToggleElement>
        }
        tooltip={settingTooltips?.formatSettings?.general?.displayScheduleTab}
        id={settingIds?.formatSettings?.general?.displayScheduleTab}
      />

      <SettingsFormInput 
        label="Only show paid hours in schedule tab:"
        grayOut={
          !settings[getUrlVariableValue("settingsId")].formatSettings.general?.displayScheduleTab
        }
        inputElement={
          <BooleanToggleElement
            id="onlyShowPaidHoursSchedule"
            onChange={handleChange}
            default={
              settings[getUrlVariableValue("settingsId")].formatSettings.general?.onlyShowPaidHoursSchedule
            }
          ></BooleanToggleElement>
        }
        tooltip={settingTooltips?.formatSettings?.general?.onlyShowPaidHoursSchedule}
        id={settingIds?.formatSettings?.general?.onlyShowPaidHoursSchedule}
      />

      <SettingsFormInput 
        label="Display comments in Staff Tabs"
        grayOut={
          !settings[getUrlVariableValue("settingsId")].formatSettings.general?.commentsCarryOverToStaffTabs
        }
        inputElement={
          <MultipleSelectCheckmarks
            tag="Comments in Staff Tabs"
            onChange={handleChange}
            id="commentsCarryOverToStaffTabs"
            multiple
            default={
              settings[getUrlVariableValue("settingsId")].formatSettings.general?.commentsCarryOverToStaffTabs
            }
            size={400}
            //options={attendeeStatuses}
            options={
              ["Class", "Time", "Commission"]
            }
            label="Comments"
            name="Comments"
          ></MultipleSelectCheckmarks>
        }
        tooltip={settingTooltips?.formatSettings?.general?.commentsCarryOverToStaffTabs}
        id={settingIds?.formatSettings?.general?.commentsCarryOverToStaffTabs}
      />

      <SettingsFormInput 
        label="Display Rescheduled Classes in Output:"
        grayOut={
          !settings[getUrlVariableValue("settingsId")].formatSettings.general?.showRescheduledClassesInOutput
        }
        inputElement={
          <BooleanToggleElement
            id="showRescheduledClassesInOutput"
            onChange={handleChange}
            default={
              settings[getUrlVariableValue("settingsId")].formatSettings.general?.showRescheduledClassesInOutput
            }
          ></BooleanToggleElement>
        }
        tooltip={settingTooltips?.formatSettings?.general?.showRescheduledClassesInOutput}
        id={settingIds?.formatSettings?.general?.showRescheduledClassesInOutput}
      />

      <SettingsFormInput 
        label="Display Attendance Status as Comments:"
        grayOut={
          !settings[getUrlVariableValue("settingsId")].formatSettings.general?.showAttendanceStatus
        }
        inputElement={
          <MultipleSelectCheckmarks
            tag="Attendee Statuses"
            onChange={handleChange}
            id="showAttendanceStatus"
            multiple
            default={
              settings[getUrlVariableValue("settingsId")].formatSettings.general?.showAttendanceStatus
            }
            size={400}
            //options={attendeeStatuses}
            options={
              ["All"].concat(settings[getUrlVariableValue("settingsId")].generalSettings?.attendeeStatuses)
            }
            label="Statuses"
            name="Statuses"
          ></MultipleSelectCheckmarks>
        }
        tooltip={settingTooltips?.formatSettings?.general?.showAttendanceStatus}
        id={settingIds?.formatSettings?.general?.showAttendanceStatus}
      />

      <SettingsFormInput 
        label="Display Attendee Bonus Comment:"
        grayOut={
          !settings[getUrlVariableValue("settingsId")].formatSettings.general?.showAttendeeBonusComment
        }
        inputElement={
          <BooleanToggleElement
            id="showAttendeeBonusComment"
            onChange={handleChange}
            default={
              settings[getUrlVariableValue("settingsId")].formatSettings.general?.showAttendeeBonusComment
            }
          ></BooleanToggleElement>
        }
        tooltip={settingTooltips?.formatSettings?.general?.showAttendeeBonusComment}
        id={settingIds?.formatSettings?.general?.showAttendeeBonusComment}
      />

    </SettingsFormContainer>

  </Stack>
  ) : (
    <Box className="settings-page">
      <Skeleton>
        <Typography variant="h2" className="settings-page__title">
          Studios
        </Typography>
      </Skeleton>
      <Divider className="settings-page__divider" />
      <Skeleton className="settings-page__attribute-box settings-page__attribute-box--skeleton"></Skeleton>
    </Box>
  );
}
