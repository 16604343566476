import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { Skeleton, Typography } from "@mui/material";
import { Divider } from "@mui/material";
import SelectorBox from "../../Components/SelectorBox/SelectorBox";
import InputBoxElement from "../../Components/InputBox/InputBox";
import BooleanToggleElement from "../../Components/BooleanToggle";
import { RevertList } from "../../Components/RevertList";
import AdvancedCommissionSettings from "./AdvancedCommissionSettings";
import { useSettingsContext } from "../../../contexts/SettingsContext";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import BasicModal from "../../../components/Modal/Modal";
import MultipleSelectCheckmarks from "../../../components/MultipleSelectCheckbox/MultipleSelectCheckbox";
import { useAuth } from "../../../contexts/AuthContext";
import { getUrlVariableValue } from "../../../utility-functions/utility-functions.js";
import SettingsFormContainer from "../../../components/SettingsForm/SettingsFormContainer.jsx";
import SettingsFormInput from "../../../components/SettingsForm/SettingsFormInput.jsx";
import { settingIds, settingTooltips } from "../CommonVariables";

export default function CommissionSettings() {
  const { getUID } = useAuth();
  const { settings, dispatch, changes, setChanges } = useSettingsContext();

  const [agreements, setAgreements] = useState(
    settings[getUrlVariableValue("settingsId")]?.commissionSettings?.general
      ?.useAgreementsLog
      ? settings[getUrlVariableValue("settingsId")].commissionSettings.general
          .useAgreementsLog
      : false
  );
  const [retail, setRetail] = useState(
    settings[getUrlVariableValue("settingsId")]?.commissionSettings?.general
      ?.useProductGrossSales
      ? settings[getUrlVariableValue("settingsId")].commissionSettings.general
          .useProductGrossSales
      : false
  );

  function handleChange(value, id) {
    if (id === "useAgreementsLog") {
      setAgreements(value);
    } else if (id === "useProductGrossSales") {
      setRetail(value);
    }
    let newRev = new RevertList(changes.getValues());
    newRev.addElement(
      id,
      settings[getUrlVariableValue("settingsId")].commissionSettings.general[
        id
      ],
      value
    );
    setChanges(newRev);

    dispatch({
      type: "UPDATE_SETTINGS_PROPERTY",
      settingsId: getUrlVariableValue("settingsId"),
      uid: getUID(),
      path: `${getUrlVariableValue(
        "settingsId"
      )}.commissionSettings.general.${id}`,
      value: value,
    });
    // setStudioSettings({
    //   ...settings[getUrlVariableValue("settingsId")],
    //   commissionSettings: {
    //     ...settings[getUrlVariableValue("settingsId")].commissionSettings,
    //     general: {
    //       ...settings[getUrlVariableValue("settingsId")].commissionSettings.general,
    //       [id]: value,
    //     },
    //   },
    // });
  }

  const agreementQuestions = (
    <>
      {settings.hasOwnProperty("1") && settings.hasOwnProperty("staff")
        ? Object.entries(
            settings[getUrlVariableValue("settingsId")]?.generalSettings
              ?.commissionTypes
          )
            .filter(([, value]) => value.secondary === "Agreement")
            .sort()
            .map(([key]) => {
              return (
                <>
                  <CommissionBucketBox
                    key={key}
                    type={key}
                    title={key}
                    body={key}
                    text={"For " + key}
                  ></CommissionBucketBox>
                  <Divider className="settings-page__divider settings-page__divider--light" />
                </>
              );
            })
        : null}
    </>
  );
  const retailQuestions = (
    <>
      {settings.hasOwnProperty("1") && settings.hasOwnProperty("staff")
        ? Object.entries(
            settings[getUrlVariableValue("settingsId")]?.generalSettings
              ?.commissionTypes
          )
            .filter(([, value]) => value.secondary === "Retail")
            .sort()
            .map(([key], index) => {
              return (
                <>
                  <CommissionBucketBox
                    key={key}
                    type={key}
                    title={key}
                    body={key}
                    text={"For " + key}
                    // step={startingStep}
                  ></CommissionBucketBox>
                  <Divider className="settings-page__divider settings-page__divider--light" />
                </>
              );
            })
        : null}
    </>
  );

  return settings.hasOwnProperty("1") && settings.hasOwnProperty("staff") ? (
    <Box className="settings-page">
      <Typography variant="h2" className="settings-page__title">
        {
          "Sales " + settings[getUrlVariableValue("settingsId")].generalSettings
            .commissionName
        }
      </Typography>
      <Typography variant="p" className="settings-page__title-description">
        {/* <Link href=""><VideocamIcon/>Tutorial</Link> */}
        {`(Deprecated) - Please reach out for new and improved sales commission settings.`}
      </Typography>
      <Divider className="settings-page__divider" />

      <SettingsFormContainer>

        <SettingsFormInput
          label={"Do you pay " +
            settings[getUrlVariableValue("settingsId")].generalSettings
              .commissionName +
            " on sold agreements?:"}
          inputElement={
            <Box className="settings-page__box settings-page__box--inline">
              <BooleanToggleElement
                default={agreements}
                id="useAgreementsLog"
                onChange={handleChange}
              ></BooleanToggleElement>
              <BasicModal disabled={!agreements} openIcon={<EditRoundedIcon />}>
                {agreementQuestions}
              </BasicModal>
            </Box>
          }
          tooltip={settingTooltips?.commissionSettings?.soldAgreementsEnabled}
          id={settingIds?.commissionSettings?.soldAgreementsEnabled}
        />

        <SettingsFormInput
          label={"Do you pay " +
            settings[getUrlVariableValue("settingsId")].generalSettings
              .commissionName +
            " on sold retail items?:"}
          inputElement={
            <Box className="settings-page__box settings-page__box--inline">
              <BooleanToggleElement
                default={retail}
                id="useProductGrossSales"
                onChange={handleChange}
              ></BooleanToggleElement>
              <BasicModal disabled={!retail} openIcon={<EditRoundedIcon />}>
                {/* {modal(commissionQuestions)} */}
                {retailQuestions}
              </BasicModal>
            </Box>
          }
          tooltip={settingTooltips?.commissionSettings?.soldItemsEnabled}
          id={settingIds?.commissionSettings?.soldItemsEnabled}
        />

        <SettingsFormInput
          label={"Advanced " +
            settings[getUrlVariableValue("settingsId")].generalSettings
              .commissionName +
            " Settings"}
          inputElement={
            <Box className="settings-page__box settings-page__box--inline">
              <BasicModal openIcon={<EditRoundedIcon />}>
                {<AdvancedCommissionSettings></AdvancedCommissionSettings>}
              </BasicModal>
            </Box>
          }
          tooltip={settingTooltips?.commissionSettings?.advanced}
          id={settingIds?.commissionSettings?.advanced}
        />

      </SettingsFormContainer>
    </Box>
  ) : (
    <Box className="settings-page">
      <Skeleton>
        <Typography variant="h2" className="settings-page__title">
          Studios
        </Typography>
      </Skeleton>
      <Divider className="settings-page__divider" />
      <Skeleton className="settings-page__attribute-box settings-page__attribute-box--skeleton"></Skeleton>
    </Box>
  );
}

function CommissionBucketBox(props) {
  const { getUID } = useAuth();
  const { settings, dispatch, changes, setChanges } = useSettingsContext();

  const handleBucketUpdate = (value, id) => {
    let newRev = new RevertList(changes.getValues());
    newRev.addElement(
      id,
      settings[getUrlVariableValue("settingsId")].commissionSettings
        .commissionBuckets[props.type][id],
      value
    );

    setChanges(newRev);

    dispatch({
      type: "UPDATE_SETTINGS_PROPERTY",
      settingsId: getUrlVariableValue("settingsId"),
      uid: getUID(),
      path: `${getUrlVariableValue(
        "settingsId"
      )}.commissionSettings.commissionBuckets.${props.type}.${id}`,
      value: value,
    });
  };

  return (
    <Box>
      <Box className="settings-page__box settings-page__box--inline settings-page__box">
        <Typography className="settings-page__text">
          {props.text}, salespeople are paid a rate of
        </Typography>
        {settings[getUrlVariableValue("settingsId")].commissionSettings ? (
          <InputBoxElement
            onChange={handleBucketUpdate}
            id="rate"
            default={
              settings[getUrlVariableValue("settingsId")].commissionSettings
                .commissionBuckets[props.type].rate
            }
            types={["percent", "dollar"]}
            size={100}
            name="Commission Amount"
          ></InputBoxElement>
        ) : (
          <Skeleton></Skeleton>
        )}

        {settings[getUrlVariableValue("settingsId")].commissionSettings ? (
          <SelectorBox
            onChange={handleBucketUpdate}
            id="split"
            default={
              settings[getUrlVariableValue("settingsId")].commissionSettings
                .commissionBuckets[props.type].split
            }
            size={200}
            options={["Split between", "Given to each,"]}
            label="Split?"
            name="Split"
          ></SelectorBox>
        ) : (
          <Skeleton></Skeleton>
        )}

        <MultipleSelectCheckmarks
          tag="Persons"
          default={
            settings[getUrlVariableValue("settingsId")].commissionSettings
              .commissionBuckets[props.type].salespeople
          }
          onChange={handleBucketUpdate}
          id="salespeople"
          multiple
          size={400}
          options={
            settings[getUrlVariableValue("settingsId")].generalSettings
              .commissionTypes[props.type].salespeople
          }
          label="Salespeople"
          name="Salespeople"
        ></MultipleSelectCheckmarks>
      </Box>
    </Box>
  );
}
