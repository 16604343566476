export const resources = [
    // {
    //     name: "General-Overview",
    //     slug: "f1e619fd0a2949c8ad676e8da779c74d",
    //     summary: "A general overview of PayWell.",
    //     transcript: "",
    //     icon: "InfoIcon",
    //     content: [
    //         {
    //             type: "paragraph",
    //             text: "Here is a detailed explanation of what our product does and how it can help you."
    //         },
    //         {
    //             type: "image",
    //             src: "path/to/image.jpg",
    //             alt: "Descriptive alternate text"
    //         },
    //         {
    //             type: "paragraph",
    //             text: "Further details and insights into product features."
    //         }
    //     ],
    //     chapters: [
    //         { timestamp: "00:00:00", title: "Introduction" },
    //         { timestamp: "00:00:10", title: "Features Overview" },
    //         { timestamp: "00:00:20", title: "Advanced Settings" }
    //     ],
    //     relatedResources: ["Running-PayWell"],
    //     lastUpdated: "2024-11-14",
    //     author: "John Doe",
    //     estimatedReadingTime: "5 minutes",
    //     keywords: ["overview", "getting started", "PayWell"],
    //     attachments: [
    //         { name: "User Guide", url: "/path/to/user_guide.pdf" }
    //     ]
    // },
    {
        name: "Running-PayWell",
        slug: "8333cd620d5b4a6f97a14fee171358ea",
        summary: "Learn how to run PayWell.",
        transcript: `0:02 Hi, today I'm going to be doing a brief tutorial on how to run Paywall. so without further ado, let's get started.
        0:08 This is the screen that you will see when you log into. if we wanted to edit our settings, we could click this edit button, updating staff rates, changing the way some of our payroll structures work.
        0:19 If not, we can go hit this next in the bottom right corner. From here, if we have multiple locations, we will see them both pop up here.
        0:30 If we'd like to run them together, we can leave as is and click the next button. to run one at a time, we can drop down and select one or the other or a couple.
        0:43 Now we can press this next button. If we've set up in your settings what your pay period is, the date range of the most recent pay period should automatically pop If not, you can go into here and select the date range or type the date range in.
        0:59 Now to run, all we have to do is hit create new payroll. Upon hitting it, ah, you will see that for all of the locations that we're running, there are going to be two tabs that pop up, and they will be loading to indicate that files are being pulled over.
        1:20 If we click these arrows, we can drop down and see all the files that were pulled over and their respective date ranges.
        1:29 Now, we have several options of what we can do here. If some files aren't loading, we can retry. With the top arrow right here, there will be an option to click these three dots.
        1:41 We can also go into an individual file, click here and press this retry button. If we have something like a different time clock file that we need to import in directly, we go to the third option, which will be the time clock payroll that will get loaded in, press the three dots on just one of the locations
        2:06 , and we can press this upload button, which will prompt us to drop the, when I work, sling, any fi- any alternate and external time file.
        2:15 that you may use. If we wanted to run for an alternate date range for certain sets of files, the most common use case is paying monthly sales commission.
        2:26 what we can do is select into the agreements and the gross sales detail, which is going to be where we get our memberships and retail respective.
        2:36 If it's a pay period that we do not run for those, we can hit this delete button. You'll see this will delete them up.
        2:45 If it is a pay period where we do and we want to override the date range, we can click this, select change pay period.
        2:54 Select what this pay period is. This option will override it for all locations, so we can turn this on. And now we'll see both of these are going to be loading in.
        3:08 Now we're ready to hit the next button and review the files and the data that came over before we delete them.
        3:15 And now we're ready the paywall output. So we'll hit this button down here. Now, this is where we can review everything before downloading the output file.
        3:25 At the top, any sessions that are unadded or we haven't determined how they are paid are going to show up here.
        3:32 We can use this plus arrow right here to add it to its own bucket to make sure it's assigned a rate.
        3:37 We'll also show staff that are unadded. So, maybe it's new staff, maybe it's old staff that are returning, or people we just haven't added in yet.
        3:45 We will have the option here to add this staff member right on the spot. We also might give recommendations if two staff names are similar.
        3:53 We'll give the option of saying, is this the same person? And so, in order to add it, we can do add as a new staff here, which will mean we're just going to create a completely new staff member, add in all the rates for them, and any other information we may need, or attach to an existing, meaning, let's
        4:11 add this name to the alternate names of another staff member, and continue running, they'll have the same, the same rates.
        4:19 Now, all that's left to do is press this download button, and we are done running Payroll. One final thing, if we'd like to run files from a previous Payroll, or run files that we might have just ran, and we don't want to have to wait for the system to pull down those reports again, we're okay using 
        4:37 the same reports we just ran, we can select this Payroll history on the third screen when we're running the reports, and this will give us any indication into all of the previous Payroll runs that we've in and we can resume the payroll.`,
        icon: "SettingsIcon",
        content: [
            {
                type: "paragraph",
                text: "3rd Party Timeclock Files we work with:  ClubReady, MarianaTek, WhenIWork, Sling, Deputy, Prospr, Gusto, Paycom, Deputy.       If you have a different timeclock system, please reach out to us to see if we can work with it."
            },
        ],
        chapters: [
            { "timestamp": "00:00:00", "title": "Introduction & Main Page" },
            { "timestamp": "00:00:24", "title": "Selecting Locations to Run" },
            { "timestamp": "00:00:45", "title": "Grabbing Pay Period Data" },
            { "timestamp": "00:01:29", "title": "Retrying File Grab" },
            { "timestamp": "00:01:50", "title": "Adding 3rd Party Time Report" },
            { "timestamp": "00:02:17", "title": "Running Certain Files for Alternate Date Ranges" },
            { "timestamp": "00:03:08", "title": "Reviewing Before Downloading" },
            { "timestamp": "00:04:26", "title": "Payroll History Runs" }
        ],
        relatedResources: [],
        lastUpdated: "2024-11-22",
        author: "Scott Smith",
        estimatedReadingTime: "5 minutes",
        keywords: ["run", 'sling', 'wheniwork', 'wiw', 'deputy', 'propsr', 'gusto timesheet', 'timesheet', 'monthly commission', 'date range', 'review', 'output', 'staff', 'sessions', 'payroll history'],
        attachments: []
    },
    {
        name: "Adding-And-Removing-Locations",
        slug: "6a232430c2974c60bc9dcf01cdff2dfc",
        summary: "Learn how to add or remove locations in PayWell.",
        transcript: `0:01 Hi, today I'm going to be showing you how to add and remove new locations into Paywall, so we'll jump right in.
        0:07 On your homepage when you log in, if you go into the preset or settings you would like to add the new location to, press this edit button.
        0:16 It will default to the locations tab. and here we can add in the name of our location. Make sure it's the full name spelled out correctly, the way it would be present within Clubready.
        0:32 From here we can press the enter button once we've typed that out, and press save. we'd like to remove any locations, we can press this X button, and do the same thing.
        0:44 Exit out of it, and press the save. In order to confirm that we have the ability to pull reports for this, what we can do is check on the third reports page.
        0:55 So press next, and next again. If we're missing access to one of the accounts, or to one of the locations, it will display that for us here, and the way to check that would be, or double check that, would be clicking the accounts button, opening up this account, and then seeing what we have access to
        1:15 . If we'd like to provide access to this new location, we can email clubready support asking for, right here, this username to be granted permission or access to the new location of our choosing.
        1:32 last thing to note is that, by default, staff are assigned to the locations that are present when you added them as a staff member.
        1:41 So, if I create a staff member, and afterwards add a new location, they will not be assigned. They will that location by default.
        1:48 So, you may see errors like this on the back end, where it's essentially saying they do not have any rates assigned to Club Pilates South Naperville.
        1:58 If this is the case, we can use this and say assign to existing location, and this will give them access to it.
        2:07 That's it.`,
        icon: "LocationOnIcon", // Assuming you have a suitable icon like this
        content: [],
        chapters: [
            { timestamp: "00:00:00", title: "Adding and Removing Locations to Settings" },
            { timestamp: "00:02:30", title: "Confirming Access to Locations" },
            { timestamp: "00:04:45", title: "Staff Assigned Location Considerations" }
        ],
        relatedResources: [], // Assuming logical related resources
        lastUpdated: "2024-11-28",
        author: "Scott Smith",
        estimatedReadingTime: "2 minutes",
        keywords: ["locations", "management", "setup"],
        attachments: []
    },
    // {
    //     name: "Session Options",
    //     slug: "session-options-2024",
    //     summary: "Configure and customize session options to suit your business needs.",
    //     transcript: "",
    //     icon: "EventIcon", // Suggesting an icon related to scheduling
    //     content: [
    //         {
    //             type: "paragraph",
    //             text: "This resource helps you navigate the various session settings available in PayWell, enabling you to tailor session characteristics according to your operational requirements."
    //         },
    //         {
    //             type: "image",
    //             src: "path/to/session-options-image.jpg",
    //             alt: "Screenshot of session options interface"
    //         },
    //         {
    //             type: "paragraph",
    //             text: "Explore detailed explanations on setting up and managing different session types, durations, and specific conditions."
    //         }
    //     ],
    //     chapters: [
    //         { timestamp: "00:00:00", title: "Overview of Session Options" },
    //         { timestamp: "00:03:00", title: "Configuring Session Types" },
    //         { timestamp: "00:06:30", title: "Advanced Session Settings" }
    //     ],
    //     relatedResources: ["General-Demo", "Time Options"],email
    //     lastUpdated: "2024-11-15",
    //     author: "Jane Smith",
    //     estimatedReadingTime: "4 minutes",
    //     keywords: ["sessions", "settings", "customization"],
    //     attachments: [
    //         { name: "Session Configuration Guide", url: "/path/to/session_configuration_guide.pdf" }
    //     ]
    // },
    // {
    //     name: "Time Options",
    //     slug: "time-options-2024",
    //     summary: "Adjust time settings to accurately track and manage employee hours.",
    //     transcript: "",
    //     icon: "AccessTimeIcon", // Suggesting an icon that denotes time management
    //     content: [
    //         {
    //             type: "paragraph",
    //             text: "Learn how to set up and manage time options within PayWell to ensure accurate and efficient time tracking for your workforce."
    //         },
    //         {
    //             type: "image",
    //             src: "path/to/time-options-image.jpg",
    //             alt: "Guide to time settings configuration"
    //         },
    //         {
    //             type: "paragraph",
    //             text: "This guide covers everything from basic time recording settings to complex configurations for special scenarios."
    //         }
    //     ],
    //     chapters: [
    //         { timestamp: "00:00:00", title: "Introduction to Time Management" },
    //         { timestamp: "00:02:45", title: "Setting Up Time Tracking" },
    //         { timestamp: "00:05:15", title: "Custom Time Settings Explained" }
    //     ],
    //     relatedResources: ["Session Options", "Format Settings"],
    //     lastUpdated: "2024-11-15",
    //     author: "John Doe",
    //     estimatedReadingTime: "5 minutes",
    //     keywords: ["time", "tracking", "management"],
    //     attachments: [
    //         { name: "Time Management Guide", url: "/path/to/time_management_guide.pdf" }
    //     ]
    // },
    // {
    //     name: "Format Settings",
    //     slug: "format-settings-2024",
    //     summary: "Control and customize the format of data entries and displays in your system.",
    //     transcript: "",
    //     icon: "SettingsIcon", // Icon indicating settings or configuration
    //     content: [
    //         {
    //             type: "paragraph",
    //             text: "This resource provides comprehensive guidance on adjusting format settings within PayWell to meet your company's specific data presentation and processing needs."
    //         },
    //         {
    //             type: "image",
    //             src: "path/to/format-settings-image.jpg",
    //             alt: "Illustration of format customization options"
    //         },
    //         {
    //             type: "paragraph",
    //             text: "Discover how to modify formats for dates, numbers, and other data types across your platform."
    //         }
    //     ],
    //     chapters: [
    //         { timestamp: "00:00:00", title: "Overview of Format Settings" },
    //         { timestamp: "00:03:15", title: "Customizing Data Formats" },
    //         { timestamp: "00:06:00", title: "Best Practices in Format Configuration" }
    //     ],
    //     relatedResources: ["Time Options", "General-Demo"],
    //     lastUpdated: "2024-11-16",
    //     author: "Jane Smith",
    //     estimatedReadingTime: "3 minutes",
    //     keywords: ["format", "settings", "customization"],
    //     attachments: [
    //         { name: "Format Settings Guide", url: "/path/to/format_settings_guide.pdf" }
    //     ]
    // },
    // {
    //     name: "Exceptions/Automations",
    //     slug: "exceptions-automations-2024",
    //     summary: "Automate tasks and manage exceptions to streamline your payroll processes.",
    //     transcript: "",
    //     icon: "AutorenewIcon", // Suggesting an icon related to automation
    //     content: [
    //         {
    //             type: "paragraph",
    //             text: "Learn how to set up automations and handle exceptions in PayWell to reduce manual errors and increase efficiency."
    //         },
    //         {
    //             type: "image",
    //             src: "path/to/exceptions-automations-image.jpg",
    //             alt: "Diagram of automation workflows in PayWell"
    //         },
    //         {
    //             type: "paragraph",
    //             text: "This section covers the tools and methods to configure automations that respond to common exceptions in payroll management."
    //         }
    //     ],
    //     chapters: [
    //         { timestamp: "00:00:00", title: "Understanding Automations" },
    //         { timestamp: "00:04:00", title: "Managing Payroll Exceptions" },
    //         { timestamp: "00:08:30", title: "Advanced Automation Techniques" }
    //     ],
    //     relatedResources: ["General-Demo", "Admin Settings"],
    //     lastUpdated: "2024-11-17",
    //     author: "John Doe",
    //     estimatedReadingTime: "6 minutes",
    //     keywords: ["exceptions", "automations", "payroll efficiency"],
    //     attachments: [
    //         { name: "Automation Setup Guide", url: "/path/to/automation_setup_guide.pdf" }
    //     ]
    // },
    // {
    //     name: "Payroll Processor Mapping",
    //     slug: "payroll-processor-mapping-2024",
    //     summary: "Integrate and map your payroll data seamlessly with external processors.",
    //     transcript: "",
    //     icon: "SwapHorizIcon", // Suggesting an icon related to data interchange
    //     content: [
    //         {
    //             type: "paragraph",
    //             text: "Ensure accurate data transfer between PayWell and your payroll processor with comprehensive mapping tools."
    //         },
    //         {
    //             type: "image",
    //             src: "path/to/payroll-processor-mapping-image.jpg",
    //             alt: "Flowchart of payroll data mapping process"
    //         },
    //         {
    //             type: "paragraph",
    //             text: "This guide provides step-by-step instructions on how to set up and verify mappings to ensure smooth payroll operations."
    //         }
    //     ],
    //     chapters: [
    //         { timestamp: "00:00:00", title: "Introduction to Payroll Mapping" },
    //         { timestamp: "00:03:50", title: "Configuring Data Mappings" },
    //         { timestamp: "00:07:20", title: "Troubleshooting Common Mapping Issues" }
    //     ],
    //     relatedResources: ["General-Demo", "Running-PayWell"],
    //     lastUpdated: "2024-11-17",
    //     author: "Jane Smith",
    //     estimatedReadingTime: "5 minutes",
    //     keywords: ["payroll processing", "data integration", "mapping"],
    //     attachments: [
    //         { name: "ADP Run Import Guide (PDF)", url: "GTS_payroll_guide.pdf" },
    //         { name: "Paychex Flex Import Guide (PDF)", url: "Payroll_Import_Instructions_20240606.pdf" },
    //         { name: "Paychex Flex Import Template (XLSX)", url: "Template_for_SPI_Import_20240606.xlsx"},
    //         { name: "Gusto Import Guide (PDF)", url: "Gusto Smart Import.pdf" },
    //         { name: "Gusto Import Template (CSV)", url: "standard-gusto-timesheet-template.csv"}
    //     ]
    // },
    // {
    //     name: "Commission Settings",
    //     slug: "commission-settings-2024",
    //     summary: "Configure and manage commission structures to incentivize your workforce effectively.",
    //     transcript: "",
    //     icon: "TrendingUpIcon", // Suggesting an icon related to growth or finance
    //     content: [
    //         {
    //             type: "paragraph",
    //             text: "This resource guides you through setting up commission plans in PayWell, helping you to align incentives with business goals."
    //         },
    //         {
    //             type: "image",
    //             src: "path/to/commission-settings-image.jpg",
    //             alt: "Overview of commission setting options"
    //         },
    //         {
    //             type: "paragraph",
    //             text: "Learn how to create, apply, and manage commission rates and rules that motivate your team and drive performance."
    //         }
    //     ],
    //     chapters: [
    //         { timestamp: "00:00:00", title: "Overview of Commission Structures" },
    //         { timestamp: "00:03:30", title: "Creating Commission Plans" },
    //         { timestamp: "00:07:00", title: "Commission Management Best Practices" }
    //     ],
    //     relatedResources: ["Running-PayWell", "Add/Remove Locations"],
    //     lastUpdated: "2024-11-18",
    //     author: "John Doe",
    //     estimatedReadingTime: "4 minutes",
    //     keywords: ["commissions", "sales incentives", "payroll customization"],
    //     attachments: [
    //         { name: "Commission Setup Guide", url: "/path/to/commission_setup_guide.pdf" }
    //     ]
    // },
    // {
    //     name: "Admin Settings",
    //     slug: "admin-settings-2024",
    //     summary: "Master the administrative controls to manage and secure your PayWell system effectively.",
    //     transcript: "",
    //     icon: "AdminPanelSettingsIcon", // Suggesting an icon related to administrative control
    //     content: [
    //         {
    //             type: "paragraph",
    //             text: "Gain comprehensive control over your PayWell system with detailed guidance on configuring admin settings."
    //         },
    //         {
    //             type: "image",
    //             src: "path/to/admin-settings-image.jpg",
    //             alt: "Screenshot of admin settings panel"
    //         },
    //         {
    //             type: "paragraph",
    //             text: "This resource provides instructions on how to customize user roles, permissions, and system preferences to ensure operational security and efficiency."
    //         }
    //     ],
    //     chapters: [
    //         { timestamp: "00:00:00", title: "Introduction to Admin Controls" },
    //         { timestamp: "00:04:00", title: "Configuring User Permissions" },
    //         { timestamp: "00:08:00", title: "System Security Settings" }
    //     ],
    //     relatedResources: ["General-Demo", "Exceptions/Automations"],
    //     lastUpdated: "2024-11-19",
    //     author: "Jane Smith",
    //     estimatedReadingTime: "5 minutes",
    //     keywords: ["admin settings", "user roles", "system security"],
    //     attachments: [
    //         { name: "Admin Settings Guide", url: "/path/to/admin_settings_guide.pdf" }
    //     ]
    // },<div style="position: relative; padding-bottom: 56.25%; height: 0;"><iframe src="https://www.loom.com/embed/a1e9bb6a35fd464681d8432ef626a9a6?sid=713871d3-a6cd-446a-938f-4138d36b7554" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;"></iframe></div>
    {
        name: "Adding-and-Updating-Staff",
        slug: "a1e9bb6a35fd464681d8432ef626a9a6",
        transcript: `00:02 Hi, today I'm going to be showing you how to edit and update and delete staff, within Paywall. So we'll jump right in.
        00:10 To get to your staff information and rates, we'll press the edit button, and then on the left side we'll see a staff button.
        00:19 And now we're here. see two tabs, one of which is going to be info, which is anything related to the employee themselves, whether that be the type of instructor or front desk staff that they are, any emails, IDs that we might need, and then we'll see rates, which is going to be anything related to how
        00:37 they are paid. So, first and foremost, we can talk about adding a new staff member. To add a new staff member, press this add button here.
        00:47 You'll be prompted with the name of the staff member the type. we can have custom types for you, but the payment premise is basically we'll have people who are instructors or providers and people who are not instructors, say front desk workers.
        01:03 There may be other fields here that we have required. Maybe that's IDs for a payroll processor. Make sure you fill those out and press continue.
        01:13 we want to remove a staff member, it's as simple as pressing the trash can icon right here, which will prompt you to say yes if you want to delete it or cancel if you do not want to delete the staff instance.
        01:26 They will stay here until you refresh the page and you save it with the save button in the top right corner.
        01:32 Note that the save button needs to be hit after every single staff that is edited. deleting staff members is encouraged for cleanliness, it's not mandatory, so if there's chances of staff members coming back or temporary leaves of absence, feel free to leave them in.
        01:50 You don't need to update them and add them as they go. To utilize the staff table here, you'll see all your employees, and you can scroll up and down, and click through them.
        02:03 as I hover over each cell, I can double click in here and press into any of the cells, and edit them.
        02:09 a couple other important things would be, if I wanted a CSV printout of all my employees' information and rates, I can press this export button here, which will download that.
        02:19 and I strongly recommend or advise against not doing this, but if we wanted to do mass edits, please reach out to me and I can show you how to use this upload staff button where we can mass upload, rates for employees or IDs.
        02:33 Likewise, if we want to search up staff, we can type in their name and press search. so getting right into the info tab, the first column is going to be the name of the staff member.
        02:47 now this is their primary name, so this is how they'll show up in the paywall output. A couple things, it's important not to have special characters in here.
        02:54 Thank you. Postrophes as well, too, as that may throw off the, output, but whatever they go by, whether that be their legal name or the way that you, identify them, that's what to put here.
        03:04 Type, this is going to be a drop-down and it's customizable, so by default, it'll be instructor versus non-instructor. if we wanted to add custom types, such as managers, GMs, so on and so forth, and change the names here, we can do that for you as well.
        03:17 the indication here is going to be whether they have rates for sessions or they do not, they just have hourly rates and commissions.
        03:26 names, is how we can move forward with, any employees, who may be in our system multiple times. Maybe it's Adam M.
        03:34 and Adam Michaels. What we can do is click can hear. the name. And click out. And it should save. And they can have as many alternate names as they have.
        03:46 save here. Email. If we're utilizing our email pay stub, we'll want to have the correct email for each employee in here.
        03:55 Again, following standard email conventions. We will also have ID fields, secondary ID fields, title fields, anything that may be necessary.
        04:05 Mostly for your payroll processor or any indication of what this employee may be. we can change the names for how these are indicated.
        04:14 If you would like a different name for it to be more clear if other people are maintaining staff. We can do that.
        04:20 We can also take away fields, hide fields, and add new custom fields for it. If you would like. Locations is going to be all the locations that employees are assigned to.
        04:33 so for adding new locations, we might want to go through and add the new location to each employee who has the potential to work there.
        04:40 finally as well, home locations. Completely optional as well too. We can take it away, we can add it in for you.
        04:46 But if employees are paid out of a single location, but they work across both, we can add that in here as a dropdown.
        04:52 again to reiterate, if there's any custom columns that you need, you want a third ID, or maybe you want another set of titles, we can add these in with any name that is for you, and configure the order around here so it matches exactly what you need.
        05:05 Next, we'll discuss rates. so in our rates tab, we will have sub tabs that pop up here, the first one being hourly.
        05:13 so what's important to note is that for the regular column right here, this dollar amount is going to get multiplied against the hours that are clocked in to our clocking system, or to clock in.
        05:24 of Wwerdy or whatever system that we're using. we have a secondary column as well, too, and we can have other columns.
        05:30 If we want more unique shift differential rates, we can add those for you, please do just reach out. For most use cases, secondary columns are not necessary.
        05:40 Ah. You would know in our onboarding if we don't… you'd need to add those for certain instances. Based on our setup, we also may have overtime, double overtime, and holiday pay here.
        05:50 Ah. So, these can be adjusted if there are unique rates that do not fall under the standard conventions of 1.5 times their regular rate or two times their regular rate.
        05:59 And, again, take a look click in here, we can add what that is, click out, and then press the Save button in the top right corner.
        06:08 Earnings are a newer feature that we have if somebody has earnings that are not multiplied against a unit cost. Or our amount, so it's just a flat $200 stipend or a $1,000 salary.
        06:22 We can add these in here. To do that, we'll click, and we'll see a pop-up box. We'll add the earning, let's say it is $25, and then we can select which locations we want them for.
        06:35 So, if it's $25 from both. We would say $25 at this location, and then we can say another $25 at another location.
        06:44 Confirm. It'll show $50 for the total amount, and again, we're saving it in the top right corner. If there are other earnings, such as reimbursements, miscellaneous bonuses, anything else, we can add those of you.
        06:57 Again, please feel free to reach out, and we'll add more columns for you, and customize it how you might like.
        07:03 we're going to get into the non-hourly, the session-based rates, that we have set. So, following the hourly and earnings, for all of our class types, or we call them class buckets, we are going to have a rate set up for everybody, and anybody who's indicated as a provider or instructor, would show up
        07:21 here as being able to have a rate for these types of classes. So, again, same thing here. There's a couple different options you'll see, but if there's a flat rate, double-click and we can add what that flat rate is.
        07:33 may also have a base plus a per head rate, so, again, this follows the same conventions. If zero attendees are in a session, how much would they get paid?
        07:42 what would be the baseline. Base rate for that, what would be the per head bonus, and then when does that per head bonus kick in after four attendees.
        07:54 final option you might see here is a custom rate, so if people are paid a different rate for each amount of attendees in a class that doesn't follow the standard base plus a per head structure, you might see it like this, very simple, 10, 100, 200.
        08:18 if you click on one of these tabs, and it's blank, this means we haven't set this rate up yet, so if we'd like to get it set up, we can go in, feel free to reach out to us, and we'll set this up for you based on how you'd like to pay it out, or we can delete this so you don't see it altogether, this 
        08:33 goes, again, with everything, it's incredibly customizable, so if you have new types of sessions, such as workshops, or any new unique session type that you'd like us to add in, we can add and delete any of these for you, and change their name so it fits your conventions.
        08:49 Finally, we have our sales commission rates tab, which is a little bit more unique than the other tabs prior to this.
        08:57 This essentially is what we call an override rate. So by default, we've set up our system to pay sales commission on potentially memberships and retail at a certain amount, maybe it's 10%.
        09:09 Maybe it's $10, maybe it's split. By default, everybody that we assign will get that. However, if we have certain employees who are getting paid different rates, we can go in here and adjust those.
        09:21 so it would go through, they sold an agreement, it'd give them the standard rate, but if we find a overridden sales commission rate here, this is what it would go to.
        09:31 So, for example, if we add in here, this means that for any agreement sold, Adam would get a dollar instead of whatever it was.
        09:43 If we want To get even more granular, if you're using our new sales commission logic, we can break down even further into the different sales commission types over here, as well as what the status of those were.
        09:58 So, if we want upgraded memberships to be $5 as an overridden rate versus new memberships to be the standard by default, this is where we do that.
        10:11 leaving this blank, or taking it out, so let's if we added a $10 in here, this would be $10 as their override rate, and if we take it out and leave it blank, this means they get the default.
        10:23 and again, we'll have a lot of options here for how we can customize this. One thing to note is that flat rates can either be a dollar amount or a percentage amount.
        11:00 So we can either have $25 as a flat rate for each group session, they get paid $23, or we could have it as a percentage.
        11:11 And to add this in, we would put zero point whatever the percentage is here, click out, and it will automatically give the percentage.
        11:20 This essentially means 30% of what was paid for the class is given to the provider.`,
        summary: "Learn how to add new staff members and update existing staff information efficiently.",
        icon: "GroupAddIcon", // Suggesting an icon related to adding group members
        content: [],
        chapters: [
            { "timestamp": "00:00:00", "title": "Introduction to Staff Editing" },
            { "timestamp": "00:00:38", "title": "Adding a New Staff Member" },
            { "timestamp": "00:01:12", "title": "Removing a Staff Member" },
            { "timestamp": "00:01:55", "title": "Working with the Staff Table User Interface" },
            { "timestamp": "00:02:41", "title": "Info: Staff Name, Type, Alternate Names & Email" },
            { "timestamp": "00:03:59", "title": "Info: ID and Titles Fields" },
            { "timestamp": "00:04:28", "title": "Info: Staff Locations, Home Location & Custom Fields" },
            { "timestamp": "00:05:04", "title": "Rates: Hourly & Earnings" },
            { "timestamp": "00:07:02", "title": "Rates: Class Rates" },
            { "timestamp": "00:08:49", "title": "Rates: Sales Commission" },
            { "timestamp": "00:10:28", "title": "Exceptions/Automations" },
            { "timestamp": "00:10:55", "title": "Flat Dollar Amount vs Percentage Rates" }
        ],        
        relatedResources: [],
        lastUpdated: "2024-12-6",
        author: "Scott Smith",
        estimatedReadingTime: "10 minutes",
        keywords: ["adding staff", "updating staff", "ids", 'rates', 'custom'],
        attachments: [
            // { name: "Staff Management Guide", url: "/path/to/staff_management_guide.pdf" }
        ]
    },
    {
        name: "Emailing Pay Stubs",
        slug: "3906ceb229ed4292a9b7d024109d76fd",
        transcript: `0:01 Hi, today I'm going to be showing you how to send email pay stubs out to our staff members. First and foremost, any staff who would like to receive these emails must have their emails stored within Paywall.
0:12 So, to do that, press edit, staff, and in our info table here, for any staff, again, who would like to receive these emails, needs to have an email right here.
0:24 You can double click, add that in, make sure we save it. re-upload our file into paywall. To send out the pay stubs, we can go to the main screen, press the email pay stubs up here or click on the top right icon and press email pay stubs.
0:44 From here we can drag and drop the file or we can click browse and drop in the file that we were just editing.
0:50 And that has been saved. that the file is in, you'll see some cards down here. That indicate employees that both have emails within Paywall and worked throughout the pay period.
1:01 So, from here, if we wanted to preview what will be sent to them, we can hover over and click on the respective person.
1:09 Now, I did this the incorrect way and I had forgot to save the file to show you what it looks like.
1:14 So, if I don't save the file before importing it and based on Excel references and formulas and how Excel works, I click in here and you'll see everything is zero.
1:26 for this one. left corner prior to dropping it in and I put the file back in. And now if I hover over, I can see that there are numbers here.
1:42 if it worked for one, it worked for all. Finally, we can press the send for each individual person we'd like to send it to, or we can press send all to mass send out to all of our employees here.
1:54 just be cognizant that you will be s- Please see it on all the e-mails and they will come from an e-mail that's pay preview at paywell.solutions.
2:02 Make sure your employees know to reply all to make sure that you who see seed on it receives the message if they ha-`,
        summary: "Efficiently sned pay stubs to your employees via email directly from PayWell.",
        icon: "EmailIcon", // Suggesting an icon related to emailing
        content: [],
        chapters: [
            { timestamp: "00:00:00", title: "Introduction & Managing Staff Emails" },
            { timestamp: "00:00:31", title: "Reuploading the PayWell Output" },
            { timestamp: "00:01:02", title: "Previewing Emails" },
            { timestamp: "00:01:09", title: "Incorrect Import (Forgot to Save Prior to Upload)" },
            { timestamp: "00:01:28", title: "Correct Import (Properly Save File Uploaded)" },
            { timestamp: "00:01:46", title: "Sending the Emails" }
        ],
        relatedResources: [],
        lastUpdated: "2024-12-23",
        author: "Scott Smith",
        estimatedReadingTime: "3 minutes",
        keywords: ["email", "pay stubs", "security"],
        attachments: []
    },
    {
        name: "Importing Into Payroll Processors",
        slug: "ad5009de34f24818a720029e3194e319",
        summary: "Learn how to import payroll data into external payroll processors seamlessly.",
        transcript: `0:01 Hi, today I'm going to be showing you how to import your paywall file into your paywall processor after you've ran it and you've gotten all your paywall calculations ready.
        0:12 One thing to note first is that in our settings, our mapping file, this is where the mapping formula or the custom template is created.
        0:22 If you do have questions about that Or you'd like adjustments or there are issues with it. Please reach out to us then The mapping is a little bit complex, so we'd be happy to help you build it out so that it fits the specifications.
        0:36 We will work with every single payroll processor out there, to name a few, Gusto, Paychex, ADP, Paycom, Alliance HCM. There's only one payroll processor that we cannot work with, which is QuickBooks, because QuickBooks does not have an upload template.
        0:53 but the rest of the payroll processors do have that payroll template so we can customize that to your specifications.
        1:01 getting right into it, this is the paywall output, for 99% of the paywall processors, ADP, Paychex, Gusto, alike, they're gonna need a CSV file format, however, paywall gives you an Excel file, so that won't work, we can't drop it directly, so the way to get that is to go to our export tab that we created
        1:24 for you right here, press file, we're gonna I'm going say Save As. folder you'd like to save it in, if you'd like to change the name, by all means go ahead, and then you can, for the type down here, select CSV, or comma delimited, save it, it's gonna give you this error, which is all good, it's just 
        1:47 saying all the other data is gonna be deleted from it, we should be good to go. thing to note is that if we have multiple entities, and paywall has broken out different import templates per entity, what we can do to make this quicker, is to go to each of those imports, so here's for one location, here's
        2:05 for another, follow that same process, save as, downloads, CSV and save. And while we're still in this file, not having exited out and went back in, we can click on our next template, and do the same exact thing.
        2:37 Now we will have two separate CSVs that can be dropped directly into our paywall processor.
        2:46 import into Paychex, we'll log in. Here's our dashboard. We'll press Payroll Center. If we have multiple entities, you'll see all the entities on this Payroll Center.
        3:12 And we can simply go through, click the dropdown right next to Begin and and press this import button. On the side, we can now drag and drop a file or press browse and import the file.
        4:01 .    .      . import into Gusto, all we have to do is log in, and where it says run payroll, we'll say let's do it.
        4:27 here, all we have to do is drag and drop or upload our
        4:45 To import into ADP Run, we'll need to make sure this is set up with ADP. By default, you can't import, so we'll have to reach out to the support and get the, timesheet import set up.
        4:55 However, once we do that, it's very simple. Just in the home screen for the upcoming payroll, we can press this Import Timesheets button.
        5:04 from here, we can press the Upload File. Again, this is going to be a CSV file only, and we can drop our file in.
        5:16 For ADP Workforce Now, it's going to function similarly to ADP Run, but slightly different. I only have screenshots here for the beginning, so I will walk through this.
        5:25 But on your payroll dashboard, when you're going to run payroll, all you have to do is press this Manage Payroll button right here.
        5:35 Pressing the add will pull up this page, which will prompt you to either start from scratch or to upload a CSV.
        5:42 We've already created this CSV template for you based on your specifications, so you can say, I have a CSV to import right here.
        5:51 the next button, we'll know we'll have setup if it's the ADP legacy format, our own file for it, or the next gen format, but from here, again with the CSV, select the file you can drag and drop it in, or upload it.
        6:07 To import into OnPay, we'll log in, this will be our main dashboard, we'll press this payroll, and it will pop up, and what we can say is Upload Hours.
        6:19 here, it's going to ask us, what file to choose, so we'll press Choose. Choose File, and we can drop in our CSV format that we've created.
        6:28 we can drag and drop it here, and press Upload.`,
        icon: "ImportExportIcon", // Suggesting an icon related to import/export operations
        content: [],
        chapters: [
            { "timestamp": "00:00:00", "title": "Introduction & PayWell Mapping Format" },
            { "timestamp": "00:00:46", "title": "Payroll Processor We Work With" },
            { "timestamp": "00:01:00", "title": "Preparing our CSV Import File" },
            { "timestamp": "00:01:52", "title": "Preparing Multiple CSV's for Multiple Entities" },
            { "timestamp": "00:02:44", "title": "Paychex Import" },
            { "timestamp": "00:04:00", "title": "Gusto Import" },
            { "timestamp": "00:04:45", "title": "ADP Run Import" },
            { "timestamp": "00:05:16", "title": "ADP Workforce Now Import" },
            { "timestamp": "00:06:07", "title": "OnPay Import" }
        ],
        relatedResources: [],
        lastUpdated: "2024-12-06",
        author: "Scott Smith",
        estimatedReadingTime: "5 minutes",
        keywords: ["import", "payroll", "data management"],
        attachments: [
                    { name: "ADP Run Import Guide (PDF)", url: "GTS_payroll_guide.pdf" },
                    { name: "Paychex Flex Import Guide (PDF)", url: "Payroll_Import_Instructions_20240606.pdf" },
                    { name: "Paychex Flex Import Template (XLSX)", url: "Template_for_SPI_Import_20240606.xlsx"},
                    { name: "Gusto Import Guide (PDF)", url: "Gusto Smart Import.pdf" },
                    { name: "Gusto Import Template (CSV)", url: "standard-gusto-timesheet-template.csv"}
                ]
    },
    // {
    //     name: "Support & Troubleshooting",
    //     slug: "support-troubleshooting-2024",
    //     summary: "Resolve common issues and access support resources to maintain smooth operations.",
    //     transcript: "",
    //     icon: "SupportAgentIcon", // Suggesting an icon related to support
    //     content: [
    //         {
    //             type: "paragraph",
    //             text: "Access detailed information on troubleshooting common problems and learn how to utilize PayWell’s support resources effectively."
    //         },
    //         {
    //             type: "image",
    //             src: "path/to/support-troubleshooting-image.jpg",
    //             alt: "Guide to troubleshooting common issues"
    //         },
    //         {
    //             type: "paragraph",
    //             text: "This section provides FAQs, direct support contacts, and step-by-step guides to solve frequent operational challenges."
    //         }
    //     ],
    //     chapters: [
    //         { timestamp: "00:00:00", title: "Getting Started with Support" },
    //         { timestamp: "00:02:30", title: "Common Issues and Solutions" },
    //         { timestamp: "00:05:00", title: "Accessing Additional Support Resources" }
    //     ],
    //     relatedResources: ["Adding & Updating Staff", "Running PayWell"],
    //     lastUpdated: "2024-11-24",
    //     author: "Jane Smith",
    //     estimatedReadingTime: "4 minutes",
    //     keywords: ["support", "FAQ", "troubleshooting"],
    //     attachments: [
    //         { name: "Troubleshooting Guide", url: "/path/to/troubleshooting_guide.pdf" }
    //     ]
    // },
    // {
    //     name: "PayWell Features & Limitations",
    //     slug: null,
    //     summary: "Resolve common issues and access support resources to maintain smooth operations.",
    //     transcript: "",
    //     icon: "ListAltIcon", // Suggesting an icon related to support
    //     content: [
    //         {
    //             type: "paragraph",
    //             text: "Access detailed information on troubleshooting common problems and learn how to utilize PayWell’s support resources effectively."
    //         },
    //         {
    //             type: "image",
    //             src: "path/to/support-troubleshooting-image.jpg",
    //             alt: "Guide to troubleshooting common issues"
    //         },
    //         {
    //             type: "paragraph",
    //             text: "This section provides FAQs, direct support contacts, and step-by-step guides to solve frequent operational challenges."
    //         }
    //     ],
    //     chapters: [
    //         { timestamp: "00:00:00", title: "Getting Started with Support" },
    //         { timestamp: "00:02:30", title: "Common Issues and Solutions" },
    //         { timestamp: "00:05:00", title: "Accessing Additional Support Resources" }
    //     ],
    //     relatedResources: ["Adding & Updating Staff", "Running PayWell"],
    //     lastUpdated: "2024-11-24",
    //     author: "Jane Smith",
    //     estimatedReadingTime: "4 minutes",
    //     keywords: ["support", "FAQ", "troubleshooting"],
    //     attachments: [
    //         { name: "Troubleshooting Guide", url: "/path/to/troubleshooting_guide.pdf" }
    //     ]
    // },
    // {
    //     name: "Payroll Processor Comparison",
    //     slug: null,
    //     summary: "Breakdown of different payroll processors",
    //     transcript: "",
    //     icon: "CompareIcon", // Suggesting an icon related to support
    //     content: [
    //         {
    //             type: "paragraph",
    //             text: "Access detailed information on troubleshooting common problems and learn how to utilize PayWell’s support resources effectively."
    //         },
    //         {
    //             type: "image",
    //             src: "path/to/support-troubleshooting-image.jpg",
    //             alt: "Guide to troubleshooting common issues"
    //         },
    //         {
    //             type: "paragraph",
    //             text: "This section provides FAQs, direct support contacts, and step-by-step guides to solve frequent operational challenges."
    //         }
    //     ],
    //     chapters: [
    //         { timestamp: "00:00:00", title: "Getting Started with Support" },
    //         { timestamp: "00:02:30", title: "Common Issues and Solutions" },
    //         { timestamp: "00:05:00", title: "Accessing Additional Support Resources" }
    //     ],
    //     relatedResources: ["Adding & Updating Staff", "Running PayWell"],
    //     lastUpdated: "2024-11-24",
    //     author: "Jane Smith",
    //     estimatedReadingTime: "4 minutes",
    //     keywords: ["support", "FAQ", "troubleshooting"],
    //     attachments: [
    //         { name: "Troubleshooting Guide", url: "/path/to/troubleshooting_guide.pdf" }
    //     ]
    // }
];


//<div style="position: relative; padding-bottom: 56.25%; height: 0;"><iframe src="https://www.loom.com/embed/8333cd620d5b4a6f97a14fee171358ea?sid=8f7839e7-aebb-42c1-9fba-07b6097bf3f3" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;"></iframe></div>
//<div style="position: relative; padding-bottom: 56.25%; height: 0;"><iframe src="https://www.loom.com/embed/8333cd620d5b4a6f97a14fee171358ea?sid=5345e087-f189-44ff-a4b0-54762e914d40" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;"></iframe></div>