import { Box, Tooltip, Typography } from "@mui/material";
import './SettingsFormInput.scss';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const GREEN = '#38ffcc'

/**
 * A component for rendering a labeled input field within a settings form. Meant to be used as a child of a {@link SettingsFormContainer}.
 *
 * @see {@link SettingsFormContainer}
 *
 * @component
 * @param {object} props - The component props.
 * @param {string} props.label - The text label displayed above the input element.
 * @param {boolean} [props.grayOut] - If true, the elements will be given a low transparency
 * @param {boolean} [props.noMaxWidth] - Normally this component has a max width of 800px. Set this prop to `true` to override this.
 * @param {string} [props.tooltip] - A tooltip related to the setting
 * @param {string} [props.id] - The uniue ID of this element
 * @param {React.ReactNode} props.inputElement - The input element to be rendered below the label. 
 * This can be any valid React node, such as a text field, dropdown, or custom input component.
 * @returns {JSX.Element} A styled container with a label and an input element.
 */
export default function SettingsFormInput({ label, inputElement, grayOut, noMaxWidth, tooltip, id: htmlId }) {
  const targetId = window.location.hash.split('#')?.[1];
  return <Box sx={noMaxWidth ? { maxWidth: "none !important" } : {}} className={"settings-page__input-container" + (grayOut ? " settings-page__gray-out" : "")}>
    <Typography id={htmlId ? htmlId : ""} sx={{ backgroundColor: targetId && targetId === htmlId ? GREEN : 'transparent' }}>
      {tooltip
        ? <Tooltip arrow title={
          <span style={{ display: 'flex', flexDirection: 'column', fontSize: '.8em', padding: '0', margin: '0' }}>
            {targetId === htmlId && targetId && <span style={{ opacity: .8, color: GREEN }}>*Search Result*</span>}
            {tooltip?.includes("\n")
              ? tooltip.split("\n").map((text, i) => (
                <p className="tooltip-paragraph" key={i}>{text}</p>
              ))
              : tooltip}
          </span>
        }>
          <InfoOutlinedIcon sx={{ opacity: .5, fontSize: '1.3em', marginRight: '5px', marginBottom: '1px' }} />{'  '}{label}
        </Tooltip>
        : label}
    </Typography>
    {inputElement}
  </Box>
}
