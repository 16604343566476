import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";

// datagrid pro imports
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import { GridRowModes, GridToolbarContainer } from "@mui/x-data-grid-pro";
import TimeForm from "./TimeForm/TimeForm";
import FormatForm from "./FormatForm/FormatForm";
import { useHistory, useLocation } from "react-router-dom";

import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DescriptionIcon from "@mui/icons-material/Description";
import AltRouteIcon from "@mui/icons-material/AltRoute";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import MapIcon from "@mui/icons-material/Map";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import ProcessorMappingList from "../ProcessorMapping/ProcessorMapping";
import Studiomanager from "./StudiosForm/StudioManager";
import StaffSettings from "../StaffSettings/StaffSettings";
import AdditionalSettings from "./AdminForm/AdditionalSettings";
import ResponsiveAppBar from "../../components/AppBar/ResponsiveAppBar";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import GroupsIcon from "@mui/icons-material/Groups";
import ScheduleIcon from "@mui/icons-material/Schedule";
import ReceiptIcon from '@mui/icons-material/Receipt';

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";

import ClassSettings from "./ClassForm/ClassSettings";
import SalesSettings from "./SalesForm/SalesSettings";

import SettingsSelector from "../Components/SettingsSelector";

import SearchIcon from '@mui/icons-material/Search';
import SettingsSearchPopup from '../Components/SettingsSearchPopup';

import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import NextPlanIcon from "@mui/icons-material/NextPlan";
import { useAuth } from "../../contexts/AuthContext";
import { updateStudioSettings } from "../../services/studio.services";

import { updateStaffMasterSettings } from "../../services/staff.services";
import { auth } from "../../firebase";

import "./studioSettings.scss";

import { RevertList } from "../Components/RevertList";
import Exceptions from "./ExceptionsForm/Exceptions";
import CommissionSettings from "./CommissionForm/CommissionSettings";
import LinearWithValueLabel from "../../components/LinearProgress/LinearProgressWithLabel";
import { useSettingsContext } from "../../contexts/SettingsContext";
import SaveIcon from "@mui/icons-material/SaveRounded";
import { LoadingButton } from "@mui/lab";
import ResponsiveDrawer from "../../components/Drawer/Drawer";
import { useDrawerContext } from "../../components/Drawer/drawerContext";
/// Material UI docs: https://v4.mui.com/
/// Firestore docs: https://firebase.google.com/docs/firestore

// tab for selecting which columns to view
import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import { getUrlVariableValue } from "../../utility-functions/utility-functions.js";
import { getSecondaryPageFromUrl } from "../../utility-functions/utility-functions.js";
import DrawerContextProvider from "../../components/Drawer/drawerContext";

export function StudioSettings() {
  const {
    changes,
    setChanges,
    saveDisabled,
    setSaveDisabled,
    settings,
    settingsMenuButtonsDisabled,
    setSettingsMenuButtonsDisabled,
    dispatch,
    initSettingsPresets,
  } = useSettingsContext();

  const history = useHistory();
  const { drawerDispatch } = useDrawerContext();

  const { getUID } = useAuth();

  const [searchPopupOpen, setSearchPopupOpen] = useState(false);

  const pageOrder = {
    employees: 1,
    studios: 2,
    classStructure: 3,
    timeClockStructure: 4,
    commissionStructure: 5,
    outputFormat: 6,
    exceptions: 7,
    payrollProcessorMapping: 8,
    adminSettings: 9,
    salesSettings: 10,
  };

  const pageComponentsArray = [
    <StaffSettings pageOrder={pageOrder} />,
    <Studiomanager  />,
    <ClassSettings />,
    <TimeForm />,
    <CommissionSettings />,
    <FormatForm />,
    <Exceptions />,
    <ProcessorMappingList />,
    <AdditionalSettings />,
    <SalesSettings />,
  ];

  const pageHeaders = [
    "Staff",
    settings[getUrlVariableValue("settingsId")]?.generalSettings
      ?.locationsName || "Studios",
    settings[getUrlVariableValue("settingsId")]?.generalSettings?.sessionName +
      " Structure" || "Class Structure",
    settings[getUrlVariableValue("settingsId")]?.generalSettings?.timeName +
      " Clock" || "Time Clock",
    "Sales " +
      settings[getUrlVariableValue("settingsId")]?.generalSettings
        ?.commissionName +
      "" || "Sales Commission",
    "Format",
    "Exceptions",
    "Mapping",
    "Admin",
    "Sales"
  ];

  const urlPageHeaders = [
    "staff",
    "studios",
    "class-structure",
    "time-clock-structure",
    "commission-structures",
    "output-format",
    "exceptions",
    "payroll-processor-mapping",
    "admin-settings",
    "sales-settings",
  ];

  const activePage =
    pageComponentsArray[urlPageHeaders.indexOf(getSecondaryPageFromUrl())];
  const activePageIndex = urlPageHeaders.indexOf(getSecondaryPageFromUrl());

  const [openDialog, setOpenDialog] = useState(false);
  const [postDialogIndex, setPostDialogIndex] = useState(-1);
  const [saveButtonLoading, setSaveButtonLoading] = useState(false);

  useEffect(() => {
    if (changes.getLength() > 0) {
      setSaveDisabled(false);
    } else {
      setSaveDisabled(true);
    }
  }, [changes]);

  useEffect(() => {
    drawerDispatch({
      type: "SET_PAGE_TITLE",
      pageTitle: pageHeaders[urlPageHeaders.indexOf(getSecondaryPageFromUrl())],
    });
    drawerDispatch({
      type: "CLOSE_DRAWER",
    });
  }, []);

  async function handleSave() {
    
    setSaveButtonLoading(true);
    let uid = getUID();

    const updateSuccessful = await updateStudioSettings(
      settings[getUrlVariableValue("settingsId")],
      uid,
      getUrlVariableValue("settingsId")
    );

    setSaveButtonLoading(false);

    if (updateSuccessful) {
      // Only perform these actions if the update was successful (returned true)
      removeDeletedStaffFromState(settings.staff.staff);
      setChanges(new RevertList([]));
      setSaveDisabled(true);
    }

    // await updateStudioSettings(
    //   settings[getUrlVariableValue("settingsId")],
    //   uid,
    //   getUrlVariableValue("settingsId")
    // ).then(() => setSaveButtonLoading(false));
    // removeDeletedStaffFromState(settings.staff.staff);
    // setChanges(new RevertList([]));
    // setSaveDisabled(true);
  }

  async function handleStaffSave() {
   
    setSaveButtonLoading(true);
    let uid = getUID();
  
    
    const updateSuccessful = await updateStaffMasterSettings(
      uid,
      settings.staff.staff
    );

    setSaveButtonLoading(false);

    if (updateSuccessful) {
      // Only perform these actions if the update was successful (returned true)
      removeDeletedStaffFromState(settings.staff.staff);
      setChanges(new RevertList([]));
      setSaveDisabled(true);
    }
  }

  function removeDeletedStaffFromState(updatedStaffRowIds) {
    if (typeof updatedStaffRowIds !== "object") {
      throw new Error(
        "Invalid argument: updatedStaffRowIds must be an object."
      );
    }

    const staffRowIds = Object.keys(updatedStaffRowIds);

    for (let i = 0; i < staffRowIds.length; i++) {
      const staffDocId = staffRowIds[i];
      const staffDoc = updatedStaffRowIds[staffDocId];

      if (!staffDoc || typeof staffDoc.status !== "string") {
        continue;
      }

      if (staffDoc.status === "delete") {
        dispatch({
          type: "DELETE_STAFF_SETTINGS_STAFF_MEMBER",
          uid: getUID(),
          id: staffDocId,
        });
      }
    }
  }

  // async function handleSaveManual(data) {
  //   setSaveButtonLoading(true);

  //   let uid = getUID();

  //   await updateStudioSettings(data, uid, getUrlVariableValue("settingsId")).then(() =>
  //     setSaveButtonLoading(false)
  //   );
  //   setChanges(new RevertList([]));
  //   setSaveDisabled(true);
  // }

  function handleSettingSelect(pageIndex) {
    if (changes.getLength() > 0) {
      setOpenDialog(true);
      setPostDialogIndex(pageIndex);
    } else {
      // get a copy of the current query parameters without the tab ID
      const newQueryParams = new URLSearchParams(location.search);
      newQueryParams.delete("tabId");

      history.push({
        pathname: `/studio-setting/${urlPageHeaders[pageIndex - 1]}`,
        search: newQueryParams.toString(), // this includes the current query parameters
      });
    }
  }

  function forceHandleSettingSelect(pageIndex) {
    if (pageIndex >= 0) {
      history.push({
        pathname: `/studio-setting/${urlPageHeaders[pageIndex - 1]}`,
        search: location.search, // this includes the current query parameters
      });
    }
  }

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleCloseDiscard = async () => {
    setSettingsMenuButtonsDisabled(true);
    await initSettingsPresets();
    setChanges(new RevertList([]));
    setSaveDisabled(true);

    forceHandleSettingSelect(postDialogIndex);
    setOpenDialog(false);

    if (postDialogIndex < 0) {
      history.push({
        pathname: "/dashboard",
        search: location.search, // this includes the current query parameters
      });
    }
  };

  // const handleCloseSave = async () => {
  //   await handleSave();
  //   forceHandleSettingSelect(postDialogIndex);
  //   setOpenDialog(false);
  // };

  const handleCloseSave = async () => {
    if (urlPageHeaders[activePageIndex] == "staff") {
      await handleStaffSave();
    } else {
      await handleSave();
    }

    forceHandleSettingSelect(postDialogIndex);
    setOpenDialog(false);
    if (postDialogIndex < 0) {
      history.push({
        pathname: "/dashboard",
        search: location.search, // this includes the current query parameters
      });
    }
  };

  function handleDashboardClick() {
    if (changes.getLength() > 0) {
      setOpenDialog(true);
    } else {
      history.push({
        pathname: "/dashboard",
        search: location.search, // this includes the current query parameters
      });
    }
  }

  const DrawerContent = (
    <List className="settings__list drawer__list">
      <Button
        className="drawer__back-button"
        onClick={handleDashboardClick}
        startIcon={<ArrowBackIosRoundedIcon className="icon" />}
      >
        Dashboard
      </Button>

      <ListItem
        disabled={settingsMenuButtonsDisabled}
        className="settings__list-item"
        disablePadding
      >
        <ListItemButton
          className="settings__list-item-button"
          onClick={() => {setSearchPopupOpen(true)}}
          disabled={settingsMenuButtonsDisabled}
        >
          <ListItemIcon>
             <SearchIcon className="icon"/> 
          </ListItemIcon>
          <ListItemText className="list-item-text" primary="Search for Settings"/>
        </ListItemButton>
      </ListItem>

      <Divider className="settings__divider" />

      {/* Staff */}

      <ListItem
        className="settings__list-item"
        disablePadding
        disabled={settingsMenuButtonsDisabled}
        onClick={() =>
          !settingsMenuButtonsDisabled
            ? handleSettingSelect(pageOrder.employees)
            : null
        }
      >
        <ListItemButton
          disabled={settingsMenuButtonsDisabled}
          className={`settings__list-item-button${
            urlPageHeaders.indexOf(getSecondaryPageFromUrl()) + 1 ==
            pageOrder.employees
              ? "--active"
              : ""
          }`}
        >
          <ListItemIcon>
            <PersonAddAltIcon className="icon"></PersonAddAltIcon>
          </ListItemIcon>
          <ListItemText className="list-item-text" primary={"Staff"} />
        </ListItemButton>
      </ListItem>

      {/* Studios */}

      <ListItem
        disabled={settingsMenuButtonsDisabled}
        className="settings__list-item"
        disablePadding
      >
        <ListItemButton
          disabled={settingsMenuButtonsDisabled}
          onClick={() => {
            handleSettingSelect(pageOrder.studios);
          }}
          className={`settings__list-item-button${
            urlPageHeaders.indexOf(getSecondaryPageFromUrl()) + 1 ==
            pageOrder.studios
              ? "--active"
              : ""
          }`}
        >
          <ListItemIcon>
            <AddBusinessIcon className="icon"></AddBusinessIcon>
          </ListItemIcon>
          <ListItemText
            className="list-item-text"
            primary={
              settings[getUrlVariableValue("settingsId")]?.generalSettings
                ?.locationsName || "Studios"
            }
          />
        </ListItemButton>
      </ListItem>

      {/* Classes */}

      <ListItem
        className="settings__list-item"
        disablePadding
        disabled={settingsMenuButtonsDisabled}
        onClick={() =>
          !settingsMenuButtonsDisabled
            ? handleSettingSelect(pageOrder.classStructure)
            : null
        }
      >
        <ListItemButton
          disabled={settingsMenuButtonsDisabled}
          onClick={() =>
            !settingsMenuButtonsDisabled
              ? handleSettingSelect(pageOrder.classStructure)
              : null
          }
          className={`settings__list-item-button${
            urlPageHeaders.indexOf(getSecondaryPageFromUrl()) + 1 ==
            pageOrder.classStructure
              ? "--active"
              : ""
          }`}
        >
          <ListItemIcon>
            <GroupsIcon className="icon"></GroupsIcon>
          </ListItemIcon>
          <ListItemText
            className="list-item-text"
            primary={
              settings[getUrlVariableValue("settingsId")]?.generalSettings
                ?.sessionsName || "Classes"
            }
          />
        </ListItemButton>
      </ListItem>

      {/* Time Clock */}

      <ListItem
        className="settings__list-item"
        disablePadding
        disabled={settingsMenuButtonsDisabled}
        onClick={() =>
          !settingsMenuButtonsDisabled
            ? handleSettingSelect(pageOrder.timeClockStructure)
            : null
        }
      >
        <ListItemButton
          disabled={settingsMenuButtonsDisabled}
          className={`settings__list-item-button${
            urlPageHeaders.indexOf(getSecondaryPageFromUrl()) + 1 ==
            pageOrder.timeClockStructure
              ? "--active"
              : ""
          }`}
          onClick={() =>
            !settingsMenuButtonsDisabled
              ? handleSettingSelect(pageOrder.timeClockStructure)
              : null
          }
        >
          <ListItemIcon>
            <ScheduleIcon className="icon"></ScheduleIcon>
          </ListItemIcon>
          <ListItemText
            className="list-item-text"
            primary={
              settings[getUrlVariableValue("settingsId")]?.generalSettings
                ?.timeName + " Clock" || "Time Clock"
            }
          />
        </ListItemButton>
      </ListItem>

      {/* Commission */}

      <ListItem
        className="settings__list-item"
        disablePadding
        disabled={settingsMenuButtonsDisabled}
        onClick={() =>
          !settingsMenuButtonsDisabled
            ? handleSettingSelect(pageOrder.commissionStructure)
            : null
        }
      >
        <ListItemButton
          disabled={settingsMenuButtonsDisabled}
          className={`settings__list-item-button${
            urlPageHeaders.indexOf(getSecondaryPageFromUrl()) + 1 ==
            pageOrder.commissionStructure
              ? "--active"
              : ""
          }`}
          onClick={() =>
            !settingsMenuButtonsDisabled
              ? handleSettingSelect(pageOrder.commissionStructure)
              : null
          }
        >
          <ListItemIcon>
            <PointOfSaleIcon className="icon"></PointOfSaleIcon>
          </ListItemIcon>
          <ListItemText
            className="list-item-text"
            primary={
              "Sales " +
                settings[getUrlVariableValue("settingsId")]?.generalSettings
                  ?.commissionName || "Sales Commission"
            }
          />
        </ListItemButton>
      </ListItem>

      {/* Output Format */}

      <ListItem
        disabled={settingsMenuButtonsDisabled}
        className="settings__list-item"
        disablePadding
      >
        <ListItemButton
          disabled={settingsMenuButtonsDisabled}
          onClick={() =>
            !settingsMenuButtonsDisabled
              ? handleSettingSelect(pageOrder.outputFormat)
              : null
          }
          className={`settings__list-item-button${
            urlPageHeaders.indexOf(getSecondaryPageFromUrl()) + 1 ==
            pageOrder.outputFormat
              ? "--active"
              : ""
          }`}
        >
          <ListItemIcon>
            <DescriptionIcon className="icon"></DescriptionIcon>
          </ListItemIcon>
          <ListItemText className="list-item-text" primary={"Format"} />
        </ListItemButton>
      </ListItem>

      {/* Exceptions */}
      <Divider className="settings__divider" />

      <ListItem
        disabled={settingsMenuButtonsDisabled}
        className="settings__list-item"
        disablePadding
      >
        <ListItemButton
          // onClick={handleOpenExceptions}
          disabled={settingsMenuButtonsDisabled}
          onClick={() =>
            !settingsMenuButtonsDisabled
              ? handleSettingSelect(pageOrder.exceptions)
              : null
          }
          className={`settings__list-item-button${
            urlPageHeaders.indexOf(getSecondaryPageFromUrl()) + 1 ==
            pageOrder.exceptions
              ? "--active"
              : ""
          }`}
        >
          <ListItemIcon>
            <AltRouteIcon className="icon"></AltRouteIcon>
          </ListItemIcon>
          <ListItemText className="list-item-text" primary={"Exceptions"} />
        </ListItemButton>
      </ListItem>

      {/* Mapping */}

      <ListItem
        disabled={settingsMenuButtonsDisabled}
        className="settings__list-item"
        disablePadding
      >
        <ListItemButton
          disabled={settingsMenuButtonsDisabled}
          onClick={() =>
            !settingsMenuButtonsDisabled
              ? handleSettingSelect(pageOrder.payrollProcessorMapping)
              : null
          }
          className={`settings__list-item-button${
            urlPageHeaders.indexOf(getSecondaryPageFromUrl()) + 1 ==
            pageOrder.payrollProcessorMapping
              ? "--active"
              : ""
          }`}
        >
          <ListItemIcon>
            <MapIcon className="icon"></MapIcon>
          </ListItemIcon>
          <ListItemText className="list-item-text" primary={(settings[getUrlVariableValue("settingsId")]?.formatSettings?.processorMapping?.name || "") + " Mapping"} />
        </ListItemButton>
      </ListItem>

      {/* Settings */}

      <ListItem
        disabled={settingsMenuButtonsDisabled}
        className="settings__list-item"
        disablePadding
      >
        <ListItemButton
          disabled={settingsMenuButtonsDisabled}
          onClick={() =>
            !settingsMenuButtonsDisabled
              ? handleSettingSelect(pageOrder.adminSettings)
              : null
          }
          className={`settings__list-item-button${
            urlPageHeaders.indexOf(getSecondaryPageFromUrl()) + 1 ==
            pageOrder.adminSettings
              ? "--active"
              : ""
          }`}
        >
          <ListItemIcon>
            <AdminPanelSettingsIcon className="icon"></AdminPanelSettingsIcon>
          </ListItemIcon>
          <ListItemText className="list-item-text" primary={"Admin"} />
        </ListItemButton>
      </ListItem>

      {/* Sales Settings */}
      <ListItem
        className="settings__list-item"
        disablePadding
        disabled={settingsMenuButtonsDisabled}
        onClick={() =>
          !settingsMenuButtonsDisabled
            ? handleSettingSelect(pageOrder.salesSettings)
            : null
        }
      >
        <ListItemButton
          disabled={settingsMenuButtonsDisabled}
          onClick={() =>
            !settingsMenuButtonsDisabled
              ? handleSettingSelect(pageOrder.salesSettings)
              : null
          }
          className={`settings__list-item-button${
            urlPageHeaders.indexOf(getSecondaryPageFromUrl()) + 1 ==
            pageOrder.salesSettings
              ? "--active"
              : ""
          }`}
        >
          <ListItemIcon>
            <ReceiptIcon className="icon"></ReceiptIcon>
          </ListItemIcon>
          <ListItemText
            className="list-item-text"
            primary={
              "Sales"
            }
          />
        </ListItemButton>
      </ListItem>
    </List>
  );

  const PageContent = (
    <Box 
      sx={{
        width: {
          xs: '100%', // 0px and up
          md: '100%', // 900px and up
          lg: '65%', // 1200px and up
          xl: '60%', // 1536px and up
        }
      }}
      className="settings__active-page">
      {activePage}

      <Dialog
        className="confirmation-dialog"
        open={openDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle component={"h3"}>Save Before Leaving?</DialogTitle>

        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Do you want to save your changes before leaving?
          </DialogContentText>
        </DialogContent>
        <DialogActions className="confirmation-dialog__actions">
          <Button
            className="confirmation-dialog__button"
            color="error"
            onClick={handleCloseDiscard}
          >
            Discard
          </Button>
          <LoadingButton
            loading={saveButtonLoading}
            className="confirmation-dialog__button"
            startIcon={
              <SaveIcon className="confirmation-dialog__button-icon" />
            }
            variant="contained"
            onClick={handleCloseSave}
          >
            <span className="confirmation-dialog__button-text">Save</span>
          </LoadingButton>
        </DialogActions>
      </Dialog>

      <Box className="settings__button-container settings__button-container--desktop">
        {urlPageHeaders.indexOf(getSecondaryPageFromUrl()) + 1 !==
        pageOrder.employees ? (
          <LoadingButton
            startIcon={<SaveIcon />}
            loading={saveButtonLoading}
            className="settings__button"
            onClick={handleSave}
            variant="contained"
            size="large"
            disabled={saveDisabled}
          >
            Save
          </LoadingButton>
        ) : (
          <LoadingButton
            startIcon={<SaveIcon />}
            loading={saveButtonLoading}
            className="settings__button"
            onClick={handleStaffSave}
            variant="contained"
            size="large"
            disabled={saveDisabled}
          >
            Save
          </LoadingButton>
        )}
      </Box>
    </Box>
  );

  return (
    <>
      <ResponsiveAppBar hideMenu={true} showUser={true} />

      <ResponsiveDrawer
        saveButton={
          <Box className="settings__button-container">
            {urlPageHeaders.indexOf(getSecondaryPageFromUrl()) + 1 !==
            pageOrder.employees ? (
              <LoadingButton
                startIcon={<SaveIcon />}
                loading={saveButtonLoading}
                className="settings__button"
                onClick={handleSave}
                variant="contained"
                size="large"
                disabled={saveDisabled}
              >
                Save
              </LoadingButton>
            ) : (
              <LoadingButton
                startIcon={<SaveIcon />}
                loading={saveButtonLoading}
                className="settings__button"
                onClick={handleStaffSave}
                variant="contained"
                size="large"
                disabled={saveDisabled}
              >
                Save
              </LoadingButton>
            )}
          </Box>
        }
        drawerContent={DrawerContent}
        pageContent={PageContent}
      />
      <SettingsSearchPopup open={searchPopupOpen} setOpen={setSearchPopupOpen} />
    </>
  );
}

export default function StudioSettingsWrapper() {
  return (
    <DrawerContextProvider>
      <StudioSettings />
    </DrawerContextProvider>
  );
}
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
