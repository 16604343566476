import React, { useState } from "react";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Link, Typography } from "@mui/material";
import AttributeListBox from "../../Components/AttributeListBox";
import SelectorBox from "../../Components/SelectorBox/SelectorBox";
import { Divider } from "@mui/material";
import { attendeeStatuses, classesExamples, settingTooltips, settingIds } from "../CommonVariables";
import InputBoxElement from "../../Components/InputBox/InputBox";
import BooleanToggleElement from "../../Components/BooleanToggle";
import { RevertList } from "../../Components/RevertList";
import AdvancedClassSettings from "./AdvancedClassSettings";
import "../studioSettings.scss";
import { useSettingsContext } from "../../../contexts/SettingsContext";
import MultipleSelectCheckmarks from "../../../components/MultipleSelectCheckbox/MultipleSelectCheckbox";
import BasicModal from "../../../components/Modal/Modal";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import VideocamIcon from '@mui/icons-material/Videocam';
import { useAuth } from "../../../contexts/AuthContext";
import { getUrlVariableValue } from "../../../utility-functions/utility-functions.js";
import { Skeleton } from "@mui/material";
import SettingsFormInput from "../../../components/SettingsForm/SettingsFormInput";
import SettingsFormContainer from "../../../components/SettingsForm/SettingsFormContainer";
import useAutoScrollToHash from "../useAutoScrollToHash";
function tabProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function ClassBucketBox(props) {
  const { getUID } = useAuth();
  const { settings, dispatch, changes, setChanges } = useSettingsContext();

  const handleBucketUpdate = (value, id) => {
    let newRev = new RevertList(changes.getValues());
    newRev.addElement(
      props.classType + "-" + id,
      settings[getUrlVariableValue("settingsId")].classSettings.classBuckets[
      props.classType
      ][id],
      value
    );
    setChanges(newRev);
    dispatch({
      type: "UPDATE_CLASS_SETTINGS_WITH_KEY",
      settingsId: getUrlVariableValue("settingsId"),
      uid: getUID(),
      classType: props.classType,
      key: id,
      value: value,
    });
  };

  const handleRateUpdate = (value, id) => {
    let newRev = new RevertList(changes.getValues());
    newRev.addElement(
      props.classType + "-" + id,
      settings[getUrlVariableValue("settingsId")].classSettings.classBuckets[
        props.classType
      ].rate[id],
      value
    );
    setChanges(newRev);

    // UPDATE CLASS SETTINGS GROUP/INTRO/PRIVATE RATE
    dispatch({
      type: "UPDATE_CLASS_SETTINGS_RATE",
      settingsId: getUrlVariableValue("settingsId"),
      uid: getUID(),
      classType: props.classType,
      rateId: id,
      newRate: value,
    });
  };

  function ZeroComponent() {
    let booleanConverter = {
      true: "Paid A Different Amount",
      false: "Not Paid",
    };

    return (
      <SettingsFormInput
        label={settings[getUrlVariableValue("settingsId")].generalSettings
          .sessionName +
          " with zero " +
          settings[getUrlVariableValue("settingsId")].generalSettings
            .clientsName.toLowerCase() +
          ", are :"}
        tooltip={settingTooltips?.classSettings?.classBuckets?.sharedBucketProperties?.rate?.includeZero}
        id={settingIds?.classSettings?.classBuckets?.sharedBucketProperties?.rate?.includeZero}
        inputElement={
          <SelectorBox
            key={props.classType + "-zero"}
            onChange={handleRateUpdate}
            id="includeZero"
            default={
              typeof settings[getUrlVariableValue("settingsId")].classSettings
                .classBuckets[props.classType].rate.includeZero === "boolean"
                ? booleanConverter[
                settings[getUrlVariableValue("settingsId")].classSettings
                  .classBuckets[props.classType].rate.includeZero
                ]
                : settings[getUrlVariableValue("settingsId")].classSettings
                  .classBuckets[props.classType].rate.includeZero
            }
            size={400}
            options={[
              "Not Paid",
              "Paid The Base Rate",
              "Paid A Different Amount",
            ]}
            label="Zero Attendees"
            name="Zero Attendees"
          ></SelectorBox>
        }
      />
    );
  }

  return (
    <SettingsFormContainer>

      <SettingsFormInput label={
        `For ${props.classType} ${settings[getUrlVariableValue("settingsId")].generalSettings
          .sessionsName +
        ", " +
        settings[getUrlVariableValue("settingsId")].generalSettings
          .specialistsName +
        " are paid a"}`
      }
        tooltip={settingTooltips?.classSettings?.classBuckets?.sharedBucketProperties?.rate?.structure}
        id={settingIds?.classSettings?.classBuckets?.sharedBucketProperties?.rate?.structure}
        inputElement={
          <SelectorBox
            key={props.classType + "-structure"}
            onChange={handleRateUpdate}
            id="structure"
            default={
              settings[getUrlVariableValue("settingsId")].classSettings
                .classBuckets[props.classType].rate.structure
            }
            size={400}
            options={[
              "Base Rate + Per Head Bonus",
              "Flat Rate",
              "Custom Amount",
              "None (Zero)",
            ]}
            label="Rate"
            name="Rate"
          ></SelectorBox>
        }
      />

      {settings[getUrlVariableValue("settingsId")].classSettings.classBuckets[
        props.classType
      ].rate.structure !== "None (Zero)" && (
          <ZeroComponent />
        )}

      {settings[getUrlVariableValue("settingsId")].classSettings.classBuckets[
        props.classType
      ].rate.structure === "Base Rate + Per Head Bonus" && (
          <AfterAttendeeBonus classType={props.classType} />
        )}

      {settings[getUrlVariableValue("settingsId")].classSettings.classBuckets[
        props.classType
      ].rate.structure !== "None (Zero)" && (
          <StatusComponent classType={props.classType} />
        )}

      <SettingsFormInput
        label={"Max " +
          settings[getUrlVariableValue("settingsId")].generalSettings
            .sessionName?.toLowerCase() +
          " capacity:"}
        inputElement={
          <InputBoxElement
            key={props.classType + "-capacity"}
            onChange={handleBucketUpdate}
            id="capacity"
            default={
              settings[getUrlVariableValue("settingsId")].classSettings
                .classBuckets[props.classType].capacity
            }
            types={["number"]}
            size={100}
            label="Attendees"
          />
        }
      tooltip={settingTooltips?.classSettings?.classBuckets?.sharedBucketProperties?.capacity}
      id={settingIds?.classSettings?.classBuckets?.sharedBucketProperties?.capacity}
      />

      <SettingsFormInput
        label={`A ${props.classType.toLowerCase()} is any ${settings[getUrlVariableValue("settingsId")].generalSettings
          .sessionName.toLowerCase()
          } with name(s):`}
        inputElement={
          <AttributeListBox
            key={props.classType + "-names"}
            multiple={true}
            freeSolo
            limitTags={20}
            onChange={handleBucketUpdate}
            id="names"
            default={
              settings[getUrlVariableValue("settingsId")].classSettings
                .classBuckets[props.classType].names
            }
            size={400}
            label="Class Names"
            options={classesExamples}
          />
        }
        tooltip={settingTooltips?.classSettings?.classBuckets?.sharedBucketProperties?.names}
        id={settingIds?.classSettings?.classBuckets?.sharedBucketProperties?.names}
      />

      <SettingsFormInput
        label={`Advanced ${props.classType} Settings`}
        inputElement={
          <Box className="settings-page__box settings-page__box--inline">
            <BasicModal openIcon={<EditRoundedIcon />}>
              <AdvancedClassTypeSettings
                classType={props.classType}
                key={props.classType + "advancedClassSettings"}
              ></AdvancedClassTypeSettings>
            </BasicModal>
          </Box>
        }
      />
    </SettingsFormContainer>
  );
}

function StatusComponent(props) {
  const { getUID } = useAuth();
  const { settings, dispatch, changes, setChanges } = useSettingsContext();

  const handleBucketUpdate = (value, id) => {

    let newRev = new RevertList(changes.getValues());
    newRev.addElement(
      props.classType + "-" + id,
      settings[getUrlVariableValue("settingsId")].classSettings.classBuckets[
      props.classType
      ][id],
      value
    );
    setChanges(newRev);
    dispatch({
      type: "UPDATE_CLASS_SETTINGS_WITH_KEY",
      settingsId: getUrlVariableValue("settingsId"),
      uid: getUID(),
      classType: props.classType,
      key: id,
      value: value,
    });
  };

  return (
    <SettingsFormInput
      label={settings[getUrlVariableValue("settingsId")].generalSettings
        .specialistsName +
        " are paid for any " +
        settings[getUrlVariableValue("settingsId")].generalSettings
          .clientsName.toLowerCase() +
        " with the status(es) of"}
      inputElement={
        <MultipleSelectCheckmarks
          tag="Status"
          key={props.classType + "-status"}
          onChange={handleBucketUpdate}
          id="statuses"
          multiple
          default={
            settings[getUrlVariableValue("settingsId")].classSettings
              .classBuckets[props.classType].statuses
          }
          size={400}
          //options={attendeeStatuses}
          options={
            settings[getUrlVariableValue("settingsId")].generalSettings
              .attendeeStatuses
          }
          label="Statuses"
          name="Statuses"
        />
      }
      tooltip={settingTooltips?.classSettings?.classBuckets?.sharedBucketProperties?.statuses}
      id={settingIds?.classSettings?.classBuckets?.sharedBucketProperties?.statuses}
    />
  );
}

function AfterAttendeeBonus(props) {
  const { getUID } = useAuth();
  const { settings, dispatch, changes, setChanges } = useSettingsContext();

  const handleRateUpdate = (value, id) => {
    let newRev = new RevertList(changes.getValues());
    newRev.addElement(
      props.classType + "-" + id,
      settings[getUrlVariableValue("settingsId")].classSettings.classBuckets[
        props.classType
      ].rate[id],
      value
    );
    setChanges(newRev);

    // UPDATE CLASS SETTINGS GROUP/INTRO/PRIVATE RATE
    dispatch({
      type: "UPDATE_CLASS_SETTINGS_RATE",
      settingsId: getUrlVariableValue("settingsId"),
      uid: getUID(),
      classType: props.classType,
      rateId: id,
      newRate: value,
    });
  };

  return (
    <SettingsFormInput
      label={"Per Head Bonus starts AFTER " +
        settings[getUrlVariableValue("settingsId")].generalSettings
          .clientName +
        " number:"}
      inputElement={
        <SelectorBox
          key={props.classType + "-status"}
          onChange={handleRateUpdate}
          id="defaultAfterAttendee"
          default={
            settings[getUrlVariableValue("settingsId")].classSettings
              .classBuckets[props.classType].rate.defaultAfterAttendee
          }
          size={400}
          options={createCapacityArray(
            settings[getUrlVariableValue("settingsId")].classSettings
              .classBuckets[props.classType].capacity
          )}
          label="Statuses"
          name="Statuses"
        ></SelectorBox>
      }
      tooltip={settingTooltips?.classSettings?.classBuckets?.sharedBucketProperties?.rate?.defaultAfterAttendee}
      id={settingIds?.classSettings?.classBuckets?.sharedBucketProperties?.rate?.defaultAfterAttendee}
    />
  );
}

function createCapacityArray(number) {
  let num = parseInt(number.split("~")[1]);
  let numArray = [];
  for (let i = 0; i <= num; i++) {
    numArray.push("" + i);
  }
  return numArray;
}

function AdvancedClassTypeSettings(props) {
  const { getUID } = useAuth();
  const { settings, dispatch, changes, setChanges } = useSettingsContext();

  const handleBucketUpdate = (value, id) => {

    let newRev = new RevertList(changes.getValues());
    newRev.addElement(
      props.classType + "-" + id,
      settings[getUrlVariableValue("settingsId")].classSettings.classBuckets[
      props.classType
      ][id],
      value
    );
    setChanges(newRev);
    dispatch({
      type: "UPDATE_CLASS_SETTINGS_WITH_KEY",
      settingsId: getUrlVariableValue("settingsId"),
      uid: getUID(),
      classType: props.classType,
      key: id,
      value: value,
    });
  };

  return (
    <SettingsFormContainer>
      <Typography variant="h3" sx={{ marginBottom: 2 }}>
        Advanced {settings[getUrlVariableValue("settingsId")].generalSettings.sessionName} {props.classType} Settings
      </Typography>
      <SettingsFormInput 
        label={"Track membership signups after " +
            settings[getUrlVariableValue("settingsId")].generalSettings
              .sessionsName}
        noMaxWidth
        inputElement={
          <BooleanToggleElement
            key={props.classType + "-hasSignUpsPostSession"}
            onChange={handleBucketUpdate}
            id="hasSignUpsPostSession"
            default={
              settings[getUrlVariableValue("settingsId")].classSettings
                .classBuckets[props.classType].hasSignUpsPostSession
                ? settings[getUrlVariableValue("settingsId")].classSettings
                  .classBuckets[props.classType].hasSignUpsPostSession
                : props.classType === "Intro"
            }
          ></BooleanToggleElement>
        }
        id={settingIds.classSettings.classBuckets.sharedBucketProperties.hasSignUpsPostSession}
        tooltip={settingTooltips.classSettings.classBuckets.sharedBucketProperties.hasSignUpsPostSession}
      />

      <SettingsFormInput
        label={"Is affected by alternate " + 
          settings[getUrlVariableValue("settingsId")].generalSettings.memberName + 
          " payment:"}
        noMaxWidth
        inputElement={
          <BooleanToggleElement
            key={props.classType + "-isAffectedByAlternateClientPayment"}
            onChange={handleBucketUpdate}
            id="isAffectedByAlternateClientPayment"
            default={
              settings[getUrlVariableValue("settingsId")].classSettings
                .classBuckets[props.classType].isAffectedByAlternateClientPayment
                ? settings[getUrlVariableValue("settingsId")].classSettings
                  .classBuckets[props.classType]
                  .isAffectedByAlternateClientPayment
                : props.classType === "Group"
            }
          ></BooleanToggleElement>
        }
        id={settingIds.classSettings.classBuckets.sharedBucketProperties.isAffectedByAlternateClientPayment}
        tooltip={settingTooltips.classSettings.classBuckets.sharedBucketProperties.isAffectedByAlternateClientPayment}
      />

      <SettingsFormInput
        label={"Is standard " +
          settings[getUrlVariableValue("settingsId")].generalSettings.sessionName +
          " type:"}
        noMaxWidth
        inputElement={
          <BooleanToggleElement
            key={props.classType + "-isStandardSessionType"}
            onChange={handleBucketUpdate}
            id="isStandardSessionType"
            default={
              settings[getUrlVariableValue("settingsId")].classSettings
                .classBuckets[props.classType].isStandardSessionType
                ? settings[getUrlVariableValue("settingsId")].classSettings
                  .classBuckets[props.classType].isStandardSessionType
                : props.classType === "Group"
            }
          ></BooleanToggleElement>
        }
        id={settingIds.classSettings.classBuckets.sharedBucketProperties.isStandardSessionType}
        tooltip={settingTooltips.classSettings.classBuckets.sharedBucketProperties.isStandardSessionType}
      />

      <SettingsFormInput
        label={"Include " +
          settings[getUrlVariableValue("settingsId")].generalSettings.sessionName +
          " length in name:"}
        noMaxWidth
        inputElement={
          <BooleanToggleElement
            key={props.classType + "-includeSessionTimeInName"}
            onChange={handleBucketUpdate}
            id="includeSessionTimeInName"
            default={
              settings[getUrlVariableValue("settingsId")].classSettings
                .classBuckets[props.classType].includeSessionTimeInName
                ? settings[getUrlVariableValue("settingsId")].classSettings
                  .classBuckets[props.classType].includeSessionTimeInName
                : true
            }
          ></BooleanToggleElement>
        }
        id={settingIds.classSettings.classBuckets.sharedBucketProperties.includeSessionTimeInName}
        tooltip={settingTooltips.classSettings.classBuckets.sharedBucketProperties.includeSessionTimeInName}
      />

      <SettingsFormInput
        label={'"Would have been full" applicable'}
        noMaxWidth
        inputElement={
          <BooleanToggleElement
            key={props.classType + "-wouldHaveBeenFullApplicable"}
            onChange={handleBucketUpdate}
            id="wouldHaveBeenFullApplicable"
            default={
              settings[getUrlVariableValue("settingsId")].classSettings
                .classBuckets[props.classType].wouldHaveBeenFullApplicable
                ? settings[getUrlVariableValue("settingsId")].classSettings
                  .classBuckets[props.classType].wouldHaveBeenFullApplicable
                : props.classType === "Group"
            }
          ></BooleanToggleElement>
        }
        id={settingIds.classSettings.classBuckets.sharedBucketProperties.wouldHaveBeenFullApplicable}
        tooltip={settingTooltips.classSettings.classBuckets.sharedBucketProperties.wouldHaveBeenFullApplicable}
      />

      <SettingsFormInput
        label={"Pay attendee bonus on post class signups:"}
        noMaxWidth
        inputElement={
          <BooleanToggleElement
            key={props.classType + "-paidOnSignUps"}
            onChange={handleBucketUpdate}
            id="paidOnSignUps"
            default={
              settings[getUrlVariableValue("settingsId")].classSettings
                .classBuckets[props.classType].paidOnSignUps
                ? settings[getUrlVariableValue("settingsId")].classSettings
                  .classBuckets[props.classType].paidOnSignUps
                : props.classType === "Intro" &&
                settings[getUrlVariableValue("settingsId")].classSettings
                  .general.introSignUps
            }
          ></BooleanToggleElement>
        }
        id={settingIds.classSettings.classBuckets.sharedBucketProperties.paidOnSignUps}
        tooltip={settingTooltips.classSettings.classBuckets.sharedBucketProperties.paidOnSignUps}
      />

      <SettingsFormInput
        label={"Exclude Payment for Overlapping Sessions:"}
        noMaxWidth
        inputElement={
          <BooleanToggleElement
            key={props.classType + "-excludeOverlapInPayments"}
            onChange={handleBucketUpdate}
            id="excludeOverlapInPayments"
            default={
              settings[getUrlVariableValue("settingsId")].classSettings
                .classBuckets[props.classType].excludeOverlapInPayments
                ? settings[getUrlVariableValue("settingsId")].classSettings
                  .classBuckets[props.classType].excludeOverlapInPayments
                : false
            }
          ></BooleanToggleElement>
        }
        id={settingIds.classSettings.classBuckets.sharedBucketProperties.excludeOverlapInPayments}
        tooltip={settingTooltips.classSettings.classBuckets.sharedBucketProperties.excludeOverlapInPayments}
      />
    </SettingsFormContainer>
  );
}

const tabElement = (value) => {
  if (value === "Advanced") {
    return <AdvancedClassSettings></AdvancedClassSettings>;
  } else {
    return (
      <ClassBucketBox
        key={value + "-element-box"}
        classType={value}
      ></ClassBucketBox>
    );
  }
};
export default function ClassSettings() {
  const { getUID } = useAuth();
  const { settings, dispatch, changes, setChanges } = useSettingsContext();

  const [tab, setTab] = useState(
    settings.hasOwnProperty("1") && settings.hasOwnProperty("staff")
      ? Object.keys(
        settings[getUrlVariableValue("settingsId")].classSettings.classBuckets
      ).sort()[0]
      : "Group"
  );
  const [tabIndex, setTabIndex] = useState(0);

  const setTabAndIndex = (id) => {
    if (!settings[getUrlVariableValue("settingsId")]?.classSettings?.classBuckets) return;

    let buckets = Object.keys(
      settings[getUrlVariableValue("settingsId")]?.classSettings?.classBuckets
    ).sort();

    if (!buckets.includes(id) && id !== "Advanced") return;

    console.log(id === "Advanced")

    setTab(id);
    if (id == "Advanced") {
      setTabIndex(buckets.length);
    }

    for (let i = 0; i < buckets.length; i++) {
      if (buckets[i] === id) {
        setTabIndex(i);
        return;
      }
    }
  };

  useAutoScrollToHash(setTabAndIndex);

  const handleTabChange = (event) => {
    let id = event.target.id;
    setTabAndIndex(id);
  };

  return settings.hasOwnProperty("1") && settings.hasOwnProperty("staff") ? (
    <Box className="settings-page">
      <Typography variant="h2" className="settings-page__title">
        {settings[getUrlVariableValue("settingsId")].generalSettings
          .sessionName + " Settings"}
      </Typography>
      <Typography variant="p" className="settings-page__title-description">
        {/* <Link href=""><VideocamIcon/>Tutorial</Link> */}
        {`Set up rules for your ${settings[getUrlVariableValue("settingsId")].generalSettings
          .sessionsName?.toLowerCase() || "classes"} buckets, including how an ${settings[getUrlVariableValue("settingsId")].generalSettings
          .specialistName?.toLowerCase() || "instructor"} will be paid, what statuses count towards payment, what names of sessions are included in the bucket, and more.`}
      </Typography>
      <Tabs
        className="settings-page__tabs"
        value={tabIndex}
        onChange={handleTabChange}
        centered
      >
        {Object.keys(
          settings[getUrlVariableValue("settingsId")].classSettings.classBuckets
        )
          .sort()
          .map((key, index) => (
            <Tab
              label={key.replace(/(?<!^)([A-Z])/g, "-$1")}
              {...tabProps(index)}
              className={`settings-page__tab`}
              key={key}
              id={key}
            />
          ))}

        <Tab
          className={`settings-page__tab `}
          {...tabProps(
            Object.keys(
              settings[getUrlVariableValue("settingsId")].classSettings
                .classBuckets
            ).length
          )}
          key={"Advanced"}
          label={"Advanced"}
          id={"Advanced"}
        />
      </Tabs>

      {tabElement(tab)}
    </Box>
  ) : (
    <Box className="settings-page">
      <Skeleton>
        <Typography variant="h2" className="settings-page__title">
          Studios
        </Typography>
      </Skeleton>
      <Divider className="settings-page__divider" />
      <Skeleton className="settings-page__attribute-box settings-page__attribute-box--skeleton"></Skeleton>
    </Box>
  );
}
