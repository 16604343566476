import React, { } from "react";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import "./staffSettings.scss";
import { DialogContentText, Stack } from "@mui/material";
import { Dialog, DialogTitle, DialogContent, TextField, DialogActions } from '@mui/material';
import { Table, TableBody, TableCell, TableHead, TableRow, IconButton } from '@mui/material';
import { InputAdornment } from '@mui/material';
import SelectorBox from "../Components/SelectorBox/SelectorBox";

export const defaultEarnings =  [{ amount: 0, location: "" }]
export default function EarningsDialog({ isOpen, onClose, earnings, setEarnings, locations }) {
  const handleAddEarning = () => {
    const newEarning = { amount: 0, location: locations[0] };
    setEarnings([...earnings, newEarning]);
  };

  const handleRemoveEarning = (index) => {
    const updatedEarnings = earnings.filter((_, i) => i !== index);
    setEarnings(updatedEarnings);
  };

  const handleChange = (index, field, value) => {


    const updatedEarnings = earnings.map((item, i) =>
      i === index ? { ...item, [field]: value } : item
    );
   
    setEarnings(updatedEarnings);
  };

  return (

    <Dialog
      open={isOpen}
      onClose={onClose}
      fullWidth>
      <DialogTitle component={"h3"}>Edit Salary</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please add staff salary
        </DialogContentText>


        <Stack spacing={3} sx={{ margin: "16px  0" }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Amount</TableCell>
                <TableCell>Location</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {earnings.map((item, index) => (
                <TableRow key={index} className="earnings-dialog__table-row">
                  <TableCell className="earnings-dialog__table-cell">
                    <TextField
                      type="number"
                      value={item.amount}
                      onChange={(e) => handleChange(index, 'amount', parseFloat(e.target.value))}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        inputMode: "decimal",
                        pattern: "[0-9]*",
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <SelectorBox
                      onChange={(e, newValue) => handleChange(index, 'location', e)}
                      id="location"
                      default={item.location || (locations[0] ? locations[0] : "")}
                      size={400}
                      options={locations}

                      label="Location"
                      name="Location"
                    ></SelectorBox>
                  </TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleRemoveEarning(index)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell colSpan={4}>
                  <Button startIcon={<AddIcon />} onClick={handleAddEarning}>
                    Add Salary
                  </Button>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>


        </Stack>

      </DialogContent>


      <DialogActions style={{ padding: "24px" }}>
        <Button onClick={() => onClose(false)}>Cancel</Button>
        <Button
          onClick={() => onClose(true)}
          color={"primary"}
          variant={"contained"}
        >
          Confirm
        </Button>
      </DialogActions>

    </Dialog>
  )
}