import { getUrlVariableValue } from "./utility-functions"


export const getLocationName = (settings) => (settings[getUrlVariableValue("settingsId")]?.generalSettings?.locationName || "Studio")
export const getLocationNamePlural = (settings) => (settings[getUrlVariableValue("settingsId")]?.generalSettings?.locationsName || "Studios")

export const getClassName = (settings) => (settings[getUrlVariableValue("settingsId")]?.generalSettings?.sessionName || "Session");
export const getClassNamePlural = (settings) => (settings[getUrlVariableValue("settingsId")]?.generalSettings?.sessionsName || "Sessions");

export const getClientName = (settings) => (settings[getUrlVariableValue("settingsId")]?.generalSettings?.clientName || "Attendee");
export const getClientNamePlural = (settings) => (settings[getUrlVariableValue("settingsId")]?.generalSettings?.clientsName || "Attendees");

export const getSpecialistName = (settings) => (settings[getUrlVariableValue("settingsId")]?.generalSettings?.specialistName || "Instructor");
export const getSpecialistNamePlural = (settings) => (settings[getUrlVariableValue("settingsId")]?.generalSettings?.specialistsName || "Instructors");

export const getTimeName = (settings) => (settings[getUrlVariableValue("settingsId")]?.generalSettings?.timeName || "Time")

export const getCommissionName = (settings) => (settings[getUrlVariableValue("settingsId")]?.generalSettings?.commissionName || 'Commission');

export const getPayrollProcessorName = (settings) => (settings[getUrlVariableValue("settingsId")]?.formatSettings?.processorMapping?.name || "Payroll Processor")


export const getClassBucketKeys = (settings) => {
  const bucketsObj = settings[getUrlVariableValue("settingsId")]?.classSettings?.classBuckets;
  if (!bucketsObj) return []
  return Object.keys(bucketsObj);
}

export const getTimeBucketKeys = (settings) => {
  const bucketsObject = settings[getUrlVariableValue("settingsId")]?.timeSettings?.timeBuckets;
  const unsortedKeys = bucketsObject ? Object.keys(bucketsObject) : undefined;
  const bucketKeys = unsortedKeys ? unsortedKeys.sort() : [];
  return bucketKeys;
}

export const getSalesBucketKeys = (settings) => {
  const bucketsObject = settings[getUrlVariableValue("settingsId")]?.salesSettings?.salesBuckets;
  const unsortedKeys = bucketsObject ? Object.keys(bucketsObject) : undefined;
  const bucketKeys = unsortedKeys ? unsortedKeys.sort() : [];
  return bucketKeys;
}

